import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import { ContactFormAction, buyCoinsAction, getSinToCoinValueAction,trnasferTokenAPIAction, getcartcountAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import Swal from "sweetalert2";



const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));



const BuyCoins = () => {

    const [connectWalletAddress, setConnectWalletAddress] = useState('');
    const [cartcount, setcartcount] = useState(0);
    const [sinToCoinValue, setSinToCoinValue] = useState('');
    const [getBalance, setBalance] = useState(0);//setTotalCount
    const [spinLoader, setSpinLoader] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [modalopen, setModalopen] = useState(0);   
    const [buy100, setbuy100] = useState(0);   
    const [buy500, setbuy500] = useState(0);   
    const [buy1000, setbuy1000] = useState(0);  
    const [buy10000, setbuy10000] = useState(0);  
    const [by100, setby100] = useState(0);   
    const [by500, setby500] = useState(0);   
    const [by1000, setby1000] = useState(0);    
    const [by10000, setby10000] = useState(0);    
    
    const [ErrorMessage, setErrorMessage] = useState('');
   
    useEffect(async () => {
      getSinToCoinValueAPI();      
        setTimeout(() => {
            if (window.ethereum) {
                const { ethereum } = window;
                setConnectWalletAddress(ethereum.selectedAddress);
                getcartcountAPI(ethereum.selectedAddress);
            }
        }, 200);
        
      //  getAccount()

    }, []);
    
    const getSinToCoinValueAPI = async () => {
        let res = await getSinToCoinValueAction();
        if (res.success) {
            setSinToCoinValue(res.data);
            setby100(parseInt(100/res.data).toLocaleString('en-US'))
            setby500(parseInt(500/res.data).toLocaleString('en-US'))
            setby1000(parseInt(1000/res.data).toLocaleString('en-US'))
            setby10000(parseInt(10000/res.data).toLocaleString('en-US'))
        }
    }
    
    const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
    
    const purchaseCoins100 = async (e) => {
    if (!loginData?.id) {       
        Swal.fire({  text: 'Please login first!!',
				  title: "Alert",
				  confirmButtonText: 'Okay',
				  confirmButtonColor: '#e7275a',
				  background: '#343444',
				  color: '#FFF',
				  allowOutsideClick:false
				});
    } else {  
            if (sinToCoinValue == '') {
               Swal.fire({  text: 'Reload you page first',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
                return false;
            }            
            setSpinLoader(1)
            setbuy100(1)
			setbuy500(1)
			setbuy1000(1)
			setbuy10000(1)
			 const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
             let web3 = new Web3(window.ethereum);
             let currentNetwork = web3.currentProvider.chainId;
			 let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                    Swal.fire({  text: 'Please select BNB network!',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
                     setbuy100(0)
					 setbuy500(0)
					 setbuy1000(0)
                    return false;
                }
                try {
                 Swal.fire({
						title : "Processing...",
						text: 'Transaction is under process, please wait for confirmation!',
						allowEscapeKey: false,
						allowOutsideClick: false,
						showConfirmButton:false,
						background: '#343444',
						color: '#FFF'
					});
					
					let tx_builderForToken = '';
					let from_address = accounts[0];	
					let tokenId	= 100;								
					let tokenPrice = tokenId/parseInt(sinToCoinValue);
					
					//let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
					let tokenValue = web3.utils.toWei(tokenPrice.toString(), 'ether')
					let tokenContractAddress = `${config.tokenContract}`;
					
					
					const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
					let allowance = await tokenContract.methods.allowance(from_address, config.mainMarketplaceContract).call();
					
					if (parseInt(allowance) < parseInt(tokenValue)) {
						console.log(tokenPrice)
						tx_builderForToken = await tokenContract.methods.approve(config.mainMarketplaceContract.toString(), tokenValue.toString());
						console.log("test")
						setSpinLoader(1);
						setDialogOpen(true);

						let encodedTxForToken = tx_builderForToken.encodeABI();
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: tokenContractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});

						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: tokenContractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
					}

			let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
			var currentBal = parseInt(getTokenBalance) / 100000000;
			console.log("currentBal")
			console.log(currentBal)
			if (currentBal < tokenPrice) {
				setSpinLoader(0);
				setDialogOpen(false);
				Swal.fire({  text: 'Insufficient fund for transfer',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
				setbuy100(0)
				setbuy500(0)
				setbuy1000(0)
				setbuy10000(0)
				return false;
			}
			await trnasferNFT(tokenId, tokenValue);
			}
			 catch (error) {
                    setSpinLoader(0);
                    setDialogOpen(false);
                    setbuy100(0)
					setbuy500(0)
					setbuy1000(0)
					setbuy10000(0)
                    Swal.fire({  text: 'Something went wrong! Please try again.',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
					return false;
                }
        }
  }
  
  
   const purchaseCoins500 = async (e) => {
    if (!loginData?.id) {
        Swal.fire({  text: 'Please login first!!',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
    } else {  
        if (sinToCoinValue == '') {
           Swal.fire({  text: 'Reload you page first',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
            return false;
        }
			console.log('clicked 500');
			setbuy100(1)
			setbuy500(1)
			setbuy1000(1)
			setbuy10000(1)
			const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
             let web3 = new Web3(window.ethereum);
             let currentNetwork = web3.currentProvider.chainId;
			let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                   Swal.fire({  text: 'Please select BNB network!',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
                    setbuy100(0)
					setbuy500(0)
					setbuy1000(0)
					setbuy10000(0)
                    return false;
                }
                try {
                 Swal.fire({
						title : "Processing...",
						text: 'Transaction is under process, please wait for confirmation!',
						allowEscapeKey: false,
						allowOutsideClick: false,
						showConfirmButton:false,
						background: '#343444',
						color: '#FFF'
					});
					let tx_builderForToken = '';
					let from_address = accounts[0];	
					let tokenId	= 500;
					let tokenPrice = tokenId/parseInt(sinToCoinValue);
					//let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
					let tokenValue = web3.utils.toWei(tokenPrice.toString(), 'ether')					
					console.log("amount is ", tokenValue);
					
					let tokenContractAddress = `${config.tokenContract}`;
					console.log(from_address)
					const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
					let allowance = await tokenContract.methods.allowance(from_address, config.mainMarketplaceContract).call();
					console.log(parseInt(tokenPrice))
					console.log(parseInt(allowance))
					if (parseInt(allowance) < parseInt(tokenValue)) {
						tx_builderForToken = await tokenContract.methods.approve(config.mainMarketplaceContract.toString(), tokenValue.toString());
						
						setSpinLoader(1);
						setDialogOpen(true);

						let encodedTxForToken = tx_builderForToken.encodeABI();
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: tokenContractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});

						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: tokenContractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
					}

			let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
			var currentBal = parseInt(getTokenBalance) / 100000000;			
			if (currentBal < tokenPrice) {
				setSpinLoader(0);
				setDialogOpen(false);                        
				Swal.fire({  text: 'Insufficient fund for transfer',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
				setbuy100(0)
				setbuy500(0)
				setbuy1000(0)
				setbuy10000(0)
				return false;
			}
			await trnasferNFT(tokenId, tokenValue);
			}
			 catch (error) {
                    setSpinLoader(0);
                    setDialogOpen(false);
                    setbuy100(0)
					setbuy500(0)
					setbuy1000(0)
					setbuy10000(0)
                    Swal.fire({  text: 'Something went wrong! Please try again.',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
                    return false;
                }
        }
  }
  
  
   const purchaseCoins1000 = async (e) => {
    if (!loginData?.id) {
       Swal.fire({  text: 'Please login first!!',
		  title: "Alert",
		  confirmButtonText: 'Okay',
		  confirmButtonColor: '#e7275a',
		  background: '#343444',
		  color: '#FFF',
		  allowOutsideClick:false
		});
    } else {
        if (sinToCoinValue == '') {
           Swal.fire({  text: 'Reload you page first',
				  title: "Alert",
				  confirmButtonText: 'Okay',
				  confirmButtonColor: '#e7275a',
				  background: '#343444',
				  color: '#FFF',
				  allowOutsideClick:false
				});
            return false;
        } 
        console.log('clicked 1000');
					setbuy100(1)
					setbuy500(1)
					setbuy1000(1) 
					setbuy10000(1) 
			 const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
             let web3 = new Web3(window.ethereum);
             let currentNetwork = web3.currentProvider.chainId;
			let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                     Swal.fire({  text: 'Please select BNB network!',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
                    setbuy100(0)
					setbuy500(0)
					setbuy1000(0)
					setbuy10000(0)
                    return false;
                }
                try {
					Swal.fire({
						title : "Processing...",
						text: 'Transaction is under process, please wait for confirmation!',
						allowEscapeKey: false,
						allowOutsideClick: false,
						showConfirmButton:false,
						background: '#343444',
						color: '#FFF'
					});
					let tx_builderForToken = '';
					let from_address = accounts[0];	
					let tokenId	= 1000;
					let tokenPrice = tokenId/parseInt(sinToCoinValue);
					//let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
					let tokenValue = web3.utils.toWei(tokenPrice.toString(), 'ether')
					let tokenContractAddress = `${config.tokenContract}`;
					console.log(from_address)
					const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
					let allowance = await tokenContract.methods.allowance(from_address, config.mainMarketplaceContract).call();
					console.log(parseInt(tokenPrice))
					console.log(parseInt(allowance))
					if (parseInt(allowance) < parseInt(tokenValue)) {
						tx_builderForToken = await tokenContract.methods.approve(config.mainMarketplaceContract.toString(), tokenValue.toString());
						
						setSpinLoader(1);
						setDialogOpen(true);

						let encodedTxForToken = tx_builderForToken.encodeABI();
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: tokenContractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});

						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: tokenContractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
					}

			let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
			var currentBal = parseInt(getTokenBalance) / 100000000;
			console.log("currentBal")
			console.log(currentBal)
			if (currentBal < tokenPrice) {
				setSpinLoader(0);
				setDialogOpen(false);
				setbuy100(0)
				setbuy500(0)
				setbuy1000(0)
				setbuy10000(0)
				Swal.fire({  text: 'Insufficient fund for transfer',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
				return false;
			}
			await trnasferNFT(tokenId, tokenValue);
			}
			 catch (error) {
                    setSpinLoader(0);
                    setDialogOpen(false);
                    Swal.fire({  text: 'Something went wrong! Please try again.',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
                    setbuy100(0)
					setbuy500(0)
					setbuy1000(0)
					setbuy10000(0)
                    return false;
                }
        }
  }

   const purchaseCoins10000 = async (e) => {
    if (!loginData?.id) {
       Swal.fire({  text: 'Please login first!!',
		  title: "Alert",
		  confirmButtonText: 'Okay',
		  confirmButtonColor: '#e7275a',
		  background: '#343444',
		  color: '#FFF',
		  allowOutsideClick:false
		});
    } else {
        if (sinToCoinValue == '') {
           Swal.fire({  text: 'Reload you page first',
				  title: "Alert",
				  confirmButtonText: 'Okay',
				  confirmButtonColor: '#e7275a',
				  background: '#343444',
				  color: '#FFF',
				  allowOutsideClick:false
				});
            return false;
        } 
        console.log('clicked 10000');
					setbuy100(1)
					setbuy500(1)
					setbuy1000(1) 
					setbuy10000(1) 
			 const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
             let web3 = new Web3(window.ethereum);
             let currentNetwork = web3.currentProvider.chainId;
			let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                     Swal.fire({  text: 'Please select BNB network!',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
                    setbuy100(0)
					setbuy500(0)
					setbuy1000(0)
					setbuy10000(0)
                    return false;
                }
                try {
					Swal.fire({
						title : "Processing...",
						text: 'Transaction is under process, please wait for confirmation!',
						allowEscapeKey: false,
						allowOutsideClick: false,
						showConfirmButton:false,
						background: '#343444',
						color: '#FFF'
					});
					let tx_builderForToken = '';
					let from_address = accounts[0];	
					let tokenId	= 10000;
					let tokenPrice = tokenId/parseInt(sinToCoinValue);
					//let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
					let tokenValue = web3.utils.toWei(tokenPrice.toString(), 'ether')
					let tokenContractAddress = `${config.tokenContract}`;
					console.log(from_address)
					const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
					let allowance = await tokenContract.methods.allowance(from_address, config.mainMarketplaceContract).call();
					console.log(parseInt(tokenPrice))
					console.log(parseInt(allowance))
					if (parseInt(allowance) < parseInt(tokenValue)) {
						tx_builderForToken = await tokenContract.methods.approve(config.mainMarketplaceContract.toString(), tokenValue.toString());
						
						setSpinLoader(1);
						setDialogOpen(true);

						let encodedTxForToken = tx_builderForToken.encodeABI();
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: tokenContractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});

						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: tokenContractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
					}

			let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
			var currentBal = parseInt(getTokenBalance) / 100000000;
			console.log("currentBal")
			console.log(currentBal)
			if (parseInt(currentBal) < parseInt(tokenPrice)) {
				setSpinLoader(0);
				setDialogOpen(false);
				setbuy100(0)
				setbuy500(0)
				setbuy1000(0)
				setbuy10000(0)
				Swal.fire({  text: 'Insufficient fund for transfer',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
				return false;
			}
			await trnasferNFT(tokenId, tokenValue);
			}
			 catch (error) {
                    setSpinLoader(0);
                    setDialogOpen(false);
                    Swal.fire({  text: 'Something went wrong! Please try again.',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
                    setbuy100(0)
					setbuy500(0)
					setbuy1000(0)
					setbuy10000(0)
                    return false;
                }
        }
  }

  const trnasferNFT = async (tokenId, tokenPrice) => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let web3 = new Web3(window.ethereum);
            try {
                let contractAddress = `${config.mainMarketplaceContract}`;
                let from_address = accounts[0];
                const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);
                let tx_builder = '';
                let itemPrice = 0;
                itemPrice = tokenPrice / 10 ** 18;
                console.log(tokenPrice,'tp')
                console.log(itemPrice,'ip')
                tx_builder = await contract.methods.sendTokens(tokenPrice.toString());
                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                if (txData.transactionHash) {
                    var paymentArr = {
                        email: loginData?.email,
                        user_id: loginData?.id,
                        txHash: txData.transactionHash,
                        amount: itemPrice,
                        address: from_address,
                        item_id: tokenId
                    }
                    console.log(paymentArr);
                    trnasferTokenAPI(paymentArr)
                } else {
                   Swal.fire({  text: 'Something went wrong please try again.',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
                    setSpinLoader(0);
                    setbuy100(0)
					setbuy500(0)
					setbuy1000(0)
					setbuy10000(0)
                    setDialogOpen(false);
                    return false;
                }

            } catch (err) {
                console.log(err);                
                if (err.toString().indexOf('insufficient funds') > -1) {               
                   Swal.fire({  text: 'Transaction reverted : Insufficient funds for transfer',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});                 
                }
                else if (err.toString().indexOf('Max NFTs per address exceeded') > -1) {
				Swal.fire({  text: 'Transaction reverted : Max NFTs per address exceeded',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});                  
			  }
			  else {
                    if (err.toString().split('execution reverted:')[1]) {
                       Swal.fire({  text: 'Transaction reverted : Something went wrong! Please try again.',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});  
                    } 
                    else {
						if (err.message.toString().search(/supplied gas/) > -1 || err.message.toString().search(/exceeds allowance/) > -1) {
						Swal.fire({  text: 'Transaction reverted : insufficient funds for transaction fee',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						}); 				
						} else {
							Swal.fire({  text: 'Something went wrong! Please try again.',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
                        }
                    }
                }
              
                setSpinLoader(0);
                setbuy100(0);
				setbuy500(0);
				setbuy1000(0);
				setbuy10000(0);
                setDialogOpen(false);
                return false;
            }

        } else {
           Swal.fire({  text: 'Please Connect to MetaMask.',
					  title: "Alert",
					  confirmButtonText: 'Okay',
					  confirmButtonColor: '#e7275a',
					  background: '#343444',
					  color: '#FFF',
					  allowOutsideClick:false
					});
            setSpinLoader(0);
            setbuy100(0);
			setbuy500(0);
			setbuy1000(0);
			setbuy10000(0);
            setDialogOpen(false);
            return false;
        }
    }
	
	 const trnasferTokenAPI = async (data) => {
        let res = await trnasferTokenAPIAction(data);
        if (res.success) {			
			loginData.credits = res.data;			  
			Cookies.set('loginSuccessSinverseUserpanel', JSON.stringify(loginData));
			
           Swal.fire({  text: res.msg+': Thanks your '+data.item_id+' coins credited in your account',
					  title: "Alert",
					  confirmButtonText: 'Okay',
					  confirmButtonColor: '#e7275a',
					  background: '#343444',
					  color: '#FFF',
					  allowOutsideClick:false
					});
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
					setbuy100(0)
					setbuy500(0)
					setbuy1000(0)
					setbuy10000(0)
				Swal.fire({  text: 'Something went wrong! Please try again.',
					  title: "Alert",
					  confirmButtonText: 'Okay',
					  confirmButtonColor: '#e7275a',
					  background: '#343444',
					  color: '#FFF',
					  allowOutsideClick:false
					});
        }
    }
  
    
    const getAccount = async () => {
		const web3 = new Web3(window.ethereum);
		let currentNetwork = web3.currentProvider.chainId
		let chainId = config.chainId;		
		if (currentNetwork !== chainId) {
			Swal.fire({  text: 'Please select BNB network!',
					  title: "Alert",
					  confirmButtonText: 'Okay',
					  confirmButtonColor: '#e7275a',
					  background: '#343444',
					  color: '#FFF',
					  allowOutsideClick:false
					});
			return false;
		}
		const useraccount = await web3.eth.getAccounts();
		const account = useraccount[0];
		if (account) 
		{    
			let tokenContractMethod = new web3.eth.Contract(config.abiToken, config.tokenContract);
			let balance = await tokenContractMethod.methods.balanceOf(account).call();

			setBalance(balance / 10 ** 18)     
     
		}
  }

    

   
    
    return (
       <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
            <div class="about-section">
			<div class="container">
				<div class="about-us-heading">
					<h1>Buy Gold Coins</h1>   
			</div>
			</div>
			</div>
           
            <section className="tf-login tf-section">
                <div className="cart-container">
                    <div className="row">                    
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="flat-form box-login-email mgr-btm20">
								<div className="gold-images-sec">
									<img src="images/100-sin-token-bag.png" />
									<div className="gold-token_info">For { by100 } Sin Tokens</div>
								</div>
                                <div className="form-inner">
                                    <button disabled={buy100 == 1 ? true : false} onClick={() => purchaseCoins100()} className="custom-btn gold-btn">
                                    BUY NOW</button>
                                </div>
                            </div>
                        </div>
                         <div className="col-lg-3 col-md-6 text-center">
                            <div className="flat-form box-login-email mgr-btm20">
								<div className="gold-images-sec">
									<img src="images/500-sin-token-bag_.png" />
									<div className="gold-token_info">For { by500 } Sin Tokens</div>
								</div>
                                <div className="form-inner">
                                   <button disabled={buy500 == 1 ? true : false} onClick={() => purchaseCoins500()} className="custom-btn gold-btn">BUY NOW</button>
                                </div>
                            </div>
                        </div>
                        
                         <div className="col-lg-3 col-md-6 text-center">
                            <div className="flat-form box-login-email mgr-btm20">
								<div className="gold-images-sec">
									<img src="images/1000-sin-token-bag_.png" />
									<div className="gold-token_info">For { by1000 } Sin Tokens</div>
								</div>
                                <div className="form-inner">
                                    <button disabled={buy1000 == 1 ? true : false} onClick={() => purchaseCoins1000()} className="custom-btn gold-btn">BUY NOW</button>
                                </div>
                            </div>
                        </div>
                         <div className="col-lg-3 col-md-6 text-center">
                            <div className="flat-form box-login-email mgr-btm20">
								<div className="gold-images-sec">
									<img src="images/10000-sin-token-bag_.png" />
									<div className="gold-token_info">For { by10000 } Sin Tokens</div>
								</div>
                                <div className="form-inner">
                                    <button disabled={buy10000 == 1 ? true : false} onClick={() => purchaseCoins10000()} className="custom-btn gold-btn">BUY NOW</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default BuyCoins;
