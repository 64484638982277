import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import logodark from '../../assets/images/logo/logo_dark.png'
import logofooter from '../../assets/images/logo/logo2.png'
import config from '../../config';
import Cookies from 'js-cookie';
import toast, { Toaster } from 'react-hot-toast';
import { addNewsLetterAction, getSocialLinksAction } from '../../Action/user.action';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
let socialList = [];
const Footer = () => {

    const accountList = []
    const importantLinks = [
        {
            title: "Home",
            link: ""
        },
        {
            title: "My Account",
            link: "profile"
        }, 
        {
            title: "Contact Us",
            link: "contact-us"
        },        
    ]
    const resourcesList = [ ]
    const companyList = [ ]

    const [isVisible, setIsVisible] = useState(false);
    const [validatioError, setvalidatioError] = useState({emailError: ''});

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        getSocialLinksAPI();
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const [email, setEmail] = useState("");
    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setEmail(e.target.value);

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }

    }

    function validate() {
        let emailError = "";

        if (email === '') {
            emailError = "Email is required."
        }
        if (emailError) {
            setvalidatioError({
                emailError
            })
            return false
        } else {
            return true
        }
    }

    const addSubscriberAPI = async (e) => {
        e.preventDefault();

        const isValid = validate();
        if (!isValid) {

        }
        else {

            let res = await addNewsLetterAction({ 'email': email });
            if (res.success) {
                toast.success(res.msg);
                setEmail('')
            } else {
                toast.error(res.msg);
            }
        }
    }

    const getSocialLinksAPI = async () => {
        let res = await getSocialLinksAction();
        if (res.success) {
            socialList = [
                {
                    icon: "fa-solid fa-paper-plane",
                    link: res.data.telegram
                },
                {
                    icon: "fa-brands fa-twitter",
                    link: res.data.twitter
                },
                {
                    icon: "fa-brands fa-instagram",
                    link: res.data.insta
                },
                {
                    icon: "fab fa-medium increased-x",
                    link: res.data.medium
                },
                {
                    icon: "fab fa-discord",
                    link: res.data.discord
                }
            ]

        }
    }

    return (
        <div>
            <Toaster /> 
			<div className="section-updates">
			<div className="container">	
			<div className="heading-updates">
			<h5>Join Us Now</h5>
			<h2>Get the Latest Updates</h2>
			</div>
			<div className="newsletter-input">
			<form id="subscribe-form" action="#" method="GET" acceptCharset="utf-8" className="form-submit">				
			<input id="emailError" type="email" name="email" value={email} onChange={inputHandler}  className="popup-form-input" placeholder="Enter Your Email Address" required />			
			<button id="submit" onClick={addSubscriberAPI} name="submit" type="submit" className="popup-form-submit">Subscribe</button>
			 <span className="validationErr subscribe-validation">{validatioError.emailError}</span>
			</form>
			          
			</div>
			</div>
			</div>

			<div className="section-footer">
			<div className="container">

			<div className="row">
			<div className="col-md-2">
			<div className="last-section-footer">
			<div className="main-ft">
			<img  src="images/Logo.png" />
			</div>
			</div>
			</div>
			<div className="col-md-10">
			<div className="last-section-footer">
			<div className="footer-heading">
			<p>Gather rare and lucrative NFTs designed to help your<br /> activities in Sinverse and propel your Metaverse experience.</p>
			</div>
			</div>
			</div>
			</div>

			<div className="row">		
			<div className="col-md-3">
			<div className="footer-main">
			<div className="footer-1">
			<ul>
			<li><a target="_blank" href="https://sinverse.com/staking">Staking</a></li>
			<li><a target="_blank" href="https://sinverse.com/exchange">Exchange</a></li>			
			<li><a target="_blank" href="https://sinverse.com/tokens">SIN Tokens</a></li>
			<li><a target="_blank" href="https://sinverse.com/downloads" className="btn-fotr">Download</a></li>
			</ul>
			</div>
			</div>
			</div>

			<div className="col-md-3">
			<div className="footer-main">
			<div className="footer-1">
			<ul>
			<li><a target="_blank" href="https://sinverse.com/about">About</a></li>
			<li><a target="_blank" href="https://sinverse.com/game-info">Game Info</a></li>
			<li><a target="_blank" href="https://sinverse.com/news">News</a></li>
			<li><a target="_blank" href="https://sinverse.com/district-reveal">District Reveal</a></li>
			</ul>
			</div>
			</div>
			</div>


			<div className="col-md-3">
			<div className="footer-main">
			<div className="footer-1">
			<ul>
			<h5>Availabe Exchanges</h5>
			<li><a target="_blank" href="https://www.kucoin.com/trade/SIN-USDT"><span><img src="images/fotr1.png" /></span>KuCoin</a></li>
			<li><a target="_blank" href="https://pancakeswap.finance/swap?outputCurrency=0x6397de0f9aedc0f7a8fa8b438dde883b9c201010"><span><img src="images/fotr2.png" /></span>Pancakeswap</a></li>
			<li><a target="_blank" href="https://www.mexc.com/exchange/SIN_USDT"><span><img src="images/fotr3.png" /></span>MXC</a></li>
			<li><a target="_blank" href="https://www.gate.io/trade/SIN_USDT"><span><img src="images/fotr4.png" /></span>Gate.io</a></li>
			</ul>
			</div>
			</div>
			</div>

			<div className="col-md-3">
			<div className="footer-main">
			<div className="footer-1">
			<h5>Contact us</h5>
			<div className="icon-fotr">
			{
				socialList.map((item, index) => (
					<span><a target="_blank" href={item.link}><i className={item.icon}></i></a></span>
				))
			}			
			</div>
			<div className="Contact">
			<a href="mailto:contact@sinverse.com">contact@sinverse.com</a>
			</div>
			</div>
			</div>
			</div>
			</div>

			<div className="all-right">
			<p>© 2023 Sinverse.  All Rights Reserved</p>	
			</div>


			</div>
			</div>

            {
                isVisible &&
                <Link onClick={scrollToTop} to='#' id="scroll-top"><i class="fa fa-4x fa-angle-up"></i></Link>
            }

        </div>

    );
}

export default Footer;
