import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link, useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReactTooltip from 'react-tooltip';
import { getCartAction, getCartNFTsAction,getCartGroupNFTsAction, buyItemAction, validatewhitelistedwalletAction, getWhiteListONOFFAction, buyBNBItemAction, removefromcartAction, getoutofstockCartAction, getcartcountAction,ValidateArmorItemAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import Swal from "sweetalert2";
import toast, { Toaster } from 'react-hot-toast';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));


const Cartitems = () => {
	
	const [cartcount, setcartcount] = useState(0);
    const [CartItems, setCartItems] = useState([]); 
    const [armorItem, setarmorItem] = useState(false);
    const [CartNFTs, setCartNFTs] = useState([]); 
    const [CartGroupNFTs, setCartGroupNFTs] = useState([]); 
    const [IsWhitelistOnOff, getWhiteListONOFF] = useState([]);
    const [walletAddress, setConnectWalletAddress] = useState('');
    const [spinLoader, setSpinLoader] = useState(0);
    const [cartspinLoader, setCartSpinLoader] = useState(0);
    const [isoutofstock, setoutofstock] = useState(0);
    const [buynow, setbuynow] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [modalopen, setModalopen] = useState(0);
    const [Biderror, setBiderror] = useState(0);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [isOwnerChanged, setOwnerChanged] = useState(0);
    const [form, setForm] = useState({
        'bid_price': ''
    })
	
    useEffect(async () => {	
		 
    if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
					 setCartSpinLoader(1)
					setConnectWalletAddress(accounts[0]);
					localStorage.setItem('swallet', accounts[0]);
                }
            })
        }
          
       if (window.ethereum) {
		   setCartSpinLoader(1)
			const { ethereum } = window;
			setConnectWalletAddress(ethereum.selectedAddress);
			localStorage.setItem('swallet', ethereum.selectedAddress);
			
		}
		
		const swallet = localStorage.getItem('swallet');	
		if (swallet != null && swallet != 'null' && swallet != 'undefined') {
			setConnectWalletAddress(swallet);
			getCartAPI(swallet);      
			getCartNFTsAPI(swallet);      
			getCartGroupNFTsAPI(swallet); 
			getoutofstockCartAPI(swallet);  
			getcartcountAPI(swallet); 
				
		}
		else {
			 if (window.ethereum) {
				const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
				localStorage.setItem('swallet', accounts[0]);
				setConnectWalletAddress(accounts[0]);
				getCartAPI(accounts[0]);      
				getCartNFTsAPI(accounts[0]);      
				getCartGroupNFTsAPI(accounts[0]); 
				getoutofstockCartAPI(accounts[0]);  
				getcartcountAPI(accounts[0]); 					
			}
			
		}
		
		   
      

    }, []);

    const getCartAPI = async (wallet) => {		
        let res = await getCartAction({ 'user_id': loginData?.id, 'walletAddress': wallet});
        if (res.success) {
            setCartItems(res.data);
             const hasArmorItem = res.data.some(item => item.nft_type === 1 && item.item_category_id === 8);
			 setarmorItem(hasArmorItem);      
           
        }
        else
        {
			 setCartItems([]);
		}
    }
    
    const getCartNFTsAPI = async (wallet) => {
		
		
        let res = await getCartNFTsAction({ 'user_id': loginData?.id, 'walletAddress': wallet});
        if (res.success) {
            setCartNFTs(res.data);
        }
        else
        {
			 setCartNFTs([]);
		}
    }
    const getCartGroupNFTsAPI = async (wallet) => {
		
        let res = await getCartGroupNFTsAction({ 'user_id': loginData?.id, 'walletAddress': wallet});
        if (res.success) {
            setCartGroupNFTs(res.data);
        }
        else
        {
			 setCartGroupNFTs([]);
		}
    }
    const getoutofstockCartAPI = async (wallet) => {			
		 let res = await getoutofstockCartAction({ 'user_id': loginData?.id, 'walletAddress': wallet });
        if (res.success) {
            setoutofstock(res.data);
        }   
			
			setCartSpinLoader(0)    
    }
    
       
    
	const removefromcart  = async (item_id,is_group) => {
            Swal.fire({
                      title: "Are you sure?",
                      text: "You want to Remove this NFT!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, Remove it!",
                    }).then(async (result) => {
                        if (result.isConfirmed) {                        
                                let res = await removefromcartAction({ 'cart_id': item_id,'is_group':is_group });
                                 Swal.fire({
									  text: res.msg,
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});                                
                           getCartAPI(walletAddress);      
						   getCartNFTsAPI(walletAddress);      
						   getCartGroupNFTsAPI(walletAddress);  
						   getoutofstockCartAPI(walletAddress);  
						   getcartcountAPI(walletAddress);            
						         
                      }
    });
  }
    
    const purchaseItem = async (tokenPrice) => {
        if (!loginData?.id) {
           Swal.fire({  text: 'Please login first!!',
                        title: "Alert",
                        confirmButtonText: 'Okay',
                        confirmButtonColor: '#e7275a',
                        background: '#343444',
                        color: '#FFF',
                        allowOutsideClick:false
                      });
        }
        else {
			if(isoutofstock==0)
			{
				if(buynow==0)
				{
					setbuynow(1);				
				if (window.ethereum) {
					const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
					let web3 = new Web3(window.ethereum);
					let currentNetwork = web3.currentProvider.chainId;
					let chainId = config.chainId;					
					if(armorItem==true)
					{						
						
						let res = await ValidateArmorItemAction({ 'user_id': loginData?.id, 'walletAddress': accounts[0]});
						if (res.success) {
							
							if (currentNetwork !== chainId) {
									Swal.fire({  text: 'Please select BNB network!',
										  title: "Alert",
										  confirmButtonText: 'Okay',
										  confirmButtonColor: '#e7275a',
										  background: '#343444',
										  color: '#FFF',
										  allowOutsideClick:false
										});
									setbuynow(0);
									return false;
								}
								setSpinLoader(1);
								try {
								 Swal.fire({
										title : "Processing...",
										text: 'Transaction is under process, please wait for confirmation!',
										allowEscapeKey: false,
										allowOutsideClick: false,
										showConfirmButton:false,
										background: '#343444',
										color: '#FFF'
									});
									let tx_builderForToken = '';
									let from_address = accounts[0];					
									// Approve token	
									console.log(tokenPrice)					
									//let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
									let tokenValue = web3.utils.toWei(tokenPrice.toString(), 'ether')
									let tokenContractAddress = `${config.tokenContract}`;
									console.log(tokenValue)	
									const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
									let allowance = await tokenContract.methods.allowance(from_address, config.mainMarketplaceContract).call();
									if (parseInt(allowance) < parseInt(tokenValue)) {
											tx_builderForToken = await tokenContract.methods.approve(config.mainMarketplaceContract.toString(), tokenValue.toString());

											setSpinLoader(1);
											setDialogOpen(true);

											let encodedTxForToken = tx_builderForToken.encodeABI();
											let gasPriceForToken = await web3.eth.getGasPrice();

											let gasLimitForToken = await web3.eth.estimateGas({
													gasPrice: web3.utils.toHex(gasPriceForToken),
													to: tokenContractAddress,
													from: from_address,
													chainId: chainId,
													data: encodedTxForToken
											});

											await web3.eth.sendTransaction({
													gasPrice: web3.utils.toHex(gasPriceForToken),
													gas: web3.utils.toHex(gasLimitForToken),
													to: tokenContractAddress,
													from: from_address,
													chainId: chainId,
													data: encodedTxForToken
											});
									}

									let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
									var currentBal = parseInt(getTokenBalance) / 100000000;

									if (currentBal < tokenPrice) 
									{
											setSpinLoader(0);
											setDialogOpen(false); 
											setbuynow(0);                       
											Swal.fire({  text: 'Insufficient fund for transfer',
												  title: "Alert",
												  confirmButtonText: 'Okay',
												  confirmButtonColor: '#e7275a',
												  background: '#343444',
												  color: '#FFF',
												  allowOutsideClick:false
												});
											return false;
									}
									let y = CartItems.length
									const tokenids=[];
									const tokens=[];
									const nfts=[];
									const nftcontractAddress=[];
									for(let i=0;i<CartItems.length;i++)
									{
											tokenids[i]	= CartItems[i].token_id;
											tokens[i]	= web3.utils.toWei(CartItems[i].price.toString(), 'ether');
											nfts[i]		= CartItems[i].nft_id;
											nftcontractAddress[i]		= CartItems[i].contractAddress;
																					
									}						
									await trnasferNFT(tokenids, nfts, tokens, nftcontractAddress);
									
								} catch (error) {
									setSpinLoader(0);
									setDialogOpen(false);
									setbuynow(0);
									if (error.message.toString().split('insufficient funds')[1]) {
									Swal.fire({  text: 'Transaction reverted : insufficient funds',
												  title: "Alert",
												  confirmButtonText: 'Okay',
												  confirmButtonColor: '#e7275a',
												  background: '#343444',
												  color: '#FFF',
												  allowOutsideClick:false
												});
								} else {
									if (error.toString().split('execution reverted:')[1]) {
										Swal.fire({  text: 'Transaction reverted : Something went wrong! Please try again.',
												  title: "Alert",
												  confirmButtonText: 'Okay',
												  confirmButtonColor: '#e7275a',
												  background: '#343444',
												  color: '#FFF',
												  allowOutsideClick:false
												});
									} else {
									Swal.fire({  text: 'Please try again.',
												  title: "Alert",
												  confirmButtonText: 'Okay',
												  confirmButtonColor: '#e7275a',
												  background: '#343444',
												  color: '#FFF',
												  allowOutsideClick:false
												});
										
									}
								}
									getCartAPI(walletAddress);      
									getCartNFTsAPI(walletAddress);      
									getCartGroupNFTsAPI(walletAddress);  
									getoutofstockCartAPI(walletAddress);   
									getcartcountAPI(walletAddress);        
									
									
									return false;
								}
					
							
						}
						else
						{
							 Swal.fire({  text:res.msg,
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
							setbuynow(0);
							return false;
						}
					}
					else
					{					
					
					if (currentNetwork !== chainId) {
						Swal.fire({  text: 'Please select BNB network!',
							  title: "Alert",
							  confirmButtonText: 'Okay',
							  confirmButtonColor: '#e7275a',
							  background: '#343444',
							  color: '#FFF',
							  allowOutsideClick:false
							});
						setbuynow(0);
						return false;
					}
					setSpinLoader(1);
					try {
					 Swal.fire({
							title : "Processing...",
							text: 'Transaction is under process, please wait for confirmation!',
							allowEscapeKey: false,
							allowOutsideClick: false,
							showConfirmButton:false,
							background: '#343444',
							color: '#FFF'
						});
						let tx_builderForToken = '';
						let from_address = accounts[0];					
						// Approve token	
						console.log(tokenPrice)					
						//let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
						let tokenValue = web3.utils.toWei(tokenPrice.toString(), 'ether')
						let tokenContractAddress = `${config.tokenContract}`;
						console.log(tokenValue)	
						const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
						let allowance = await tokenContract.methods.allowance(from_address, config.mainMarketplaceContract).call();
						if (parseInt(allowance) < parseInt(tokenValue)) {
								tx_builderForToken = await tokenContract.methods.approve(config.mainMarketplaceContract.toString(), tokenValue.toString());

								setSpinLoader(1);
								setDialogOpen(true);

								let encodedTxForToken = tx_builderForToken.encodeABI();
								let gasPriceForToken = await web3.eth.getGasPrice();

								let gasLimitForToken = await web3.eth.estimateGas({
										gasPrice: web3.utils.toHex(gasPriceForToken),
										to: tokenContractAddress,
										from: from_address,
										chainId: chainId,
										data: encodedTxForToken
								});

								await web3.eth.sendTransaction({
										gasPrice: web3.utils.toHex(gasPriceForToken),
										gas: web3.utils.toHex(gasLimitForToken),
										to: tokenContractAddress,
										from: from_address,
										chainId: chainId,
										data: encodedTxForToken
								});
						}

						let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
						var currentBal = parseInt(getTokenBalance) / 100000000;

						if (currentBal < tokenPrice) 
						{
								setSpinLoader(0);
								setDialogOpen(false); 
								setbuynow(0);                       
								Swal.fire({  text: 'Insufficient fund for transfer',
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
								return false;
						}
						let y = CartItems.length
						const tokenids=[];
						const tokens=[];
						const nfts=[];
						const nftcontractAddress=[];
						for(let i=0;i<CartItems.length;i++)
						{
								tokenids[i]	= CartItems[i].token_id;
								tokens[i]	= web3.utils.toWei(CartItems[i].price.toString(), 'ether');
								nfts[i]		= CartItems[i].nft_id;
								nftcontractAddress[i]		= CartItems[i].contractAddress;
															 			
						}						
						await trnasferNFT(tokenids, nfts, tokens, nftcontractAddress);
						
					} catch (error) {
						setSpinLoader(0);
						setDialogOpen(false);
						setbuynow(0);
						if (error.message.toString().split('insufficient funds')[1]) {
						Swal.fire({  text: 'Transaction reverted : insufficient funds',
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
					} else {
						if (error.toString().split('execution reverted:')[1]) {
							Swal.fire({  text: 'Transaction reverted : Something went wrong! Please try again.',
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
						} else {
						Swal.fire({  text: 'Please try again.',
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
							
						}
					}
						getCartAPI(walletAddress);      
						getCartNFTsAPI(walletAddress);      
						getCartGroupNFTsAPI(walletAddress);  
						getoutofstockCartAPI(walletAddress);   
						getcartcountAPI(walletAddress);        
						
						
						return false;
					}
					
					} 
				}
				else {
					Swal.fire({  text: 'Please Connect to MetaMask.',
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
						setbuynow(0);
					return false;
				}
				
			}
			}
			else
			{
					 Swal.fire({  text: 'Cart has some out of stock NFTs.',
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
					 return false;
			}
       
        
    }
     }
		 
    const trnasferNFT = async (tokenids,nfts,tokens,nftcontractAddress) => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let web3 = new Web3(window.ethereum);
            try {
                 let contractAddress = `${config.mainMarketplaceContract}`;
                let from_address = accounts[0];
                const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);
                let tx_builder = '';
                //let itemPrice = 0;
               // itemPrice = tokenPrice / 10 ** 18;
              //  console.log('buyNow',tokenids, nfts, tokens, nftcontractAddress)
                tx_builder = await contract.methods.buyNow(nftcontractAddress,tokenids, tokens);
                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                if (txData.transactionHash) {                    
                    for(let i=0;i<tokenids.length;i++)
                    {
                     var paymentArr = {
                        email: loginData?.email,
                        user_id: loginData?.id,
                        txHash: txData.transactionHash,
                        amount: tokens[i] / 10 ** 18,
                        to_address: from_address,
                        owner_address: from_address,
                        item_id: nfts[i]
                        }
                        console.log(paymentArr);
                        buyItemAPI(paymentArr,tokenids.length,i+1);						 			

                    }
						
                   
                } else {
                    Swal.fire({  text: 'Please try again.',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
                    setSpinLoader(0);
                    setDialogOpen(false);
                    setbuynow(0);
                    return false;
                }

            } catch (err) {
                console.log(err);
                setbuynow(0);
                if (err.message.toString().split('insufficient funds')[1]) {
                    Swal.fire({  text: 'Transaction reverted : insufficient funds',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                       Swal.fire({  text: 'Transaction reverted : Please try again.',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
                    } else {
                        Swal.fire({  text: 'Please try again.',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
                    }
                }
                setSpinLoader(0);
                setDialogOpen(false);
                return false;
            }

        } else {
           
            Swal.fire({  text: 'Please Connect to MetaMask.',
				  title: "Alert",
				  confirmButtonText: 'Okay',
				  confirmButtonColor: '#e7275a',
				  background: '#343444',
				  color: '#FFF',
				  allowOutsideClick:false
				});
            setSpinLoader(0);
            setDialogOpen(false);
            setbuynow(0);
            return false;
        }
    }

    const buyItemAPI = async (data,itemslength,index) => {
        let res = await buyItemAction(data);
        console.log(itemslength,index)
        if (res.success) {
			Swal.fire({  text: res.msg,
				  title: "Alert",
				  confirmButtonText: 'Okay',
				  confirmButtonColor: '#e7275a',
				  background: '#343444',
				  color: '#FFF',
				  allowOutsideClick:false
				});
            if(itemslength===index)
                {
                    setTimeout(() => {                
                         window.location.reload();               
                    }, 2000);
             }
        } else {
            Swal.fire({  text: 'Please try again.',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
        }
    }
             
    const connectMetasmask = async () => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setConnectWalletAddress(accounts);
        }
        else {
           Swal.fire({  text: 'Please use dApp browser to connect wallet!',
				  title: "Alert",
				  confirmButtonText: 'Okay',
				  confirmButtonColor: '#e7275a',
				  background: '#343444',
				  color: '#FFF',
				  allowOutsideClick:false
				});
        }
    }

    const closebutton = async () => {
        setModalopen(0);
    }

    const inputHandler = (e) => {
        const { name, value } = e.target
        setForm({ ...form, [name]: value })
    }
	
	const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
   
  
    return (
        <div className='item-details'>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
             <div class="about-section">
			<div class="container">
				<div class="about-us-heading">
					<h1>Cart Items</h1>   
			</div>
			</div>
			</div>
           
            <div className="tf-section cart-section style-2">
            {cartspinLoader==0 ?
                <div className="themesflat-container cart-container">					
                    <div className="row">
                        <div className="col-xl-12 col-md-12 limted-ht text-center">
                        <div class="table-responsive">
                         <table class="table">
                             <tr>                                
                                <th>Image</th>
                                <th>Item Name</th>
                                <th>Stock</th>
                                <th>Price</th>                                
                                <th className="w100px"></th>
                             </tr>
                              {CartItems.length === 0 ?                                            
								  <tr>
									<td colSpan="5" className="text-center">Cart is empty</td>
								  </tr>
								  :
								  (
									<>
									  {CartNFTs.slice(0, 1000).map((item, index) => (
										<tr key={index}>
										  <td>
											{item.isCollected ? (
											  <img className="cart-img" src="images/lant-plot.png"/>
											) : (
											  <img className="cart-img" src={`${config.ipfsUrl + item.image}`}/>
											)}
										  </td>
										  <td>
											<Link to={`${config.baseUrl}nft-details/` + item.nft_id}>{item.name}</Link>
											
											</td>
											<td>{item.is_on_sale === 0 ? (
											  <span className="error">Out of stock </span>
											) : (
											  <span className="success">In stock </span>
											)}</td>
										  <td>{parseInt(item.price).toLocaleString('en-US')} SIN</td>
										  
										  <td className="text-center">
											<button onClick={() => { removefromcart(item.id, 0) }} className="detailsPageBtn remove-button">X</button>
										  </td>
										</tr>
									  ))}
									  {CartGroupNFTs.slice(0, 1000).map((item, index) => (
										<tr key={index}>
										  <td>
											<img className="cart-img" src="images/lant-plot.png" width="60px" height="60px" />
										  </td>
										  <td>
											<Link to={`${config.baseUrl}land-group-details/` + item.id}>{item.title}</Link>											
										  </td>
										  <td>{item.is_on_sale === 0 ? (
											  <span className="error">Out of stock </span>
											) : (
											  <span className="success">In stock </span>
											)}</td>
										  <td>{parseInt(item.price).toLocaleString('en-US')} SIN</td>
										  <td className="text-center">
											<button onClick={() => { removefromcart(item.id, 1) }} className="detailsPageBtn remove-button">X</button>
										  </td>
										</tr>
									  ))}
									</>
								  )
								}
                
                        
							{CartItems.length > 0 ?		
								<tr>
									<td colspan="2"> </td> 
									<td>Total </td>  
									<td colspan="2">{parseInt(CartItems.reduce((total, item)=>total+(item.price*1),0)).toLocaleString('en-US')} SIN</td>
									</tr> 
									: ''
							}		
							</table>
                         </div>  
                         </div>  
                          <div className="col-xl-12 col-md-12 padd-top-25">
                         {CartItems.length > 0 ?
                         walletAddress ?
                         <>                         
                          <div className="col-md-12 cart-button-section"> 
                          <p className="outofstock">Please checkout within 10 minutes else your shopping cart will be cleared.</p>
                          <p className="outofstock">
							  {isoutofstock === 1 ? (
								<span>Please remove the <b>Out of Stock</b> items to proceed to checkout.</span>
							  ) : (
								''
							  )}
							</p>
                          <button onClick={() => purchaseItem(CartItems.reduce((total, item)=>total+(item.price*1),0))} className="custom-btn cart-button" disabled={isoutofstock ? 'true' : spinLoader ? true : false}>{spinLoader == 1 ? 'Processing...' : `Buy Now`}</button> 
                          </div>                         
                          </>
                           :
                            <div className="col-md-12 cart-button-section"> 
                            <button onClick={() => connectMetasmask()} className="custom-btn cart-button">Connect Wallet</button>
                              </div>  
                          : ''   
                          }   
                       </div>              
                    </div>
                </div>
				:
				<div className="text-center">
				<img src="images/loader.gif" height={50} width={50} />
			</div>		
				}
            </div>

          

            <Footer />
        </div>
    );
}

export default Cartitems;
