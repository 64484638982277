import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import config from '../config';
import { LoginAction, verifyAccountAction } from '../Action/user.action';

const Login = () => {

    const [form, setForm] = useState({ email: '', password: '' })
    const [validatioError, setvalidatioError] 	= useState({});
    const [isLoading, setisLoading] 			= useState(false);
    const [cartcount, setcartcount] 			= useState(0); 
    const [rememberMe, setRememberMe] = useState(false);  
    let { token } = useParams();

    useEffect(() => {
        if (token) {
            verifyAccountAPI()
        }
    }, []);

    const verifyAccountAPI = async () => {
        let res = await verifyAccountAction({ 'token': token });
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                window.location.href = `${config.baseUrl}login`;
            }, 1000);
        } else {
            toast.error(res.msg);
        }
    }

    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setForm((old) => {
            return { ...old, [name]: value }
        })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }

    }    
    

    function validate() {
        let emailError = "";
        let passwordError = "";

        if (form.email === '') {
            emailError = "Email is required."
        }
        if (form.password === '') {
            passwordError = "Password is required."
        }
        if (emailError || passwordError) {
            setvalidatioError({
                emailError, passwordError
            })
            return false
        } else {
            return true
        }
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {
            setisLoading(true);

            setTimeout(async () => {
                let res = await LoginAction({ 'email': form?.email,'password': form?.password,'rememberMe': rememberMe});
                if (res.success) {
					const expirationDate = new Date();
					
					if(rememberMe)
					{
						expirationDate.setTime(expirationDate.getTime() + 30 * 24 * 60 * 60 * 1000); // 30days in milliseconds
					}
					else
					{
						expirationDate.setTime(expirationDate.getTime() + 1 * 24 * 60 * 60 * 1000); // 24hr in milliseconds
					}
					

                    toast.success(res.msg);
                    Cookies.set('loginSuccessSinverseUserpanel', JSON.stringify(res.data), { expires: expirationDate });
                    Cookies.set('token', JSON.stringify(res.Token),{ expires: expirationDate });
                    setTimeout(() => {
                        window.location.href = `${config.baseUrl}`;
                    }, 2000);
                } else {
                    setisLoading(false);
                    toast.error(res.msg);
                }
            }, 1000);
        }
    }

    return (
        <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
            
		<div class="registration-section">
	<div class="container">
		<div class="registration-container">
			<div class="row">
				<div class="col-md-12">
					<div class="Registration">
						<h2>Login</h2>
					</div>
				<div class="in-tech  login-block">
				<form onSubmit={SubmitForm}>
				<div className="row">
					<div className="col-md-12">
						<div class="form-group">
							<label>Email Address <span className="validationErr">{validatioError.emailError}</span></label>
							<input autoComplete="off" name="email" id='emailError' onChange={inputHandler} type="email" placeholder="Enter Email Address" class="form-control" />						
						</div> 
						<div class="form-group">
							<label>Password <span className="validationErr">{validatioError.passwordError}</span></label>
							<input class="form-control"name="password" type="password" id='passwordError' onChange={inputHandler} placeholder="Enter Password" />					
						</div> 
					</div>					
					</div> 
					<div class="row align-items-center">
						<div class="col-6  remember">
							 <input
								  type="checkbox"
								  checked={rememberMe}
								  onChange={() => setRememberMe(!rememberMe)}
								/>
								Remember Me
						</div>
						<div class="col-6">
							<div class="your-password">
								  <Link to={`${config.baseUrl}forget-password`} className="forgot-pass">Forgot Password ?</Link>
							</div>
						</div>				
						<div class="col-md-12">
							<div class="login button">
							 {isLoading ?
                                            <button disabled className="login-btn">Processing &nbsp; <i className="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></button>
                                            :
                                            <button className="login-btn">Login</button>
                                        }
								
							</div>
						</div>
					</div>
				</form>
				</div>
		</div>
	</div>

	</div>
	</div>
</div>


            <Footer />
        </div>
    );
}

export default Login;
