import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import { getcitieslistAction, getcartcountAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import Swal from "sweetalert2";

const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));



const Licenses = () => {
	
	const [cartcount, setcartcount] = useState(0);
	const [SearchFilter, setSearchFilter] = useState('');
    const [spinLoader, setSpinLoader] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [modalopen, setModalopen] = useState(0);   
    const [citylist, setCityList] = useState([]);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [walletAddress, setwalletAddress] = useState('');
   
    useEffect(async () => {        
       getCities();
       
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
               if (accounts) {
					setwalletAddress(accounts[0]);
                     getcartcountAPI(accounts[0]);
                }
            })
        }

        setTimeout(() => {
            if (window.ethereum) {
                const { ethereum } = window;
                setwalletAddress(ethereum.selectedAddress);
                  getcartcountAPI(ethereum.selectedAddress);
            } else {
                
            }
        }, 100);     
       

    }, []);    
   
    const getCities = async () => {
		let res = await getcitieslistAction();
		if (res.success) {    
		  setCityList(res.data);
		}
		
	};
	
	const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
  
   const handleKeyPress = async (event) => {		
		 if(event.key === "Enter"){
		   let res = await getcitieslistAction({ 'name': SearchFilter });
				if (res.success) {
					if(res.data.length > 0){
						setCityList(res.data);
					}else{
						setCityList([]);
					}
				}else{
					setCityList([]);
				}
		 }
	 }
    
    const SetNameFilter = async (e) => {
        setSearchFilter(e.target.value);  
        let res = await getcitieslistAction({ 'name': e.target.value });
            if (res.success) {
                if(res.data.length > 0){
                    setCityList(res.data);
                }else{
                    setCityList([]);
                }
            }else{
                setCityList([]);
            }  
        
    }

     
    
    return (
       <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
            
           <div className="market-place-banner">
			  <h1>MarketPLACE</h1>
			</div>
			<div className="market-place">
			<div className="container">
			<div className="row">
			  <div className="col-12 col-md-3 col-lg-2 px-0 ">
				<div className="left-sidebar">
				 <ul>
					<li><Link to={`${config.baseUrl}marketplace`} className="">Weapon & Armor Collections</Link></li>
					<li><Link to={`${config.baseUrl}land-marketplace`} className="">Land</Link></li>
					<li className="active"><Link to={`${config.baseUrl}licenses-cities`} className="">License</Link></li>					
					<li><Link to={`${config.baseUrl}othernfts`} className="">Other NFTs</Link></li>	
					<li><Link to={`${config.baseUrl}gold-coins`} className="">Gold Coins</Link></li>
				  </ul>
				</div>
			  </div>
			   <div className="col-12 col-md-9 col-lg-10 pl-4">
				<div className="row">
				<div className="col-12 d-flex pro-filter-outer">					
					<div className="pro-search">
						<form className="search-form">
							<input type="text" id="search" className="form-control" placeholder="Search..." onChange={SetNameFilter} name="s" title="Search for" value={SearchFilter} onKeyDown={() => handleKeyPress(this)} />
							<i className="fa-solid fa-magnifying-glass"></i>           
						</form>	
					</div>
				</div>	
								 
				  <div className="col-12"><div className="pro-line"></div></div>
				  <div className="col-12">
					<h2 className="title">Explore Collection</h2>
				  </div>      
					{citylist.length == 0 ?
						<>
							<h4 className="not-found">No data found!!!</h4>
						</>
						:
						citylist.map((item, index) => (
						 <div className="col-md-4">
							<div className="Business-images">
							<Link to={`${config.baseUrl}licenses-city/` + item.id}>
								<img src={`${config.imageUrl + item?.image}`} alt="{item.title}"  />
								 </Link>
							</div>
							<div className="Business-btn">
							<a href={`${config.baseUrl}licenses-city/` + item.id}>
								<button>{item.title} ({item.licCount})</button>
							</a>	
							</div>
						</div>
					 ))}
					
					</div>
					</div>
					</div>
					</div>
					</div>
				   

            <Footer />
        </div >
    );
}

export default Licenses;
