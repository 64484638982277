import img1 from '../images/slider/slide_1.png';
import img2 from '../images/slider/slide_1.png';
import imgbg1 from '../images/slider/bg_slide_1.png'
import imgbg2 from '../images/slider/bg_slide_1.png'


const heroSliderData = [
    {
        title_1: "WELCOME TO THE  ",
        title_2: "SINVERSE",
        title_3: "NFT MARKETPLACE",
        description: "Build your way to success and trade business licenses, exclusive equipment and more to survive, thrive, and become the ultimate Kingpin in this R-rated Metaverse. Discover our collection now!",
        img: img1,
        imgbg: imgbg1,
        class:'left'
    },
    {
        title_1: "Discover, find,",
        title_2: "Sell extraordinary",
        title_3: "Monster NFTs",
        description: "Marketplace for monster character cllections non fungible token NFTs",
        img: img2,
        imgbg: imgbg2,
        class:'center'
    },
    {
        title_1: "Discover, find,",
        title_2: "Sell extraordinary",
        title_3: "Monster NFTs",
        description: "Marketplace for monster character cllections non fungible token NFTs",
        img: img2,
        imgbg: imgbg2,
        class:'right'
    },
    
]

export default heroSliderData;
