import React, { useState, Fragment, useEffect } from 'react';
import { Link, useSearchParams  } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Accordion } from 'react-bootstrap-accordion'
import { getMarketplaceLandNFTsAction, getCategoryAction, getCityAction, nftLikeDislikeAction, getlandroadsAction, getcartcountAction, getSettingsAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import DamageRating from '../components/layouts/DamageRating';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));

const Land = () => {
    const [searchParams] = useSearchParams();
    const [cartcount, setcartcount] = useState(0);
     const [settings, setSettings] = useState([]);
    const [SearchFilter, setSearchFilter] = useState(searchParams.get('s'));
    const [visible, setVisible] = useState(6);
    const [NFTList, setNFTList] = useState([]);
    const [groupNFTList, setgroupNFTList] = useState([]);
    const [Category, setCategory] = useState([]);
    const [Cities, setCities] = useState([]);
    const [CityIdsFilters, setCitiesIdsFilters] = useState([]);
    const [RarityIdsFilter, setRarityFilter] = useState([]);    
    const [PriceFilterData, setPriceFilterData] = useState([]);
    const [NFTTypeData, setNFTTypeData] = useState([]);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [modalopen, setModalopen] = useState(0);
    const [Landroads, setLandRoads] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
	useEffect(async () => {
        getNFTListAPI();
        getLandRoadsAPI();
        getCategoryAPI();
        getCitiesAPI();
        getSettingsAPI();
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            } 
    }, []);

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 6);
    }

    const getNFTListAPI = async () => {
        let res = await getMarketplaceLandNFTsAction({ 'user_id': loginData?.id, 'city_ids': [], 'nft_type': '', 'price_type': '','name': SearchFilter,'rarity':RarityIdsFilter });
        if (res.success) {
            setNFTList(res.data);
            setgroupNFTList(res.groupdata);            
        }
    }
    
    const getSettingsAPI = async () => {
        let res = await getSettingsAction();
        if (res.success) {
            setSettings(res.data);
        }
    }
    
    const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
    
    const getLandRoadsAPI = async () => {
        let res = await getlandroadsAction();
        if (res.success) {
           setLandRoads(res.data);            
        }
        else
        {
			setLandRoads([])
		}
    }

    const getCategoryAPI = async () => {
        let res = await getCategoryAction();
        if (res.success) {
            setCategory(res.data)
        }
    }
    
     const getCitiesAPI = async () => {
        let res = await getCityAction();
        if (res.success) {
            setCities(res.data)
        }
    }
    
    
     const handleKeyPress = async (event) => {
		if(event.key === "Enter"){
		   let res = await getMarketplaceLandNFTsAction({ 'user_id': loginData?.id, 'city_ids': CityIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': SearchFilter,'rarity':RarityIdsFilter });
				if (res.success) {
					setNFTList(res.data);
					setgroupNFTList(res.groupdata);
					
				}else{
					setNFTList([]);
					setgroupNFTList([]);
				}
		 }
	 }
    
     const SetNameFilter = async (e) => {
        setSearchFilter(e.target.value);  
         let res = await getMarketplaceLandNFTsAction({ 'user_id': loginData?.id, 'city_ids': CityIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': e.target.value,'rarity':RarityIdsFilter });
            if (res.success) {              
			  setNFTList(res.data);
			  setgroupNFTList(res.groupdata);			
		}else {
			  setNFTList([]);
			  setgroupNFTList([]);
			}

        
    }

    const CityFilter = async () => {
		
		
        let cityIds = []       
        $("input[name='city_id[]']:checked").each(function () {
            cityIds.push(parseInt($(this).val()));
        });

        const catidsArray = cityIds.filter(function (value) {
            return !Number.isNaN(value);
        });
		setCitiesIdsFilters(catidsArray);
         let res = await getMarketplaceLandNFTsAction({ 'user_id': loginData?.id, 'city_ids': catidsArray, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': SearchFilter,'rarity':RarityIdsFilter });
            if (res.success) {             
                    setNFTList(res.data);      
                    setgroupNFTList(res.groupdata);		      
            }else{
                setNFTList([]); 
                setgroupNFTList([]);
            }
        
    }
    
    const PriceFilter = async (type) => {
        setPriceFilterData(type);
        let res = await getMarketplaceLandNFTsAction({ 'user_id': loginData?.id, 'city_ids': CityIdsFilters, 'nft_type': NFTTypeData, 'price_type': type,'name': SearchFilter,'rarity':RarityIdsFilter });
        if (res.success) {
            if(res.data.length > 0){
                setNFTList(res.data);
            }else{
                setNFTList([]);
            }
        }else{
            setNFTList([]);
        }
    }
    
    
    
    const nftLike = async (type, item_id) => {
        let res = await nftLikeDislikeAction({ 'type': type, 'item_id': item_id });
        if (res.success) {
            getNFTListAPI();
        }
    } 
    
    const closebutton = async () => {
        setModalopen(0);
    }

    
     const ShowPlotDetails = (cell_number) => {
		let cell			= cell_number-1; 
		var Owner_Plots		= [];
		const size 			= 274;
		const hsize 		= 149;		
		const canvas 		= document.getElementById('myCanvas');
		canvas.width 		= 1249;
		canvas.height 		= 688;
		const ctx 			= canvas.getContext("2d");
		const tileWSize 	= canvas.width / size;
		const tileHSize 	= canvas.height / hsize;
		let lastTile 		= -1;
		for (let y = 0; y < canvas.height / tileHSize; y++) {
			for (let x = 0; x < canvas.width / tileWSize; x++) {
			const parity = (x + y) % 2;
			const tileNum = x + canvas.width / tileWSize * y;
			const xx = x * tileWSize;
			const yy = y * tileHSize;     
			if(parseInt(tileNum)	=== parseInt(cell))  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 1;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#dc3545";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);				
			}
			else if(Landroads.indexOf(tileNum) !== -1)  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#000000";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);
			} 		
			else
			{
				ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
			}
					
			  
			}
		  }

			ctx.lineWidth = .1;
			ctx.stroke(); 
			
				 
        setModalopen(1);
        
    }
    

     const ShowGroupPlotDetails = (cell_numbers) => {
		var Owner_Plots		= [];
		var values			= [];
		values			= cell_numbers.split(',').map(Number);
		Owner_Plots			= values.map((value) => parseInt(value, 10) - 1);
		const size 			= 274;
		const hsize 		= 149;		
		const canvas 		= document.getElementById('myCanvas');
		canvas.width 		= 1249;
		canvas.height 		= 688;
		const ctx 			= canvas.getContext("2d");
		const tileWSize 	= canvas.width / size;
		const tileHSize 	= canvas.height / hsize;
		let lastTile 		= -1;
		for (let y = 0; y < canvas.height / tileHSize; y++) {
			for (let x = 0; x < canvas.width / tileWSize; x++) {
			const parity = (x + y) % 2;
			const tileNum = x + canvas.width / tileWSize * y;
			const xx = x * tileWSize;
			const yy = y * tileHSize;     
			if(Owner_Plots.indexOf(tileNum) !== -1)  			
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 1;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#dc3545";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);				
			}
			else if(Landroads.indexOf(tileNum) !== -1)  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#000000";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);
			} 				
			else
			{
				ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
			}
					
			  
			}
		  }

			ctx.lineWidth = .1;
			ctx.stroke(); 
			
				 
        setModalopen(1);
        
    }
    

     
    
    return (
        <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
			<div className="market-place-banner">
			  <h1>MarketPLACE</h1>
			</div>
			<div className="market-place">
			<div className="container">
			<div className="row">
			  <div className="col-12 col-md-3 col-lg-2 px-0 ">
				<div className="left-sidebar">
				 <ul>
					<li><Link to={`${config.baseUrl}marketplace`} className="">Weapon & Armor Collections</Link></li>
					<li className="active"><Link to={`${config.baseUrl}land-marketplace`} className="">Land</Link></li>
					<li><Link to={`${config.baseUrl}licenses-cities`} className="">License</Link></li>	
					<li><Link to={`${config.baseUrl}othernfts`} className="">Other NFTs</Link></li>	
					<li><Link to={`${config.baseUrl}gold-coins`} className="">Gold Coins</Link></li>				
				  </ul>
				</div>
			  </div>
			  <div className="col-12 col-md-9 col-lg-10 pl-4">
				<div className="row">				
				<div className="col-12 d-flex pro-filter-outer">
					<div className="pro-filter">
					  <div className="dropdown city">
			  <button className="btn btn-secondary dropdown-toggle" type="button" id="city" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				District <i className="fa-solid fa-chevron-down"></i>
			  </button>
			  <div className="dropdown-menu" aria-labelledby="city">
			  <form action="#">
			   {Cities.map((cityList) => (
					<>
						<label className="filter-lebel">
						<input onClick={() => CityFilter()} type="checkbox" name='city_id[]' value={cityList.id} /><span className="filter-btn-checkbox"></span>
							{cityList.title}							
						</label><br />
					</>
				))}
				</form>
			  </div>
			</div>
			<div className="dropdown price-range">
			  <button className="btn btn-secondary dropdown-toggle" type="button" id="price-range" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				Price Range <i className="fa-solid fa-chevron-down"></i>
			  </button>
			  <div className="dropdown-menu" aria-labelledby="price-range">
				<form action="#">
					<div key="3">
						<label className="filter-lebel">
						<input onClick={() => PriceFilter('lowtohigh')} type="radio" name='price_type' value="lowtohigh" />
							<span className="filter-btn-checkbox"></span>Price: Low to high							
						</label><br />

						<label className="filter-lebel"><input onClick={() => PriceFilter('hightolow')} type="radio" name='price_type' value="hightolow" />
							<span className="filter-btn-checkbox"></span>Price: High to low							
						</label><br />
						
					</div>
				</form>
			  </div>
			</div>
			</div>
			<div className="pro-search">
					  <form className="search-form">
							 <input type="text" id="search" className="form-control" placeholder="Search (Title Or Token Id)..." onChange={SetNameFilter} name="s" title="Search for" value={SearchFilter} onKeyDown={() => handleKeyPress(this)} />
							   <i className="fa-solid fa-magnifying-glass"></i>           
							</form>							

					</div>
				  </div>			 
				  <div className="col-12"><div className="pro-line"></div></div>
				  <div className="col-12">
				  {NFTList.length != 0 ?
					<h2 className="title">Single Land plots for Sale ({NFTList.length})</h2>
					: ''
				}
				  </div>
				  {NFTList.length == 0 && groupNFTList.length === 0 ?
					<>
						<h4 className="not-found">No data found!!!</h4>
					</>
					:
					NFTList.slice(0, visible).map((item, index) => (
					<div className="col-lg-4 col-md-6">
							<div className="tab-section-main">
								<div className="tab section">
									<div className="profile-images">									
									</div>
									<div className="Andrew-text">
									<p>{item.owner_address.toString().substring(0, 4) + '...' + item.owner_address.toString().substring(item.owner_address.length - 4)}</p>	
									</div>
									<div className="height-icon">
										<i className="fa-regular fa-heart"></i>
										<p>{item.itemLike}</p>
									</div>
								</div>
								<div className="images-sec">
								<Link to={`${config.baseUrl}nft-details/` + item.id}>
									<img src="images/lant-plot.png" />
									</Link>
								</div>
								<div className="section-map">
									<h3>
									{
									  windowWidth < 1299 ?						
										<>					
										<span className="fnt-16" >{ item.name.toString().substring(0, 15) } { item.name.length > 15 ? '...' : ''}</span>
										</>
										:
										<>					
										<span className="fnt-16" >{ item.name.toString().substring(0, 23) }
										{ item.name.length > 23 ? '...' : ''}
										</span>
										</>
									}
									<span className="pull-right"><a href="javascript:;" onClick={() => { ShowPlotDetails(item.cell_number) }}><i class="fa-regular fa-eye" aria-hidden="true"></i></a></span>
									<br />
									<span className="">{item.city_name}</span>
									</h3> 
								</div>	
								{ settings.is_land_on_sale ?
								<>								
								<div className="price-section">
									<div className="price">
										<p>Price</p>
										<h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
									</div>
									<div className="price">
										<p>Token ID</p>
										<h3>#{item.token_id}</h3>
									</div>
									<Link to={`${config.baseUrl}nft-details/` + item.id}>
											<button className="tab-btn">Buy</button></Link>
								</div>
								</>
								:
								<>								
								<div className="price-section">									
									<div className="price">
										<p>Token ID</p>
										<h3>#{item.token_id}</h3>
									</div>
									<Link to={`${config.baseUrl}nft-details/` + item.id}>
											<button className="tab-btn">Coming Soon</button></Link>
								</div>
								</>
							}
								
								
								
								<div className="View-History">
								<Link to={`${config.baseUrl}nft-details/` + item.id+`#history`}><i className="fa-solid fa-clock-rotate-left"></i>View History</Link>
								</div>
							</div>
						</div>
					
					
					
					))}
					{
                        visible < NFTList.length &&
                                                      
					<div className="lorn-more col-12 text-center">
					<Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><button className="tab-last-btn btn-bg-pink px-5">Load More</button></Link>					
					</div>
					  }
					  
					 {groupNFTList.length === 0 ? '' : (
						  <>
							<div className="col-12">
							  <h2 className="title">Group Land plots for Sale</h2>
							</div>
							{groupNFTList.map((item, index) => (
							  <div className="col-lg-4 col-md-6">
								<div className="tab-section-main">
								  <div className="tab section">
									<div className="profile-images"></div>
									<div className="Andrew-text">
									  <p>
										{item.owner_address
										  .toString()
										  .substring(0, 4) +
										  "..." +
										  item.owner_address.toString().substring(item.owner_address.length - 4)}
									  </p>
									</div>
								  </div>
								  <div className="images-sec">
									<Link to={`${config.baseUrl}land-group-details/` + item.id}>
									  <img src="images/lant-plot.png" />
									</Link>
								  </div>
								  <div className="section-map">
									<h3>
									{
									  windowWidth < 1299 ?						
										item.title.toString().substring(0, 15) + '...'
										:
										item.title.toString().substring(0, 23) +
										(item.title.length > 23 ? '...' : '')
									}
									
									<span className="pull-right"><a href="javascript:;" onClick={() => { ShowGroupPlotDetails(item.landgroupcells) }}><i class="fa-regular fa-eye" aria-hidden="true"></i></a></span></h3>
								  </div>
								  { settings.is_land_on_sale ?
								<>
								  <div className="price-section">
									<div className="price">
									  <p>Price</p>
									  <h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
									</div>
									<Link to={`${config.baseUrl}land-group-details/` + item.id}>
									  <button className="tab-btn">Buy</button>
									</Link>
								  </div>
								  </>
								  :
								  <>
								  <div className="price-section">									
									<Link to={`${config.baseUrl}land-group-details/` + item.id}>
									  <button className="tab-btn">Coming Soon</button>
									</Link>
								  </div>
								  </>
							  }
								  								  
								</div>
							  </div>
							))}
						  </>
						)}


					</div>
					</div>
					</div>
					</div>
					</div>
				  <div id="myModal" className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered gridmodal">
                    <div className="modal-content">
						<button type="button" onClick={closebutton} className="close btnClose" data-dismiss="modal">&times;</button>                                      
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">                                        
                                        <div class="graph text-center">
											<canvas id="myCanvas"></canvas>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  

            <Footer />
        </div >
    );
}

export default Land;
