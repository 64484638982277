import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link, useParams  } from 'react-router-dom'
import { getUserNFTListAction, getUserDetailsAction } from '../Action/user.action';
import config from '../config';
import Web3 from 'web3';
import Cookies from 'js-cookie';


const Userportfolio = () => {
    const { id } = useParams();
    const [NFTList, setNFTList] = useState([]);
    const [UserDetails, setUserDetails] = useState([]); 
    const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
    const [walletAddress, setwalletAddress] = useState('');
    useEffect(async () => {
        getUserNFTListAPI();
        getUserDetailsAPI();
    }, []);

    
    const getUserNFTListAPI = async () => {
        let res = await getUserNFTListAction({ 'id': id });
        if (res.success) {
            setNFTList(res.data);
        }
    }
    
     const getUserDetailsAPI = async () => {
        let res = await getUserDetailsAction({ 'id': id });
        if (res.success) {
            setUserDetails(res.data);
        }
    }

   
    return (
        <div className='auctions'>            

            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{UserDetails?.first_name} Portfolio</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section live-auctions">
                <div className="themesflat-container">
                    <div className="row">

                        {NFTList.map((item) => (
                            <div className="fl-item col-xl-3 col-lg-6 col-md-6">
                                <div className="sc-card-product">                                   
                                    <div className="card-media">
                                        <Link to={`${config.baseUrl}nft-details/` + item.id}>
                                            <img src={`${config.ipfsUrl + item.image}`} alt="axies" />
                                        </Link>
                                    </div>
                                    <div className="card-title">
                                        <h5><Link to={`${config.baseUrl}nft-details/` + item.id}>{item.name}</Link></h5>
                                    </div>
                                    <div className="meta-info">
                                        <div className="author">
                                            <div className="avatar">
                                            <Link to={`${config.baseUrl}user-portfolio/` + id}>
                                                {!item.owner_profile || item.owner_profile == null || item.owner_profile == 'null' ?
                                                    <img src="images/default-user-icon.jpg" alt="owner profile" />
                                                    :
                                                    <img src={`${config.imageUrl + item.owner_profile}`} alt="owner profile" />
                                                }
                                              </Link>  
                                            </div>
                                            <div className="info">
                                                <span>Category</span>
                                                <h6> <Link to={`${config.baseUrl}nft-details/` + item.id}>{item.category_name}
                                                </Link> </h6>
                                            </div>
                                        </div>
                                        <div className="price">
                                            <span>Price</span>
                                            <h5>{parseFloat(item.price).toFixed(0)} SIN</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Userportfolio;
