import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Silk from "react-slick";
import config from '../../config';
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));    

const Slider = props => {
    const allData = props.data;    
    const nftDetails = allData[0]
	var settings = {
			centerMode: true,
            autoplay: true,
            arrows: false,
            centerPadding: '0px',
            focusOnSelect: false,
            pauseOnHover: false,
            dots: true,
            slidesToShow: 3,
            infinite: true,
            responsive: [
                {
                    breakpoint: 4100,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '0px',
                        slidesToShow: 3
                    }
                },

                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '0px',
                        slidesToShow: 2
                    }
                }, {
                    breakpoint: 568,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '0px',
                        slidesToShow: 1
                    }
                }
            ]
  };
    return (
       <div className="banner-section">
<div className="container">
  <div className="client-slider"> 
		<Silk {...settings}>
		{ nftDetails.length >0 ?
			nftDetails.map((item) => (
			<div className="slider">               
                <div className="profile images-sec">
                  <img className="ht-auto" src={ item?.image ?  `${config.imageUrl+ item.image}` : `images/no-image.png`} />
                </div>
                <div className="slider-text">
                   <h2>{item.title}</h2><p>{item.description}</p>                   
                </div> 
            </div>           
			))
			: ''
		}           
		</Silk>
	</div>         
</div>
	 
	</div>
    );
}



export default Slider;
