import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import heroSliderData from '../assets/fake-data/data-slider';
import Slider from '../components/slider/Slider';
import LiveAuction from '../components/layouts/LiveAuction';
import WelcomeSection from '../components/layouts/WelcomeSection';
import TodayPicks from '../components/layouts/TodayPicks';
import SafePicks from '../components/layouts/SafePicks';
import LandTopPicks from '../components/layouts/LandTopPicks';
import Content from '../components/layouts/Content';
import Cities from '../components/layouts/Cities';
import Create from '../components/layouts/Create';
import { getHomePageNFTAction, getHomePageSliderNFTAction, getHomePageLandAction, getHomePageContentListAction, getcitieslistAction,getHomePageSafeNFTAction, getcartcountAction, getSettingsAction } from '../Action/user.action';

const Home01 = () => {

    const [NFTList, setNFTDetails] = useState([]);
    const [SafeNFTList, setSafeNFTDetails] = useState([]);
    const [settings, setSettings] = useState([]);
    const [SliderNFTList, setSliderNFTDetails] = useState([]);
    const [LandList, setLandDetails] = useState([]);
    const [ContentList, setContentList] = useState([]);
    const [citylist, setCityList] = useState([]);
    const [cartcount, setcartcount] = useState(0);
    

    useEffect(async () => {
        getNFTListAPI();
        getSafeNFTListAPI();
        getSliderNFTListAPI();
        getLandListAPI();
        getContentListAPI();
        getSettingsAPI();
        getCities();
         if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            } 
     if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    } 
    }, []);

    const getNFTListAPI = async () => {
        let res = await getHomePageNFTAction();
        if (res.success) {
            setNFTDetails(res.data);
        }
    }
    
    const getSettingsAPI = async () => {
        let res = await getSettingsAction();
        if (res.success) {
            setSettings(res.data);
        }
    }
    
    const getSafeNFTListAPI = async () => {
        let res = await getHomePageSafeNFTAction();
        if (res.success) {
            setSafeNFTDetails(res.data);
        }
    }
    const getLandListAPI = async () => {
        let res = await getHomePageLandAction();
        if (res.success) {
            setLandDetails(res.data);
        }
    }
    const getContentListAPI = async () => {
        let res = await getHomePageContentListAction();
        if (res.success) {
            setContentList(res.data);
        }
    }
    const getCities = async () => {
		let res = await getcitieslistAction();
		if (res.success) {    
		  setCityList(res.data);
		}
		
	}
        
    const getSliderNFTListAPI = async () => {
        let res = await getHomePageSliderNFTAction();
        if (res.success) {
            setSliderNFTDetails(res.data);
        }
    } 
    
     const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': 0, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }   

    return (
        <div className='home-1'>
            <Header data={{'cartcount':cartcount}} />
            <Slider data={[SliderNFTList]} />
            <WelcomeSection data={[heroSliderData]} />  
            <TodayPicks data={[NFTList, settings]} />           
             <LandTopPicks data={[LandList,settings]} /> 
             <SafePicks data={[SafeNFTList,settings]} />
             <Content data={ContentList} /> 
             <Cities data={citylist} />                       
            <Footer />
        </div>
    );
}

export default Home01;
