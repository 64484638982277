import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CardModal from './CardModal';
import config from '../../config';

const Content = props => {
    const data = props.data;
	
   return (
		<div className="section-Business">
		<div className="container">
			<div className="Business-heading">
				<h2>Business Licenses NFTs </h2>
			</div>
			<div className="row">
		{
			data.map((item, index) => (
				
				<>
				<div className="col-lg-4 col-md-6">
					<div className="Business-images">
						<Link to={`${config.baseUrl}licenses-city/` + item.id} className="">
							<img src={`${config.imageUrl + item?.image}`} alt="{item.title}"  />
						</Link>
					</div>
					<div className="Business-btn">
					<a href={`${config.baseUrl}licenses-city/` + item.id}>
						<button>{item.title} ({item.licCount})</button>
					</a>	
					</div>
				</div>
				</>
			))
		}
	</div>
	</div>
</div>
	

    );
}



Content.propTypes = {
    data: PropTypes.array.isRequired,
}


export default Content;
