import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import { ContactFormAction, buyCoinsAction, getSinToCoinValueAction,trnasferTokenAPIAction, getcartcountAction, LoginWithTokenAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import Swal from "sweetalert2";

const BuyCoins = () => {	
	const { token } = useParams();
    const [connectWalletAddress, setConnectWalletAddress] = useState('');
    const [cartcount, setcartcount] 		= useState(0);   
    const [spinLoader, setSpinLoader] 		= useState(0); 
    const [isLoading, setisLoading] 		= useState(false);  
    
    useEffect(async () => {
      getUserDetailsAPI();  
    }, []);
    
    const getUserDetailsAPI = async (e) => {       
        setisLoading(true);           
		let res = await LoginWithTokenAction({ 'token': token});
		if (res.success) {
			const expirationDate = new Date();
			expirationDate.setTime(expirationDate.getTime() + 1 * 24 * 60 * 60 * 1000); // 24hr in milliseconds			
			toast.success(res.msg);
			Cookies.set('loginSuccessSinverseUserpanel', JSON.stringify(res.data), { expires: expirationDate });
			Cookies.set('token', JSON.stringify(res.Token),{ expires: expirationDate });
			setTimeout(() => {
				window.location.href = `${config.baseUrl}gold-coins`;
			}, 1000);
		} else {			
			toast.error(res.msg);
			setTimeout(() => {
				window.location.href = `${config.baseUrl}`;
			}, 2000);
		}
   }
    
   
    
    return (
       <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
            <div class="about-section">
			<div class="container">
				<div class="about-us-heading">
					<h1>Buy Gold Coins</h1>   
			</div>
			</div>
			</div>
           
            <section className="tf-login tf-section">
                <div className="cart-container">
                    <div className="row">                    
                     <div class="col-md-12">
							<div class="login button">
				 {isLoading ?
					 <>
						<p className="processing-icon"><i className="fa fa-spinner fa-spin"></i></p>
						<p className="processing">Processing... &nbsp;</p>
					</>
					:
					''
				}
								
							</div>
						</div>                        
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default BuyCoins;
