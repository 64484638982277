import React, { useState, useEffect } from 'react';
import { Link,useParams } from 'react-router-dom';
import { getNFTMetaDataDetailsAction } from '../Action/user.action';
const NFTmetadata = () => {
	const { id } = useParams();
	const [jsonMetadata, setjsonMetadata] = useState([]);
    useEffect(async () => {
      setNFTMetaDataDetailsAPI();      

    }, []);
    
    const setNFTMetaDataDetailsAPI = async () => {
        let res = await getNFTMetaDataDetailsAction({ 'id': id });
        if (res.success) {
           setjsonMetadata(res.data);
        }
    }
    return ( JSON.stringify(jsonMetadata, null, 2)
            );
   
}

export default NFTmetadata;

