import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CardModal from './CardModal';
import config from '../../config';

const Content = props => {
    const data = props.data;
    const [visible, setVisible] = useState(6);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 3);
    }
   return (
	<div className="section-net-Experience">
		<div className="container">
			<div className="Experience-main-heading">
				<h2>Fine-tune your NFT Experience</h2>
			</div>
			<div className="row">
			{
				data.slice(0, visible).map((item, index) => (
				
		<>
			<div key={index} className="col-md-3">
				<div className="nte-main">
					<div className="images-png">
						<img src="images/9.png" />
						<img className="Vector-img" src={config.imageUrl + item.icon} />
					</div>
					<div className="net-heading">
						<h2>{item.title}</h2>
						<p>{item.description}</p>
					</div>
				</div>
			</div>
		</>
		))
	}
	
	</div>
	</div>
</div>	

    );
}



Content.propTypes = {
    data: PropTypes.array.isRequired,
}


export default Content;
