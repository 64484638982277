import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link, useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReactTooltip from 'react-tooltip';
import { getContractNftDetailsAction,putOnSaleAction, nftLikeDislikeAction, getlicensemetadataAction, getNftHistoryAction, buyItemAction, bidPlaceAPIAction, getlandmetadataAction, getlandroadsAction, getcartcountAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import toast, { Toaster } from 'react-hot-toast';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));


const NFTDetail = () => {
    const { contract , id } = useParams();
    const [cartcount, setcartcount] = useState(0);
    const [NFTDetail, setNFTDetails] = useState([]);
    const [NFTHistory, setNFTHistory] = useState([]);
    const [NFTMetadata, setNFTMetaData] = useState([]);
    const [connectWalletAddress, setConnectWalletAddress] = useState('');
    const [spinLoader, setSpinLoader] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [modalopen, setModalopen] = useState(0);
    const [Biderror, setBiderror] = useState(0);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [validatioError, setvalidatioError] = useState({});
    const [isPutonsale, setisPutonsale] = useState(0);
    const [isLand, setisLand] = useState(1);
    const [isLoader, setisLoader] = useState(1);
      const [Landroads, setLandRoads] = useState([]);
    const [form, setForm] = useState({
        'bid_price': ''
    })
     const [itemDetails, setItemDetails] = useState({
        title: '',
        token_id: '',
        image: '',
        description: '',
        category_id: '0',
        royalty_percentage: '0',
        price: '0',
        sell_type: "1",
        start_date: null,
        expiry_date: null,
        contractAddress: '',
        wallet_address: '',
        tokenUri : ''
    });

    useEffect(async () => {
       // getNFTDetailsAPI();
         getLandRoadsAPI();

        setTimeout(() => {
            if (window.ethereum) {
                const { ethereum } = window;
                setConnectWalletAddress(ethereum.selectedAddress);
                 getNFTDetailsAPI(ethereum.selectedAddress);
            }
            else
            {
             getNFTDetailsAPI('');
            }
        }, 200);
        
         if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            }

    }, []);
    
    const getLandRoadsAPI = async () => {
        let res = await getlandroadsAction();
        if (res.success) {
           setLandRoads(res.data);            
        }
        else
        {
			setLandRoads([])
		}
    }
    
     const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }

    const getNFTDetailsAPI = async (walletAddress) => {
        let res = await getContractNftDetailsAction({ 'id': id, 'contract': contract,'walletAddress':walletAddress });
        if (res.success) {
            setNFTDetails(res.data);
			if(res.data.contract_type==config.CONTRACT_TYPE_LAND)
			{
				setisLand(1)
				let jsonData = await getlandmetadataAction({ 'token_id': res.data.token_id, 'contractAddress': contract,});
				setNFTMetaData(jsonData.data.attributes);
				 setisLoader(0)
			}
			else if(res.data.contract_type==config.CONTRACT_TYPE_LICENSE)
			{
				setisLand(0)
				let jsonData = await getlicensemetadataAction({ 'token_id': res.data.token_id, 'contractAddress': contract,});
				
				 if (jsonData.success) {
					setNFTMetaData(jsonData.data.attributes);
						
						setisLoader(0)
					}
				
			}
        }
    }

    const getNftHistoryAPI = async () => {
        let res = await getNftHistoryAction({ 'id': id });
        if (res.success) {
            setNFTHistory(res.data);
        }
    }

    const nftLike = async (type, item_id) => {
        let res = await nftLikeDislikeAction({ 'type': type, 'item_id': item_id });
        if (res.success) {
            getNFTDetailsAPI();
        }
    }

    const connectMetasmask = async () => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setConnectWalletAddress(accounts);
        }
        else {
            toast.error(`Please use dApp browser to connect wallet!`);
        }
    }
    
     function validate() {
        let titleError = "";
        let descriptionError = "";
        let categoryError = "";
        let priceError = "";
        let startDateError = "";
        let expiryDateError = "";
        if (itemDetails.title === '') {
            titleError = "Title field is required."
        }
        if (itemDetails.description === '') {
            descriptionError = "Description field is required."
        }
        if (itemDetails.category_id == 0) {
            categoryError = "Category field is required."
        }
        if (itemDetails.price == 0) {
            priceError = "Price field is required."
        }
        if (itemDetails.sell_type == 2) {
            if (itemDetails.start_date === '' || itemDetails.start_date === null) {
                startDateError = "Start date required."
            }
            if (itemDetails.expiry_date === '' || itemDetails.expiry_date === null) {
                expiryDateError = "Expiry date required."
            }
        }
        if (titleError || descriptionError || categoryError || priceError || startDateError || expiryDateError) {
            setvalidatioError({
                titleError, descriptionError, categoryError, priceError, startDateError, expiryDateError
            })
            return false
        } else {
            return true
        }
    }


    const purchaseItem = async () => {
        if (!loginData?.id) {
            toast.error('Please login first!!');
        } else {
            let tokenId = NFTDetail.token_id;
            let tokenPrice = NFTDetail.price;
            let contractAddress = NFTDetail.contractAddress;
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                let web3 = new Web3(window.ethereum);
                let currentNetwork = web3.currentProvider.chainId;
                let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                    toast.error('Please select BNB network!');
                    return false;
                }
                try {

                    // Approve token
                    let tx_builderForToken = '';
                    let from_address = accounts[0];
                    let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
                    let tokenContractAddress = `${config.tokenContract}`;

                    const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
                    let allowance = await tokenContract.methods.allowance(from_address, config.mainMarketplaceContract).call();
                    if (parseInt(allowance) < parseInt(tokenValue)) {
                        tx_builderForToken = await tokenContract.methods.approve(config.mainMarketplaceContract.toString(), tokenValue.toString());
                        
                        setSpinLoader(1);
                        setDialogOpen(true);

                        let encodedTxForToken = tx_builderForToken.encodeABI();
                        let gasPriceForToken = await web3.eth.getGasPrice();

                        let gasLimitForToken = await web3.eth.estimateGas({
                            gasPrice: web3.utils.toHex(gasPriceForToken),
                            to: tokenContractAddress,
                            from: from_address,
                            chainId: chainId,
                            data: encodedTxForToken
                        });

                        await web3.eth.sendTransaction({
                            gasPrice: web3.utils.toHex(gasPriceForToken),
                            gas: web3.utils.toHex(gasLimitForToken),
                            to: tokenContractAddress,
                            from: from_address,
                            chainId: chainId,
                            data: encodedTxForToken
                        });
                    }

                    let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
                    var currentBal = parseInt(getTokenBalance) / 10 ** 18;
                    if (currentBal < NFTDetail.price) {
                        setSpinLoader(0);
                        setDialogOpen(false);                        
                        toast.error(`Insufficient fund for transfer`);
                        return false;
                    }
                    await trnasferNFT(contractAddress, tokenId, tokenValue);
                } catch (error) {
                    console.log(error);
                    setSpinLoader(0);
                    setDialogOpen(false);
                    toast.error('Something went wrong please try again.');
                    return false;
                }
            } else {
                toast.error('Please Connect to MetaMask.');
                return false;
            }
        }
    }

    const trnasferNFT = async (nftcontractAddress, tokenId, tokenPrice) => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let web3 = new Web3(window.ethereum);
            try {
                let contractAddress = `${config.mainMarketplaceContract}`;
                let from_address = accounts[0];
                const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);
                let tx_builder = '';
                let itemPrice = 0;
                itemPrice = tokenPrice / 10 ** 18;
                console.log(tokenId.toString(), tokenPrice.toString());

                console.log(nftcontractAddress.toString(), tokenId.toString(), tokenPrice.toString());

                tx_builder = await contract.methods.buy(nftcontractAddress.toString(), tokenId.toString(), tokenPrice.toString());
                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                if (txData.transactionHash) {
                    var paymentArr = {
                        email: loginData?.email,
                        user_id: loginData?.id,
                        txHash: txData.transactionHash,
                        amount: itemPrice,
                        to_address: from_address,
                        item_id: NFTDetail?.id
                    }
                    console.log(paymentArr);
                    buyItemAPI(paymentArr)
                } else {
                    toast.error('Something went wrong please try again3.');
                    setSpinLoader(0);
                    setDialogOpen(false);
                    return false;
                }

            } catch (err) {
                console.log(err);
                if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                    } else {
                        toast.error(err.message);
                    }
                }
                setSpinLoader(0);
                setDialogOpen(false);
                return false;
            }

        } else {
            toast.error('Please Connect to MetaMask.');
            setSpinLoader(0);
            setDialogOpen(false);
            return false;
        }
    }

    const buyItemAPI = async (data) => {
        let res = await buyItemAction(data);
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            toast.error(`Something went wrong! Please try again.`);
        }
    }

    const bidPlaced = async () => {
        if (!loginData?.id) {
            toast.error('Please login first!!');
        } else {
            let tokenId = NFTDetail.token_id;
            let tokenPrice = parseInt(form?.bid_price);
            let contractAddress = NFTDetail.contractAddress;

            if ((parseInt(NFTDetail?.max_bid)) >= tokenPrice) {
                setBiderror(1);
                setErrorMessage('Bid amount should be higher than max bid amount!!');
                return false;
            }

            setBiderror(0);
            setErrorMessage('');

            if (window.ethereum) {
                let web3 = new Web3(window.ethereum);
                let currentNetwork = web3.currentProvider.chainId;

                let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                    toast.error('Please select BNB network!');
                    return false;
                }
                
                try {
                    // Approve token
                    let tx_builderForToken = '';
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    let from_address = accounts[0];
                    let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
                    let tokenContractAddress = `${config.tokenContract}`;

                    const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
                    let allowance = await tokenContract.methods.allowance(from_address, config.mainMarketplaceContract).call();
                    
                    setSpinLoader(1);
                    setDialogOpen(true);
                    setModalopen(0);

                    if (parseInt(allowance) < parseInt(tokenValue)) {
                        tx_builderForToken = await tokenContract.methods.approve(config.mainMarketplaceContract.toString(), tokenValue.toString());
                        let encodedTxForToken = tx_builderForToken.encodeABI();
                        let gasPriceForToken = await web3.eth.getGasPrice();

                        let gasLimitForToken = await web3.eth.estimateGas({
                            gasPrice: web3.utils.toHex(gasPriceForToken),
                            to: tokenContractAddress,
                            from: from_address,
                            chainId: chainId,
                            data: encodedTxForToken
                        });

                        await web3.eth.sendTransaction({
                            gasPrice: web3.utils.toHex(gasPriceForToken),
                            gas: web3.utils.toHex(gasLimitForToken),
                            to: tokenContractAddress,
                            from: from_address,
                            chainId: chainId,
                            data: encodedTxForToken
                        });
                    }

                    tokenPrice = parseInt((parseFloat(tokenPrice)) * 10 ** 18);
                    await placeBidNow(contractAddress, tokenId, tokenPrice);
                } catch (error) {
                    toast.error('Something went wrong please try again.');
                    setSpinLoader(0);
                    setDialogOpen(false);
                    setModalopen(1);
                    return false;
                }
            } else {
                toast.error('Please Connect to MetaMask.');
                setSpinLoader(0);
                setDialogOpen(false);
                setModalopen(1);
                return false;
            }
        }
    }

    const placeBidNow = async (nftContractAddress, tokenId, tokenPrice) => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let web3 = new Web3(window.ethereum);
            var chainId = config.chainId;
            try {

                let contractAddress = `${config.mainMarketplaceContract}`
                let from_address = accounts[0];

                const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);
                let tx_builder = '';
                tx_builder = await contract.methods.placeBid(nftContractAddress.toString(), tokenId.toString(), tokenPrice.toString());

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                if (txData.transactionHash) {
                    var paymentArr = {
                        "email": loginData?.email,
                        "bid_price": parseFloat(form?.bid_price).toFixed(6),
                        "user_id": loginData?.id,
                        "item_id": NFTDetail?.id,
                        "owner_id": NFTDetail?.owner_id,
                        "payable_address": from_address,
                        "txhash": txData.transactionHash,
                        "contractAddress" : nftContractAddress
                    }
                    bidPlaceAPI(paymentArr)
                } else {
                    toast.error('Something went wrong please try again3.');
                    setSpinLoader(0);
                    setDialogOpen(false);
                    setModalopen(1);
                    return false;
                }

            } catch (err) {
                if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                    } else {
                        toast.error(err.message);
                    }
                }
                setSpinLoader(0);
                setDialogOpen(false);
                setModalopen(1);
                return false;
            }
        } else {
            toast.error('Please Connect to MetaMask.');
            setSpinLoader(0);
            setDialogOpen(false);
            setModalopen(1);
            return false;
        }
    }

    const bidPlaceAPI = async (paymentArr) => {
        let res = await bidPlaceAPIAction(paymentArr);
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            toast.error(`Something went wrong! Please try again.`);
        }
    }

    const bidItem = async () => {
        if (!loginData?.id) {
            toast.error('Please login first!!');
        }
        setModalopen(1);
    }

    const closebutton = async () => {
        setModalopen(0);
    }

    const inputHandler = (e) => {
         const { name, value, id } = e.target
        setItemDetails({ ...itemDetails, [name]: value })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }
     const handleChangeStartDate = e => {
        let startDate = formatDate(e);
        setItemDetails({ ...itemDetails, ['start_date']: startDate });
        setvalidatioError({ ...validatioError, ['startDateError']: '' });
    }

    const handleChangeExpiryDate = e => {
        let expiryDate = formatDate(e);
        setItemDetails({ ...itemDetails, ['expiry_date']: expiryDate });
        setvalidatioError({ ...validatioError, ['expiryDateError']: '' });
    }
    
    function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    
    const putOnSaleModelAPI = async (item) => {
        setisPutonsale(1);
        setItemDetails({
            title: item.name ? item.name : '',
            image: item.token_uri,            
            description: item.metadata?.description ? item.metadata?.description : '',
            token_id: item.token_id,
            contractAddress: item.token_address,
            category_id: '0',
            royalty_percentage: '0',
            price: '0',
            sell_type: "1",
            start_date: null,
            expiry_date: null,
            wallet_address: '',
        });
    }

    const closeModel = async () => {
        setisPutonsale(0);
    }
     const ShowPlotDetails = (cell_number) => {
		let cell			= cell_number-1; 
		var Owner_Plots		= [];
		console.log(cell_number)
		const size 			= 274;
		const hsize 		= 149;		
		const canvas 		= document.getElementById('myCanvas');
		canvas.width 		= 1249;
		canvas.height 		= 688;
		const ctx 			= canvas.getContext("2d");
		const tileWSize 	= canvas.width / size;
		const tileHSize 	= canvas.height / hsize;
		let lastTile 		= -1;
		for (let y = 0; y < canvas.height / tileHSize; y++) {
			for (let x = 0; x < canvas.width / tileWSize; x++) {
			const parity = (x + y) % 2;
			const tileNum = x + canvas.width / tileWSize * y;
			const xx = x * tileWSize;
			const yy = y * tileHSize; 
			    
			if(parseInt(tileNum)	=== parseInt(cell))  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 1;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#dc3545";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);				
			}
			else if(Landroads.indexOf(tileNum) !== -1)  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#000000";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);
			} 				
			else
			{
				ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
			}
					
			  
			}
		  }

			ctx.lineWidth = .1;
			ctx.stroke(); 
			
				 
        setModalopen(1);
        
    }
    
    return (
        <div className='item-details'>
            <Toaster />
            <Header data={{'cartcount':cartcount}}  />
             <div className="market-place-banner">
			  <h1>NFT Details</h1>
			</div>
            <div className="market-place pro-view-page">
                <div className="container">
                <div className="row">                
                <div className="col-12 col-md-12 col-lg-12">
                    <div className="row">
                    <div className="col-12 back-btn">
                    <Link to={`${config.baseUrl}collectedNFTs`} className=""> <button className="custom-btn btn-bg-blue"><i className="fa-solid fa-chevron-left"></i>Back</button></Link>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className="pro-view">
                        
                        <div className="pro-view-img">
                         {isLoader == 0 ? 
							isLand==1 ?						
							<img src="images/lant-plot.png" />
                        :
                        NFTDetail.metadata.type=='Exclusive' ?
							<>
							<img src={NFTDetail.metadata.district.toLowerCase().includes('sin') ? 'licenses/sin-city-centre-exclusive.png' : NFTDetail.metadata.district.toLowerCase().includes('china') ? 'licenses/china-town-exclusive.png' : NFTDetail.metadata.district.toLowerCase().includes('strip') ? 'licenses/the-strip-exclusive.png' :  NFTDetail.metadata.district.toLowerCase().includes('hills') ? 'licenses/the-hills-exclusive.png' : NFTDetail.metadata.district.toLowerCase().includes('red') ? 'licenses/red-light-district-exclusive.png' : NFTDetail.metadata.district.toLowerCase().includes('silicon') ? 'licenses/silicon-valley-exclusive.png' : NFTDetail.metadata.district.toLowerCase().includes('favelas') ? 'licenses/favelas-exclusive.png' : NFTDetail.metadata.district.toLowerCase().includes('fashion') ? 'licenses/fashion-district-exclusive.png' : NFTDetail.metadata.district.toLowerCase().includes('cartel') ? 'licenses/cartel-exclusive.png' : NFTDetail.metadata.district.toLowerCase().includes('harbour') ? 'licenses/harbour-exclusive.png' : 'images/no-image.png'} alt="NFT Image" data-src={NFTDetail.metadata.district.toLowerCase().includes('strip') ? 'licenses/the-strip-exclusive.png':NFTDetail.metadata.district} alt="NFT Image1" data-src={`${NFTDetail.metadata.district.toLowerCase()}`} />
							</>
							:
							<>
							<img src={NFTDetail.metadata.district.toLowerCase().includes('sin') ? 'licenses/sin-city-centre-premium.png' : NFTDetail.metadata.district.toLowerCase().includes('china') ? 'licenses/china-town-premium.png' : NFTDetail.metadata.district.toLowerCase().includes('strip') ? 'licenses/the-strip-premium.png' :  NFTDetail.metadata.district.toLowerCase().includes('hills') ? 'licenses/the-hills-premium.png' : NFTDetail.metadata.district.toLowerCase().includes('red') ? 'licenses/red-light-district-premium.png' : NFTDetail.metadata.district.toLowerCase().includes('silicon') ? 'licenses/silicon-valley-premium.png' : NFTDetail.metadata.district.toLowerCase().includes('favelas') ? 'licenses/favelas-premium.png' : NFTDetail.metadata.district.toLowerCase().includes('fashion') ? 'licenses/fashion-district-premium.png' : NFTDetail.metadata.district.toLowerCase().includes('cartel') ? 'licenses/cartel-premium.png' : NFTDetail.metadata.district.toLowerCase().includes('harbour') || NFTDetail.metadata.district.toLowerCase().includes('harbiur') ? 'licenses/harbour-premium.png' : 'images/no-image.png'} alt="NFT Image2" data-src={NFTDetail.metadata.district.toLowerCase().includes('strip') ? 'licenses/the-strip-premium.png':NFTDetail.metadata.district} />
							</>
						 :
                           (
							 <div className="loaderDiv">
							  <img className='loaderImg' src="images/loader.gif" height={50} width={50} />
							  </div>
							  )
                        }
                        </div>
                        </div>
                         {isLoader == 0 ? NFTMetadata.length == 0 ? '' :
								NFTMetadata.map((item, index) => (
									item.trait_type=='Cell Number'?
									<>									
									<p className="map-text"><a href="javascript:;" onClick={() => { ShowPlotDetails(item.value) }}>View on map</a></p>
									</>
									:''
								))
								 :
                            ''
                        }
                         
                    </div>
                    <div className="col-12 col-md-5">
                        <div className="pro-detail-box">
                        <h2 className="title">{NFTDetail?.title}</h2>
                        
                        <div className="btn-group">
                            <button className="custom-btn btn-bg-blue active">Info</button>                            
                        </div>
                        <div className="pro-detail-inner">                                                      
							<div className="pro-detail">							
								<p>Title:</p>
								<p>{NFTDetail?.name}</p>
							</div>
							<div className="pro-detail">
								<p>Owned By:</p>
								<p>{NFTDetail?.owner_of ? 
								NFTDetail?.owner_of.toString().substring(0, 5) + '...' + NFTDetail?.owner_of.toString().substring(NFTDetail?.owner_of.length - 5) :''}
								</p>
							</div>
							<div className="pro-detail">
								<p>Contract Address:</p>
								<p>{NFTDetail?.token_address ? 
								NFTDetail?.token_address.toString().substring(0, 5) + '...' + NFTDetail?.token_address.toString().substring(NFTDetail?.token_address.length - 5) :''}</p>
							</div>
							<div className="pro-detail">
								<p>Token ID :</p>
								<p>#{NFTDetail?.token_id}</p>
							</div>
							{isLoader == 0 ? NFTMetadata.length == 0 ? '' :
								NFTMetadata.map((item, index) => (
									<>
									<div className="pro-detail">
										<p>{item.trait_type}        :</p>
										<p>{item.value}</p>
									</div>
									</>
								))
									 :
                            (
							 <div className="loaderDiv">
							  <img className='loaderImg' src="images/loader.gif" height={50} width={50} />
							  </div>
							  )
                        }
							
                        </div>
                        
                        </div>
                    </div>


                </div>
                </div>
                </div>
                </div>
                </div>	
                        

             <div id="myModal" className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered gridmodal">
                    <div className="modal-content">
						<button type="button" onClick={closebutton} className="close btnClose" data-dismiss="modal">&times;</button>                                      
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">                                        
                                        <div class="graph text-center">
											<canvas id="myCanvas"></canvas>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
    );
}

export default NFTDetail;
