import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CardModal from './CardModal';
import config from '../../config';
import DamageRating from './DamageRating';

const LandTopPicks = props => {
    const data = props.data[0];	
    const settings = props.data[1];	
    const [visible, setVisible] = useState(6);
    useEffect(() => {
		const lvisible = localStorage.getItem('lvisible');		
		if (lvisible) {
		  setVisible(lvisible);
		}
	  }, []);    
    
    const showMoreItems = () => {
        setVisible((prevValue) => parseInt(prevValue) + 3);       
        localStorage.setItem('lvisible', (parseInt(visible) + 3));
    }
   return (
     <>
      {data.length > 0 ? (
        <div className="tab-section padd-bottom-0 padd-top-0">
          <div className="container">
            <div className="tab-heading">
              <h2>Land Reselling</h2>
            </div>
            <div className="row">
              {data.slice(0, visible).map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6">
                  <div className="tab-section-main">
                    <div className="tab section">
                      <div className="profile-images"></div>
                      <div className="Andrew-text">
                        <p>
                          {item.owner_address
                            .toString()
                            .substring(0, 4) +
                            '...' +
                            item.owner_address.toString().substring(item.owner_address.length - 4)}
                        </p>
                      </div>
                      <div className="height-icon">
                        <i className="fa-regular fa-heart"></i>
                        <p>{item.itemLike}</p>
                      </div>
                    </div>
                    <div className="images-sec">
                     {item.token_id ? (
						  <>
							  <Link to={`${config.baseUrl}nft-details/` + item.id}>
								<img src="images/lant-plot.png" alt="Land Plot" />
							  </Link>
						  </>
                      )
                      :
                      (
						  <>
							  <Link to={`${config.baseUrl}land-group-details/` + item.id}>
								<img src="images/lant-plot.png" alt="Land Plot" />
							  </Link>
						  </>
                      )
				  }
                      
                    </div>
                    <div className="section-map">
                      <h3> 
                      {item.token_id ? (
						  <>
							{item.name}
							<br />
							<span className="fnt-12">{item.city_name}</span>
						  </>
						) : (
						<>
						  {item.title}
						  <br />
							<span className="fnt-12">&nbsp;</span>
						 </>	
						)}
					</h3>
                    </div>
                    { settings.is_land_on_sale ?
					<>
                    <div className="price-section">
                      <div className="price">
                        <p>Price</p>
                        <h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
                      </div>
                      {item.token_id ? (
						  <>
							 <div className="price">
								<p>Token ID</p>
								<h3>#{item.token_id}</h3>
							  </div>
							  <Link to={`${config.baseUrl}nft-details/` + item.id}>
								<button className="tab-btn">Buy</button>
							  </Link>
						  </>
						) : (
						<>
						 <Link to={`${config.baseUrl}land-group-details/` + item.id}>
								<button className="tab-btn">Buy</button>
							  </Link>
						
						</>
						)
						}
                    </div>
                    </>
                    :
                    <>
                     <div className="price-section">                     
                      {item.token_id ? (
						  <>
							 <div className="price">
								<p>Token ID</p>
								<h3>#{item.token_id}</h3>
							  </div>
							  <Link to={`${config.baseUrl}nft-details/` + item.id}>
								<button className="tab-btn">Coming Soon</button>
							  </Link>
						  </>
						) : (
						<>
						 <Link to={`${config.baseUrl}land-group-details/` + item.id}>
								<button className="tab-btn">Coming Soon</button>
							  </Link>
						
						</>
						)
						}
						</div>
                    </>
                    }
                    <div className="View-History">
                    {item.token_id ? (
						  <>
							<Link to={`${config.baseUrl}nft-details/` + item.id + `#history`}>
							<i className="fa-solid fa-clock-rotate-left"></i>View History
						  </Link>
						  </>
						) : (
						<>
						 <Link to={`${config.baseUrl}land-group-details/` + item.id + `#history`}>
							<i className="fa-solid fa-clock-rotate-left"></i>View History
						  </Link>
						 </>	
						)}
                      
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Load More button */}
            {visible < data.length && (
              <div className="lorn-more col">
                <button className="tab-last-btn" onClick={showMoreItems}>
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        // Show an empty div if there is no data
        <div></div>
      )}
    </>
   
   );
}



LandTopPicks.propTypes = {
    data: PropTypes.array.isRequired,
}


export default LandTopPicks;
