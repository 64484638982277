import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import { getMysterycrateListAction, buyCoinsAction, getSinToCoinValueAction,MysteryCrateTrnasferAction, getcartcountAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import Swal from "sweetalert2";
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));

const BuyCoins = () => {

    const [connectWalletAddress, setConnectWalletAddress] = useState('');
    const [MysterycrateList, setMysterycrateList] = useState({});
    const [cartcount, setcartcount] = useState(0);
    const [sinToCoinValue, setSinToCoinValue] = useState('');
    const [getBalance, setBalance] = useState(0);//setTotalCount
    const [spinLoader, setSpinLoader] = useState(0);
    const [itemspinLoader, setitemspinLoader] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [modalopen, setModalopen] = useState(0);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [buy100, setbuy100] = useState(0); 
    const [rewardImg, setrewardImg] = useState(''); 
    const [rewardTitle, setrewardTitle] = useState('');      
    const [spinLoaderId, setspinLoaderId] = useState(0);      
    
    useEffect(async () => {
     // getSinToCoinValueAPI();  
     setSpinLoader(1);    
      getMysterycrateListAPI();      
        setTimeout(() => {
            if (window.ethereum) {
                const { ethereum } = window;
                setConnectWalletAddress(ethereum.selectedAddress);
                getcartcountAPI(ethereum.selectedAddress);
            }
        }, 200);
        
      //  getAccount()

    }, []);
   
  const getMysterycrateListAPI = async () => {		
        let res = await getMysterycrateListAction();
        if (res.success) {
            setMysterycrateList(res.data);
            setSpinLoader(0);
        }
    }
   
  const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
    
  const buyNow = async (item) => {
    if (!loginData?.id) {       
        Swal.fire({  text: 'Please login first!!',
				  title: "Alert",
				  confirmButtonText: 'Okay',
				  confirmButtonColor: '#e7275a',
				  background: '#343444',
				  color: '#FFF',
				  allowOutsideClick:false
				});
    } else {  
                      
            setSpinLoader(1)   
            setspinLoaderId(item.id);         			
			 const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
             let web3 = new Web3(window.ethereum);
             let currentNetwork = web3.currentProvider.chainId;
			 let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                    Swal.fire({  text: 'Please select BNB network!',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						}); 
						setSpinLoader(0);	
				setspinLoaderId(0);	                 					 
                    return false;
                }
                try {
                 Swal.fire({
						title : "Processing...",
						text: 'Transaction is under process, please wait for confirmation!',
						allowEscapeKey: false,
						allowOutsideClick: false,
						showConfirmButton:false,
						background: '#343444',
						color: '#FFF'
					});
					
					let tx_builderForToken = '';
					let from_address = accounts[0];	
					let itemId	= item.id;								
					let tokenPrice = item.price;
					
					//let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
					let tokenValue = web3.utils.toWei(tokenPrice.toString(), 'ether')
					let tokenContractAddress = `${config.tokenContract}`;
					
					
					const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
					let allowance = await tokenContract.methods.allowance(from_address, config.mainMarketplaceContract).call();
					
					if (parseInt(allowance) < parseInt(tokenValue)) {
						console.log(tokenPrice)
						tx_builderForToken = await tokenContract.methods.approve(config.mainMarketplaceContract.toString(), tokenValue.toString());
						console.log("test")
						setSpinLoader(1);
						setDialogOpen(true);

						let encodedTxForToken = tx_builderForToken.encodeABI();
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: tokenContractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});

						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: tokenContractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
					}

			let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
			var currentBal = parseInt(getTokenBalance) / 100000000;
			console.log("currentBal")
			console.log(currentBal)
			if (currentBal < tokenPrice) {
				setSpinLoader(0);
				setspinLoaderId(0);     
				setDialogOpen(false);
				Swal.fire({  text: 'Insufficient fund for transfer',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
				setbuy100(0)				
				return false;
			}
			await trnasferNFT(itemId, tokenValue);
			}
			 catch (error) {
                    setSpinLoader(0);
                    setspinLoaderId(0);  
                    setDialogOpen(false);
                    setbuy100(0)					
                    Swal.fire({  text: 'Something went wrong! Please try again.',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
					return false;
                }
        }
  }
  
  const trnasferNFT = async (itemId, tokenPrice) => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let web3 = new Web3(window.ethereum);
            try {
                let contractAddress = `${config.mainMarketplaceContract}`;
                let from_address = accounts[0];
                const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);
                let tx_builder = '';
                let itemPrice = 0;
                itemPrice = tokenPrice / 10 ** 18;
                console.log(tokenPrice,'tp')
                console.log(itemPrice,'ip')
                tx_builder = await contract.methods.sendTokens(tokenPrice.toString());
                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                if (txData.transactionHash) {
                    var paymentArr = {
                        email: loginData?.email,
                        user_id: loginData?.id,
                        txHash: txData.transactionHash,
                        amount: itemPrice,
                        address: from_address,
                        item_id: itemId
                    }
                    console.log(paymentArr);
                    trnasferTokenAPI(paymentArr)
                } else {
                   Swal.fire({  text: 'Something went wrong please try again.',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
                    setSpinLoader(0);
                    setspinLoaderId(0);  
                    setbuy100(0)					
                    setDialogOpen(false);
                    return false;
                }

            } catch (err) {
                console.log(err);    
                setSpinLoader(0);	
				setspinLoaderId(0);	            
                if (err.toString().indexOf('insufficient funds') > -1) {               
                   Swal.fire({  text: 'Transaction reverted : Insufficient funds for transfer',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});                 
                }
                else if (err.toString().indexOf('Max NFTs per address exceeded') > -1) {
				Swal.fire({  text: 'Transaction reverted : Max NFTs per address exceeded',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});                  
			  }
			  else {
                    if (err.toString().split('execution reverted:')[1]) {
                       Swal.fire({  text: 'Transaction reverted : Something went wrong! Please try again.',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});  
                    } 
                    else {
						if (err.message.toString().search(/supplied gas/) > -1 || err.message.toString().search(/exceeds allowance/) > -1) {
						Swal.fire({  text: 'Transaction reverted : insufficient funds for transaction fee',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						}); 				
						} else {
							setSpinLoader(0);	
				setspinLoaderId(0);	
							Swal.fire({  text: 'Something went wrong! Please try again.',
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
                        }
                    }
                }
              
                setSpinLoader(0);
                setspinLoaderId(0);  
                setbuy100(0);				
                setDialogOpen(false);
                return false;
            }

        } else {
           Swal.fire({  text: 'Please Connect to MetaMask.',
					  title: "Alert",
					  confirmButtonText: 'Okay',
					  confirmButtonColor: '#e7275a',
					  background: '#343444',
					  color: '#FFF',
					  allowOutsideClick:false
					});
            setSpinLoader(0);
            setspinLoaderId(0);  
            setbuy100(0);			
            setDialogOpen(false);
            return false;
        }
    }
	
  const trnasferTokenAPI = async (data) => {
        let res = await MysteryCrateTrnasferAction(data);
        if (res.success) {			
			let resData =res.data;
			if(res.is_goldcoins==1)
			{				
				loginData.credits = resData.total_gold_coins;
				Cookies.set('loginSuccessSinverseUserpanel', JSON.stringify(loginData));
			}			
			setrewardImg(resData.item_icon); 
			setrewardTitle(resData.shop_item);	  
			setModalopen(1);
			Swal.close();
			setSpinLoader(0);
			setspinLoaderId(0);  
            setbuy100(0);			
            setDialogOpen(false);
        } else {
				setbuy100(0)	
				setSpinLoader(0);	
				setspinLoaderId(0);			
				Swal.fire({  text: 'Something went wrong! Please try again.',
					  title: "Alert",
					  confirmButtonText: 'Okay',
					  confirmButtonColor: '#e7275a',
					  background: '#343444',
					  color: '#FFF',
					  allowOutsideClick:false
					});
        }
    }
     
  const getAccount = async () => {
		const web3 = new Web3(window.ethereum);
		let currentNetwork = web3.currentProvider.chainId
		let chainId = config.chainId;		
		if (currentNetwork !== chainId) {
			Swal.fire({  text: 'Please select BNB network!',
					  title: "Alert",
					  confirmButtonText: 'Okay',
					  confirmButtonColor: '#e7275a',
					  background: '#343444',
					  color: '#FFF',
					  allowOutsideClick:false
					});
			return false;
		}
		const useraccount = await web3.eth.getAccounts();
		const account = useraccount[0];
		if (account) 
		{    
			let tokenContractMethod = new web3.eth.Contract(config.abiToken, config.tokenContract);
			let balance = await tokenContractMethod.methods.balanceOf(account).call();

			setBalance(balance / 10 ** 18)     
     
		}
  }
 const closebutton = async () => {
        setModalopen(0);
        Swal.close();
    }

    

   
    
    return (
       <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
            <div class="about-section">
			<div class="container">
				<div class="about-us-heading">
					<h1>Buy Gold Coins</h1>   
			</div>
			</div>
			</div>
           
            <section className="tf-login tf-section card-section">
                <div className="cart-container">
                   <div className="row"> 
						{ itemspinLoader === 0 && MysterycrateList.length > 0 ? (
							MysterycrateList.map((item, index) => (
								<div key={index} className="col-lg-3 col-md-6 col-sm-6">
									<div className="card-box">
										<div className="card-profile">
											<img src={item.image} alt={item.title} />
										</div>
										<div className="card-text">
											<h5>{item.title}</h5>
											<p>For {item.price} Sin Tokens</p>
											<button
												disabled={spinLoader === 1}
												onClick={() => buyNow(item)}
												className="custom-btn"
											>
												{spinLoader === 1 && spinLoaderId==item.id  ? 'Processing...' : 'Buy Now'}
											</button>
										</div>
									</div>
								</div>
							))
						) : (
							<div>
								<img src="images/loader.gif" height={50} width={50} alt="Loading..." />
							</div>
						)}
					</div>

                </div>
            </section>
             <div id="congratulation" className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgb(255 255 255 / 61%)', display: modalopen == '0' ? 'none' : 'block' }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                     <div className="modal-header">
						<h5 className="modal-title" id="exampleModalLongTitle">Congratulations</h5>
						<button type="button" onClick={closebutton} className="close btnClose" data-dismiss="modal" aria-label="Close">
						  <span aria-hidden="true">&times;</span>
						</button>
					  </div>						                                     
                         <div className="modal-body">
						   <div className="wapen">
							   <div className="rewardImg">
									<img className="wapenbox" src={rewardImg} alt={rewardTitle} />									
							  </div>	
									<img className="rewardboxImg" src="images/box.png" alt="" />						  
								</div>
							  <div className="reward">
							  <h3>Reward {rewardTitle}</h3>
								<p>Kindly log back into the game to access the rewarded items in your inventory.</p>
							  </div>
						  </div>
                    </div>
                </div>
            </div>  
            <Footer />
        </div>
    );
}

export default BuyCoins;
