import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import config from '../config';
import Cookies from 'js-cookie';
import { ResetPasswordAction, getcartcountAction } from '../Action/user.action';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
const Login = () => {

    let { token } = useParams();
    const [cartcount, setcartcount] = useState(0);
    const [form, setForm] = useState({ password: '', confirm_password: '', token : token })
    const [validatioError, setvalidatioError] = useState({});
    const [isLoading, setisLoading] = useState(false);
	
	 useEffect(() => {       
         if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            }
    }, []);
    
    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setForm((old) => {
            return { ...old, [name]: value }
        })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }

    function validate() {
        let passwordError = "";
        let confirmPasswordError = "";

        if (form.password === '') {
            passwordError = "Password is required."
        }
        if (form.confirm_password === '') {
            confirmPasswordError = "Confirm password is required."
        }
        if (form.password != form.confirm_password && (form.password && form.confirm_password)) {
            confirmPasswordError = "Password and confirm password does not match."
        } 
        
	  if (form.password != '') {
	
		if (form.password.length < '8') {
			passwordError = "Password must be 8 character long."
			
		}
		if (!form.password.match(/[!@#$%^&*(){}[\]<>?|.:;_-]/)) {
			passwordError = "Password must be at least one special character"
			
		}
		if (!form.password.match(/[A-Z]/)) {
			passwordError = "Password must be at least one capital letter"
			
		}
		if (!form.password.match(/\d/)) {
			passwordError = "Password must be at least one number"
			
		}
		
		
	}       
        if (passwordError || confirmPasswordError) {
            setvalidatioError({
                passwordError, confirmPasswordError
            })
            return false
        } else {
            return true
        }
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {
            setisLoading(true);
            let res = await ResetPasswordAction(form);
            if (res.success) {
                toast.success(res.msg);
                setTimeout(() => {
                    window.location.href = `${config.baseUrl}login`;
                }, 2000);
            } else {
                setisLoading(false);
                toast.error(res.msg);
            }
        }
    }
	
	 const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
	
    return (
        <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}}  />
            <div class="registration-section">
	<div class="container">
		<div class="registration-container">
			<div class="row">
				<div class="col-md-12">
					<div class="Registration">
						<h2>Reset Password</h2>
					</div>
				<div class="in-tech  login-block">
				<form onSubmit={SubmitForm}>
				<div className="row">
					<div className="col-md-12">
					<span className="validatioError">Password must be 8 character long with at least one letter, one number, and one special character.<br /><br /></span>
						<div class="form-group">
							<label>Password <span className="validationErr">{validatioError.passwordError}</span></label>
							 <input autoComplete="off" name="password" id='passwordError' onChange={inputHandler} type="password" placeholder="Enter Password" />						
						</div> 
						<div class="form-group">
							<label>Confirm Password <span className="validationErr">{validatioError.confirmPasswordError}</span></label>
							<input autoComplete="off" name="confirm_password" id='confirmPasswordError' onChange={inputHandler} type="password" placeholder="Enter Confirm Password" />					
						</div> 
					</div>					
					</div> 
					<div class="row">
						<div class="col-md-6 align-items-center remember">							
						</div>
						<div class="col-md-6">
							<div class="your-password">
								 <Link to={`${config.baseUrl}login`} className="forgot-pass">Login?</Link>
							</div>
						</div>				
						<div class="col-md-12">
							<div class="login button">
							 {isLoading ?
								<button disabled className="login-btn">Processing &nbsp; <i className="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></button>
								:
								<button className="login-btn">Update</button>
							}
								
							</div>
						</div>
					</div>
				</form>
				</div>
		</div>
	</div>

	</div>
	</div>
</div>
           
           
            <Footer />
        </div>
    );
}

export default Login;
