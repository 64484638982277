import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import toast, { Toaster } from 'react-hot-toast';
import config from '../config';
import Cookies from 'js-cookie';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCategoryAction, updateNftDataAction, createMetadataAction, getNftDetailsAction, getcartcountAction } from '../Action/user.action';
import { useParams, useNavigate } from 'react-router-dom';
const EditeNFT = () => {

    const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
    const { id } = useParams();
    const [cartcount, setcartcount] = useState(0);
   // const [Category, setCategory] = useState([]);
    const [image_preview, setimage_preview] = useState('');
    const [image_file, setimage_file] = useState('');
    const [FileType, setFileType] = useState('');
    const [validatioError, setvalidatioError] = useState({});
    const [spinloader, setspinloader] = useState(0);
    const [loader, setloader] = useState(0);
    const [NftDetails, setNftDetails] = useState(0);
    const [currentDate, setcurrentDate] = useState(new Date());

    useEffect(() => {
        if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }        
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            }

       // getCategoryAPI();
        getNFTDetailsAPI();
    }, []);

    //~ const getCategoryAPI = async () => {
        //~ let res = await getCategoryAction();
        //~ if (res.success) {
            //~ setCategory(res.data)
        //~ }
    //~ }
    
    const navigate = useNavigate();
	  const handleGoBack = () => {
		navigate(-1); // Navigate back by one step in the history stack
	}; 

    const getNFTDetailsAPI = async () => {
		setloader(1)
        let res = await getNftDetailsAction({ 'id': id });
        if (res.success) {
            setNftDetails(res.data)
        }
        setTimeout(() => {
			setloader(0);
		}, 1000);
    }
    
    const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }

    const imageUpload = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];

        let file_type = '';
        if (image_as_files.type.indexOf('image') === 0) {
            file_type = 'image';
        } else {
            file_type = 'video';
        }

        setimage_file(image_as_files);
        setimage_preview(image_as_base64);
        setFileType(file_type);
        setvalidatioError((old) => {
            return { ...old, ['imageError']: '' }
        })
    }

    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setNftDetails((old) => {
            return { ...old, [name]: value }
        })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }

    function validate() {
        let titleError = "";
        let descriptionError = "";
       // let categoryError = "";
        let priceError = "";
        let imageError = "";

        if (NftDetails?.title === '') {
            titleError = "Title field is required."
        }
        //if (NftDetails?.description === '') {
        //    descriptionError = "Description field is required."
        //}
       // if (NftDetails?.category_id == 0) {
       //     categoryError = "Category field is required."
       // }
        if (NftDetails?.price == 0) {
            priceError = "Price field is required."
        }
        if (image_file == '' && NftDetails?.image == "") {
            imageError = "Image field is required."
        }
        if (titleError || descriptionError || priceError || imageError) {
            setvalidatioError({
                titleError, descriptionError,  priceError, imageError
            })
            return false
        } else {
            return true
        }
    }

    const updateNFt = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {
            setspinloader(1);

            let fileHash = "";
            if (image_file) {
                fileHash = await imageUploadOnPinata();
                NftDetails.file_type = FileType;
            } else {
                fileHash = NftDetails?.image;
                NftDetails.file_type = NftDetails?.file_type;
            }

            if (fileHash) {
                NftDetails.ipfsHash = fileHash;
                let res = await updateNftDataAction(NftDetails);
                if (res.success) {
                    toast.success(res.msg);                    
					setTimeout(() => {
							if(NftDetails.nft_type == config.WEAPON)
							{
								window.location.href = `${config.baseUrl}portfolio-weapon`;
							}
							else if(NftDetails.nft_type == config.WEED || NftDetails.nft_type == config.COFFIEE)
							{
								window.location.href = `${config.baseUrl}portfolio-license`;
							}
							else if(NftDetails.nft_type == config.LAND)
							{
								window.location.href = `${config.baseUrl}portfolio-land`;
							}
							else if(NftDetails.nft_type == config.SAFE)
							{
								window.location.href = `${config.baseUrl}portfolio-other`;
							}
						}, 2000);
					
                } else {
                    toast.error(res.msg);
                }
            } else {
                setspinloader(0);
                toast.error('Something went wrong for uploading image on pinata.');
            }
        }
    }

    const imageUploadOnPinata = async () => {
        let formData = new FormData();
        formData.append('file', image_file);
        const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
        let resIPF = await axios.post(url,
            formData,
            {
                headers: {
                    'Content-Type': `multipart/form-data; boundary= ${formData._boundary}`,
                    'pinata_api_key': '7ca3b2043a2bf4cdeed0',
                    'pinata_secret_api_key': 'e058c8d5654ca0dc7adc1bba93aae1986d961882c9a8eda11ded091b28a6b5e0'
                }
            }
        );
        if (resIPF.data.IpfsHash) {
            let ipfsImg = resIPF.data.IpfsHash;
            return ipfsImg;
        } else {
            toast.error('Something went wrong uploading image on IPFS.');
            setspinloader(0);
            return false;
        }
    }

    const metaDataUpload = async (fileHash) => {
        let reqData = {
            "name": NftDetails.title,
            "description": NftDetails.description,
            "image": `https://ipfs.io/ipfs/${fileHash}`
        }

        let resIPF = await createMetadataAction(reqData);
        if (resIPF.tokenId) {
            let tokenId = resIPF.tokenId;
            return tokenId;
        } else {
            toast.error('Something went wrong creating metadata.');
            setspinloader(0);
            return false;
        }
    }

    function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const handleChangeStartDate = e => {
        let startDate = formatDate(e);
        setNftDetails((old) => {
            return { ...old, ['start_date']: startDate }
        })
        setvalidatioError({ ...validatioError, ['startDateError']: '' });
    }

    const handleChangeExpiryDate = e => {
        let expiryDate = formatDate(e);
        setNftDetails((old) => {
            return { ...old, ['expiry_date']: expiryDate }
        })
        setvalidatioError({ ...validatioError, ['expiryDateError']: '' });
    }

    return (
        <div className='create-item'>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
           <div class="about-section">
			<div class="container">
				<div class="about-us-heading">
					<h1>Edit NFT</h1>   
			</div>
			</div>
			</div>
            <div className="registration-section">            
					<div className="container">						
							<div className="row">	
							 <div className="col-12 back-btn">
                    <button className="custom-btn btn-bg-blue" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i>Back</button>
                    </div>							
								<div className="col-xl-5 col-lg-5 col-md-6 col-12">	
										<div className="sc-card-profile text-center">
										<div className="card-media pro-view">
										 {loader === 0 ? (
										  image_preview ? (
											<>
											  <img className='123' src={image_preview} /> &nbsp;
											</>
										  ) : NftDetails?.isCollected ? (
											NftDetails.nft_type === parseInt(config.WEED) ? (
											  <>
												<img src={`licenses/${NftDetails.city_image_title}-premium.png`} alt="NFT Image" />
											  </>
											) : NftDetails.nft_type === parseInt(config.COFFIEE) ? (
											  <>
												<img src={`licenses/${NftDetails.city_image_title}-exclusive.png`} alt="NFT Image" />
											  </>
											) : NftDetails.nft_type === parseInt(config.LAND) ? (
											  <>
												<img className='' src="images/lant-plot.png" /> &nbsp;
											  </>
											) : (
											  <>
												<img className='1' src={`${config.ipfsUrl + NftDetails?.image}`} /> &nbsp;
											  </>
											)
										  ) : (
											<>
											  <img className='' src={`${config.ipfsUrl + NftDetails?.image}`} /> &nbsp;
											</>
										  )
										) : (
											<div className="loaderDiv">
										  <img className='loaderImg' src="images/loader.gif" height={50} width={50} />
										  </div>
										)}
											
										</div>
									{	/*<div id="upload-profile">
											<Link to="#" className="btn-upload">
												Upload New Photo </Link>
											<input onChange={profilePicChange} id="tf-upload-img" accept="image/png, image/jpeg" type="file" name="profile" required="" />
										</div> */
									}
									</div>
								
								</div>
								<div className="col-xl-7 col-lg-7 col-md-12 col-12">								
									 <div className="form-upload-profile">
                                 <form onSubmit={updateNFt}>
                                    <div className="form-infor-profile profile-w100">
                                        <div className="info-account">
                                            <h4 className="title-create-item">NFT info</h4>
                                            <fieldset>
                                                <h4 className="title-infor-account">Title</h4>
                                                <label>{NftDetails?.title}</label>
                                               <span className="validationErr">{validatioError.titleError}</span>
                                                <input disabled="disabled" type="text" placeholder="Enter Title" id='titleError' className="dpnone" name='title' onChange={inputHandler} value={NftDetails?.title} />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Description</h4>
                                                 <label>{NftDetails?.description}</label>
                                               <span className="validationErr">{validatioError.descriptionError}</span>
                                                <textarea className="dpnone" disabled="disabled" id='descriptionError' name='description' onChange={inputHandler} placeholder="e.g. “This is very limited item”" value={NftDetails?.description}></textarea>
                                            </fieldset>
                                            <fieldset>
												<div className="royalty">
													<h4 className="title-infor-account ">Royalties(%)</h4>
													<input disabled={NftDetails?.owner_id == NftDetails.created_by ? '' : 'disabled'} name='royalty_percentage' onChange={inputHandler} type="text" placeholder="Eg. 5%, 10%, 15%" onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} value={NftDetails?.royalty_percentage} />
                                                 </div>
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Price(SIN)</h4>
                                                <span className="validationErr">{validatioError.priceError}</span>
                                                        <input name='price' id='priceError' onChange={inputHandler} type="text" placeholder="Enter Price (SIN)" onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} value={Math.abs(NftDetails?.price)} />
                                            </fieldset>
                                        </div>
                                        
                                    </div>
                                    {spinloader == '0' ?
										<button className='custom-btn' >Update</button>
										:
										<button disabled className='custom-btn' >Updating NFT <i className="fa fa-spinner fa-spin validat"></i></button>
									}                                   
                                </form>
                            </div>
								</div>
							</div>
						</div>				
				</div>
            <Footer />
        </div>
    );
}

export default EditeNFT;
