import React from 'react';

const DamageRating = ({ rating }) => {
  // Determine the number of filled and empty stars
  const filledStars = Math.round(rating);
  const emptyStars = 5 - filledStars;

  return (
    <div>
      {/* Render filled stars */}
      {Array.from({ length: filledStars }, (_, index) => (
        <span className="icon-1" key={index}><i className="fa-solid fa-star"></i></span>
      ))}

      {/* Render empty stars */}
      {Array.from({ length: emptyStars }, (_, index) => (
		<span className="icon-black" key={index}><i className="fa-solid fa-star"></i></span>
      ))}
    </div>
  );
};

export default DamageRating;
