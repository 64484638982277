import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import config from '../config';
import { RegisterAction } from '../Action/user.action';

const Login = () => {

    const [form, setForm] = useState({ first_name : '', last_name : '', email: '', password: '', confirm_password  : '' })
    const [validatioError, setvalidatioError] = useState({});
    const [isLoading, setisLoading] = useState(false);
	const [cartcount, setcartcount] = useState(0);
    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setForm((old) => {
            return { ...old, [name]: value }
        })

        if (value !== '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }

    }

    function validate() {
        let firstnameError = "";
        let lastnameError = "";
        let emailError = "";
        let passwordError = "";
        let confirmPasswordError = "";

        if (form.first_name === '') {
            firstnameError = "First name is required."
        }

        if (form.last_name === '') {
            lastnameError = "Last name is required."
        }        

        if (form.email === '') {
            emailError = "Email is required."
        }
        if (form.password === '') {
            passwordError = "Password is required."
        }

        if (form.confirm_password === '') {
            confirmPasswordError = "Confirm password is required."
        }
        
        if (form.password != '') {
		
            if (form.password.length < '8') {
				passwordError = "Password must be 8 character long."
				
			}
			if (!form.password.match(/[!@#$%^&*(){}[\]<>?|.:;_-]/)) {
				passwordError = "Password must be at least one special character"
				
			}
			if (!form.password.match(/[A-Z]/)) {
				passwordError = "Password must be at least one capital letter"
				
			}
			if (!form.password.match(/\d/)) {
				passwordError = "Password must be at least one number"
				
			}
			
			
        }

        if ( form.password != form.confirm_password) {
            confirmPasswordError = "Password and confirm password not match."
        }        

        if ( firstnameError || lastnameError || emailError || passwordError || confirmPasswordError) {
            setvalidatioError({
                firstnameError, lastnameError, emailError, passwordError, confirmPasswordError
            })
            return false
        } else {
            return true
        }
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {
            setisLoading(true);
            let res = await RegisterAction(form);
            if (res.success) {
                toast.success(res.msg);
                setTimeout(() => {
                    window.location.href = `${config.baseUrl}login`;
                }, 2000);
            } else {
                setisLoading(false);
                toast.error(res.msg);
            }
        }
    }

    return (
        <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
            <div className="registration-section">
					<div className="container">
						<div className="registration-container">
							<div className="row">
								<div className="col-md-12">
									<div className="Registration">
										<h2> Signup To Sinverse</h2>
									</div>
									 <form  onSubmit={SubmitForm}>
									<div className="in-tech  registration-block">						
											<div className="row">
												<div className="col-md-6">
												<div class="form-group">
													<label for="first_name">First Name <span className="validationErr">{validatioError.firstnameError}</span></label>
													<input className="form-control" autoComplete="off" name="first_name" id='firstnameError' onChange={inputHandler} type="text" placeholder="Enter First Name" /> 	
													</div>									
												</div>
												<div className="col-md-6">
												<div class="form-group">
													<label for="last_name">Last Name <span className="validationErr">{validatioError.lastnameError}</span></label>
													<input className="form-control" autoComplete="off" name="last_name" id='lastnameError' onChange={inputHandler} type="text" placeholder="Enter Last Name" />
													</div>
												</div>
												<div className="col-md-12">
													<div class="form-group">
														<label for="email">Email Address <span className="validationErr">{validatioError.emailError}</span></label>												
														<input className="form-control" autoComplete="off" name="email" id='emailError' onChange={inputHandler} type="email" placeholder="Enter Email Address" />
													</div>
												</div>
												<div className="col-md-12 validation-info">
												<span className="">Password must be 8 character long with at least one letter, one number, and one special character.</span>
												 
												  </div>
												<div className="col-md-6">											
													<div class="form-group">
															<label for="password">Password <span className="validationErr">{validatioError.passwordError}</span></label>
														<input className="form-control"name="password" type="password" id='passwordError' onChange={inputHandler} placeholder="Enter Password" />						
													</div>	
												</div> 											
												<div className="col-md-6">
												<div class="form-group">
													<label for="confirm_password">Confirm Password <span className="validationErr">{validatioError.confirmPasswordError}</span></label>									
                                       												
													<input className="form-control" name="confirm_password" type="password" id='confirmPasswordError' onChange={inputHandler} placeholder="Enter Confirm Password" />
												</div> 
												</div>         	
												
											</div>
										
									</div>
									<div className="Register button">
										{isLoading ?
                                            <button disabled className="in-tech-btn">Processing &nbsp; <i className="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></button>
                                            :
                                            <button className="in-tech-btn">Signup</button>
                                        }
										
									</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
            <Footer />
        </div>
    );
}

export default Login;
