import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import config from '../config';
import Cookies from 'js-cookie';
import { ForgotPasswordAction, getcartcountAction } from '../Action/user.action';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
const Login = () => {

    const [form, setForm] = useState({ email: '' })
    const [cartcount, setcartcount] = useState(0);
    const [validatioError, setvalidatioError] = useState({});
	const [isLoading, setisLoading] = useState(false);
	
	 useEffect(() => {        
         if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            }
    }, []);
	
    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setForm((old) => {
            return { ...old, [name]: value }
        })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }

    function validate() {
        let emailError = "";

        if (form.email === '') {
            emailError = "Email is required."
        }
        if (emailError) {
            setvalidatioError({
                emailError
            })
            return false
        } else {
            return true
        }
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {
            let res = await ForgotPasswordAction(form);
            if (res.success) {
                setForm((old) => {
                    return { ...old, ['email']: '' }
                })
                toast.success(res.msg);
                setTimeout(() => {
                    window.location.href = `${config.baseUrl}login`;
                }, 3000);
            } else {
                toast.error(res.msg);
            }
        }
    }
	
	const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
    
    return (
        <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}}  />
            <div class="registration-section">
	<div class="container">
		<div class="registration-container">
			<div class="row">
				<div class="col-md-12">
					<div class="Registration">
						<h2>Forget Password</h2>
					</div>
				<div class="in-tech  login-block">
				<form onSubmit={SubmitForm}>
				<div className="row">
					<div className="col-md-12">
						<div class="form-group">
							<label>Email Address <span className="validationErr">{validatioError.emailError}</span></label>
							<input autoComplete="off" name="email" id='emailError' onChange={inputHandler} type="email" placeholder="Enter Email Address" class="form-control" />
					
						</div>
					</div>
					<div class="col-md-6 align-items-center remember"></div>
						<div class="col-md-6">
							<div class="your-password">
								  <Link to={`${config.baseUrl}login`} className="forgot-pass">Login?</Link>
							</div>
						</div>	
				</div>				
					<div class="row">									
						<div class="col-md-12">
							<div class="login button">
								<button className="login-btn">Request Resent Link</button>
							</div>
						</div>
					</div>
				</form>
				</div>
		</div>
	</div>

	</div>
	</div>
</div>

            <Footer />
        </div>
    );
}

export default Login;
