import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CardModal from './CardModal';
import config from '../../config';
import DamageRating from './DamageRating';
import AccuracyRating from './AccuracyRating';
import RateRating from './RateRating';

const TodayPicks = props => {
    const data 		= props.data[0];	
    const settings 	= props.data[1];	
    const [visible, setVisible] = useState(6);   
	const [previousValue, setPreviousValue] = useState(null);
	
	useEffect(() => {
		const wvisible = localStorage.getItem('wvisible');		
		if (wvisible) {
		  setVisible(wvisible);
		}
	  }, []);
  
  
    const showMoreItems = () => {
        setVisible((prevValue) => parseInt(prevValue) + 3);       
        localStorage.setItem('wvisible', (parseInt(visible) + 3));
    }
  
   return (
           <>
      {data.length > 0 ? (
      <div className="tab-section padd-bottom-0">
	<div className="container">	
		<div className="row">
		{ data.slice(0, visible).map((item, index) => (
				
		<>
		
		<div key={index} className="col-lg-4 col-md-6">
					<div className="tab-section-main">
						<div className="tab section">
							<div className="profile-images">						
							</div>
							<div className="Andrew-text">
								<p>{item.owner_address.toString().substring(0, 4) + '...' + item.owner_address.toString().substring(item.owner_address.length - 4)}</p>								
							</div>
							<div className="height-icon">
								<i className="fa-regular fa-heart"></i>
								<p>{item.itemLike}</p>
							</div>
						</div>
						<div className="images-sec">
							<Link to={`${config.baseUrl}nft-details/` + item.id}>
								<img src={`${config.ipfsUrl + item.image}`} className="ht-auto" />
							</Link>
						</div>
						<div className="section-pistol">
							<h3>{item.name}</h3>
						</div>
						
					{ item.item_category_id != 8 ?
						<>
						<div className="section-ster-icon">
							<div className="tab-icon">
								<p>Damage</p>
								<DamageRating rating={item.Damage} />
															
							</div>	
							 {item.item_category_id ===2 ? 
							 <>
							<div className="tab-icon">
								<p>Weapon reach</p>
								<AccuracyRating rating={item.Weapon_reach} />															
							</div>
							<div className="tab-icon">
								<p>Speed rating</p>
								<RateRating rating={item.Speed_Rating} />
							</div>
							</>
							:
							item.item_category_id ===3 || item.item_category_id ===4 ?  
							<>								
							<div className="tab-icon">
								<p>Accuracy</p>
								<AccuracyRating rating={item.Accuracy} />															
							</div>							
							<div className="tab-icon">
								<p>Blast radius</p>
								<RateRating rating={item.Blast_Radius} />
							</div>
							</>
							:
							<>
							<div className="tab-icon">
								<p>Accuracy</p>
								<AccuracyRating rating={item.Accuracy} />															
							</div>							
							<div className="tab-icon">
								<p>Rate of fire</p>
								<RateRating rating={item.Rate_of_Fire} />
							</div>
							</>
							}					
							
						</div>
						</>
						:
						<>
						<div className="section-ster-icon mh63">&nbsp;</div>
						</>
						}
						
						{ settings.is_weapon_on_sale ?
						<>
						<div className="price-section">
							<div className="price">
								<p>Price</p>
								<h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
							</div>
							<div className="price">
								<p>Token ID</p>
								<h3>#{item.token_id}</h3>
							</div>
							<Link to={`${config.baseUrl}nft-details/` + item.id}>
							<button className="tab-btn">Buy</button></Link>
						</div>
						</>
						: 
							item.item_category_id==8 ?
							<>
								<div className="price-section">
									<div className="price">
										<p>Price</p>
										<h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
									</div>
									<div className="price">
										<p>Token ID</p>
										<h3>#{item.token_id}</h3>
									</div>
									<Link to={`${config.baseUrl}nft-details/` + item.id}>
									<button className="tab-btn">Buy</button></Link>
								</div>
								</>
							:
							
						<div className="price-section">									
							<div className="price">
								<p>Token ID</p>
								<h3>#{item.token_id}</h3>
							</div>
							<Link to={`${config.baseUrl}nft-details/` + item.id}>
									<button className="tab-btn">Coming Soon</button></Link>
						</div>
						}
						
						<div className="View-History">
						<Link to={`${config.baseUrl}nft-details/` + item.id+`#history`}><i className="fa-solid fa-clock-rotate-left"></i>View History</Link>
						</div>
					</div>
				</div>
					</>
		))}
	{
		visible < data.length &&		
		<div className="lorn-more col">
			<button className="tab-last-btn" onClick={showMoreItems}>Load More</button>
		</div>
	}
	</div>
	</div>
</div>	
 ) : (
        // Show an empty div if there is no data
        <div></div>
      )}
    </>
    );
}



TodayPicks.propTypes = {
    data: PropTypes.array.isRequired,
}


export default TodayPicks;
