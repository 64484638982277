import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import config from '../config';
import Cookies from 'js-cookie';
import { getcartcountAction } from '../Action/user.action';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
const Page404 = () => {
	const [cartcount, setcartcount] = useState(0);
	 useEffect(() => {       
         if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            }
    }, []);
    
     const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
	 return (
       <div>
           <Header data={{'cartcount':cartcount}} />
           <div class="about-section">
				<div class="container">
					<div class="about-us-heading">
						<h2>404</h2>   
					</div>
				</div>
			</div>
			<div class="sections-contact-us">
				<div class="container">
					<div class="about-us text-center">
						<p>404 Page not found.</p>
					</div>
				</div>
			</div>    
            
            <Footer />
        </div>
    );
}

export default Page404;
