import React, { useState, Fragment, useEffect } from 'react';
import { Link, useSearchParams  } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Accordion } from 'react-bootstrap-accordion'
import { getMarketplaceSafeNFTsAction, getCategoryAction, nftLikeDislikeAction, getcartcountAction, getSettingsAction, getSubCategoryAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import DamageRating from '../components/layouts/DamageRating';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));

const Safenfts = () => {
    const [searchParams] = useSearchParams();
     const [settings, setSettings] = useState([]);
    const [cartcount, setcartcount] = useState(0);
    const [SearchFilter, setSearchFilter] = useState(searchParams.get('s'));
    const [visible, setVisible] = useState(6);
    const [vipvisible, setvipvisible] = useState(6);
    const [mtovisible, setmtovisible] = useState(6);
    const [NFTList, setNFTList] = useState([]);
    const [VIPNFTList, setVIPNFTList] = useState([]);
    const [MTONFTList, setMTONFTList] = useState([]);
    const [marketplaceNFTList, setmarketplaceNFTList] = useState([]);
    const [Category, setCategory] = useState([]);
    const [CategoryIdsFilters, setCategoryIdsFilters] = useState([]);
    const [RarityIdsFilter, setRarityFilter] = useState([]);    
    const [PriceFilterData, setPriceFilterData] = useState([]);
    const [NFTTypeData, setNFTTypeData] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	useEffect(async () => {
        getNFTListAPI();
        getVIPNFTListAPI();
        getMTOoNFTListAPI();
        getCategoryAPI();
        getSettingsAPI();
          if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            }
    }, []);

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 6);
    }
    const showMoreItemsMTO = () => {
        setmtovisible((prevValue) => prevValue + 6);
    }
    const showMoreItemsVIPpass = () => {
        setvipvisible((prevValue) => prevValue + 6);
    }

    const getNFTListAPI = async () => {
        let res = await getMarketplaceSafeNFTsAction({ 'user_id': loginData?.id, 'category_ids': [], 'nft_type': '', 'price_type': '','name': SearchFilter,'rarity':RarityIdsFilter, 'sub_cat' : 0 });
        if (res.success) {
            setNFTList(res.data);
            setmarketplaceNFTList(res.data);
        }
    }
    const getVIPNFTListAPI = async () => {
        let res = await getMarketplaceSafeNFTsAction({ 'user_id': loginData?.id, 'category_ids': [], 'nft_type': '', 'price_type': '','name': SearchFilter,'rarity':RarityIdsFilter,  'sub_cat' : config.VIP });
        if (res.success) {
            setVIPNFTList(res.data);           
        }
    }
    const getMTOoNFTListAPI = async () => {
        let res = await getMarketplaceSafeNFTsAction({ 'user_id': loginData?.id, 'category_ids': [], 'nft_type': '', 'price_type': '','name': SearchFilter,'rarity':RarityIdsFilter, 'sub_cat' : config.TToO });
        if (res.success) {
            setMTONFTList(res.data);
        }
    }
    
    const getSettingsAPI = async () => {
        let res = await getSettingsAction();
        if (res.success) {
            setSettings(res.data);
        }
    }
    
     const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }

    const getCategoryAPI = async () => {
        let res = await getCategoryAction();
        if (res.success) {
            setCategory(res.data)
        }
    }
    
     const handleKeyPress = async (event) => {
		if(event.key === "Enter"){
		   let res = await getMarketplaceSafeNFTsAction({ 'user_id': loginData?.id, 'category_ids': CategoryIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': SearchFilter,'rarity':RarityIdsFilter,  'sub_cat' : 0 });
				if (res.success) {
					if(res.data.length > 0){
						setNFTList(res.data);
					}else{
						setNFTList([]);
					}
				}else{
					setNFTList([]);
				}
		//VIP
		 let vipres = await getMarketplaceSafeNFTsAction({ 'user_id': loginData?.id, 'category_ids': CategoryIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': SearchFilter,'rarity':RarityIdsFilter,  'sub_cat' : config.VIP });
				if (vipres.success) {
					if(vipres.data.length > 0){
						setVIPNFTList(vipres.data);
					}else{
						setVIPNFTList([]);
					}
				}else{
					setVIPNFTList([]);
				}
				
		//TToO
		 let ttoores = await getMarketplaceSafeNFTsAction({ 'user_id': loginData?.id, 'category_ids': CategoryIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': SearchFilter,'rarity':RarityIdsFilter, 'sub_cat' : config.TToO });
				if (ttoores.success) {
					if(ttoores.data.length > 0){
						setMTONFTList(ttoores.data);
					}else{
						setMTONFTList([]);
					}
				}else{
					setMTONFTList([]);
				}				
				
		 }
	 }
    
     const SetNameFilter = async (e) => {
        setSearchFilter(e.target.value);  
         let res = await getMarketplaceSafeNFTsAction({ 'user_id': loginData?.id, 'category_ids': CategoryIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': e.target.value,'rarity':RarityIdsFilter, 'sub_cat' : 0 });
            if (res.success) {
                if(res.data.length > 0){
                    setNFTList(res.data);
                }else{
                    setNFTList([]);
                }
            }else{
                setNFTList([]);
            } 
            
            //VIP
		 let vipres = await getMarketplaceSafeNFTsAction({ 'user_id': loginData?.id, 'category_ids': CategoryIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': e.target.value,'rarity':RarityIdsFilter,  'sub_cat' : config.VIP });
				if (vipres.success) {
					if(vipres.data.length > 0){
						setVIPNFTList(vipres.data);
					}else{
						setVIPNFTList([]);
					}
				}else{
					setVIPNFTList([]);
				}
				
		//TToO
		 let ttoores = await getMarketplaceSafeNFTsAction({ 'user_id': loginData?.id, 'category_ids': CategoryIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': e.target.value,'rarity':RarityIdsFilter, 'sub_cat' : config.TToO });
				if (ttoores.success) {
					if(ttoores.data.length > 0){
						setMTONFTList(ttoores.data);
					}else{
						setMTONFTList([]);
					}
				}else{
					setMTONFTList([]);
				} 
        
    }

    const nftLike = async (type, item_id) => {
        let res = await nftLikeDislikeAction({ 'type': type, 'item_id': item_id });
        if (res.success) {
            getNFTListAPI();
        }
    }  
    
    return (
        <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
			<div className="market-place-banner">
			  <h1>MarketPLACE</h1>
			</div>
			<div className="market-place">
			<div className="container">
			<div className="row">
			  <div className="col-12 col-md-3 col-lg-2 px-0 ">
				<div className="left-sidebar">
				 <ul>
					<li><Link to={`${config.baseUrl}marketplace`} className="">Weapon & Armor Collections</Link></li>
					<li ><Link to={`${config.baseUrl}land-marketplace`} className="">Land</Link></li>
					<li><Link to={`${config.baseUrl}licenses-cities`} className="">License</Link></li>	
					<li className="active"><Link to={`${config.baseUrl}othernfts`} className="">Other NFTs</Link></li>	
					<li><Link to={`${config.baseUrl}gold-coins`} className="">Gold Coins</Link></li>				
				  </ul>
				</div>
			  </div>
			  <div className="col-12 col-md-9 col-lg-10 pl-4">
				<div className="row">
				<div className="col-12 d-flex pro-filter-outer">					
					<div className="pro-search">
					  <form className="search-form">
							 <input type="text" id="search" className="form-control" placeholder="Search (Title Or Token Id)..." onChange={SetNameFilter} name="s" title="Search for" value={SearchFilter} onKeyDown={() => handleKeyPress(this)} />
							   <i className="fa-solid fa-magnifying-glass"></i>           
							</form>							

					</div>
				  </div>
								 
				  <div className="col-12"><div className="pro-line"></div></div>
				  <div className="col-12">
					<h2 className="title">Explore Collection Safe NFTs ({NFTList.length})</h2>
				  </div>
				  {NFTList.length == 0 ?
					<>
						<h4 className="not-found">No data found!!!</h4>
					</>
					:
					NFTList.slice(0, visible).map((item, index) => (
					<div className="col-lg-4 col-md-6">
							<div className="tab-section-main">
								<div className="tab section">
									<div className="profile-images">
									
									</div>
									<div className="Andrew-text">
									<p>{item.owner_address.toString().substring(0, 4) + '...' + item.owner_address.toString().substring(item.owner_address.length - 4)}</p>	
									</div>
									<div className="height-icon">
										<i className="fa-regular fa-heart"></i>
										<p>{item.itemLike}</p>
									</div>
								</div>
								<div className="images-sec">
								<Link to={`${config.baseUrl}nft-details/` + item.id}>
									<img src={`${config.ipfsUrl + item.image}`} alt="Axies" />
									</Link>
								</div>
								<div className="section-map">
									<h3>
									{
									  windowWidth < 1299 ?						
										item.name.toString().substring(0, 15) + '...'
										:
										item.name.toString().substring(0, 23) +
										(item.name.length > 23 ? '...' : '')
									}
									
									</h3>
								</div>	
								{ settings.is_safe_on_sale ?
								<>	
									<div className="price-section">
										<div className="price">
											<p>Price</p>
											<h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
										</div>
										<div className="price">
											<p>Token ID</p>
											<h3>#{item.token_id}</h3>
										</div>
										<Link to={`${config.baseUrl}nft-details/` + item.id}>
												<button className="tab-btn">Buy</button></Link>
									</div>
								</>
								:
								<>	
								<div className="price-section">						
									<div className="price">
											<p>Token ID</p>
											<h3>#{item.token_id}</h3>
										</div>
									<Link to={`${config.baseUrl}nft-details/` + item.id}><button className="tab-btn">Coming Soon</button></Link>
								</div>
								</>
								}
								
								
								<div className="View-History">
								<Link to={`${config.baseUrl}nft-details/` + item.id+`#history`}><i className="fa-solid fa-clock-rotate-left"></i>View History</Link>
								</div>
							</div>
						</div>
					
					
					
					))}
					{
                        visible < NFTList.length &&
                                                      
					<div className="lorn-more col-12 text-center">
					<Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><button className="tab-last-btn btn-bg-pink px-5">Load More</button></Link>					
					</div>
					  }
					  
				{VIPNFTList.length > 0 ?
					<>	  
					  
					<div className="col-12 mt-5"><div className="pro-line"></div></div>
				  <div className="col-12">
					<h2 className="title">Explore Collection VIP PASS NFTs ({VIPNFTList.length})</h2>
				  </div>
				  {VIPNFTList.length == 0 ?
					<>
						<h4 className="not-found">No data found!!!</h4>
					</>
					:
					VIPNFTList.slice(0, vipvisible).map((item, index) => (
					<div className="col-lg-4 col-md-6">
							<div className="tab-section-main">
								<div className="tab section">
									<div className="profile-images">
									
									</div>
									<div className="Andrew-text">
									<p>{item.owner_address.toString().substring(0, 4) + '...' + item.owner_address.toString().substring(item.owner_address.length - 4)}</p>	
									</div>
									<div className="height-icon">
										<i className="fa-regular fa-heart"></i>
										<p>{item.itemLike}</p>
									</div>
								</div>
								<div className="images-sec">
								<Link to={`${config.baseUrl}nft-details/` + item.id}>
									<img src={`${config.ipfsUrl + item.image}`} alt="Axies" />
									</Link>
								</div>
								<div className="section-map">
									<h3>
									{
									  windowWidth < 1299 ?						
										item.name.toString().substring(0, 15) + '...'
										:
										item.name.toString().substring(0, 23) +
										(item.name.length > 23 ? '...' : '')
									}
									
									</h3>
								</div>	
								{ settings.is_safe_on_sale ?
								<>	
									<div className="price-section">
										<div className="price">
											<p>Price</p>
											<h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
										</div>
										<div className="price">
											<p>Token ID</p>
											<h3>#{item.token_id}</h3>
										</div>
										<Link to={`${config.baseUrl}nft-details/` + item.id}>
												<button className="tab-btn">Buy</button></Link>
									</div>
								</>
								:
								<>	
								<div className="price-section">						
									<div className="price">
											<p>Token ID</p>
											<h3>#{item.token_id}</h3>
										</div>
									<Link to={`${config.baseUrl}nft-details/` + item.id}><button className="tab-btn">Coming Soon</button></Link>
								</div>
								</>
								}
								
								
								<div className="View-History">
								<Link to={`${config.baseUrl}nft-details/` + item.id+`#history`}><i className="fa-solid fa-clock-rotate-left"></i>View History</Link>
								</div>
							</div>
						</div>
					
					
					
					))}
					{
                        vipvisible < VIPNFTList.length &&
                                                      
					<div className="lorn-more col-12 text-center">
					<Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItemsVIPpass}><button className="tab-last-btn btn-bg-pink px-5">Load More</button></Link>					
					</div>
					  }
					  
					</>
					:''
				}  
				{ MTONFTList.length > 0 ?
					<>	  
					   <div className="col-12 mt-5"><div className="pro-line"></div></div>
				  <div className="col-12">
					<h2 className="title">Explore Collection TToO Membership NFTs ({MTONFTList.length})</h2>
				  </div>
				  {MTONFTList.length == 0 ?
					<>
						<h4 className="not-found">No data found!!!</h4>
					</>
					:
					MTONFTList.slice(0, mtovisible).map((item, index) => (
					<div className="col-lg-4 col-md-6">
							<div className="tab-section-main">
								<div className="tab section">
									<div className="profile-images">
									
									</div>
									<div className="Andrew-text">
									<p>{item.owner_address.toString().substring(0, 4) + '...' + item.owner_address.toString().substring(item.owner_address.length - 4)}</p>	
									</div>
									<div className="height-icon">
										<i className="fa-regular fa-heart"></i>
										<p>{item.itemLike}</p>
									</div>
								</div>
								<div className="images-sec">
								<Link to={`${config.baseUrl}nft-details/` + item.id}>
									<img src={`${config.ipfsUrl + item.image}`} alt="Axies" />
									</Link>
								</div>
								<div className="section-map">
									<h3>
									{
									  windowWidth < 1299 ?						
										item.name.toString().substring(0, 15) + '...'
										:
										item.name.toString().substring(0, 23) +
										(item.name.length > 23 ? '...' : '')
									}
									
									</h3>
								</div>	
								{ settings.is_safe_on_sale ?
								<>	
									<div className="price-section">
										<div className="price">
											<p>Price</p>
											<h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
										</div>
										<div className="price">
											<p>Token ID</p>
											<h3>#{item.token_id}</h3>
										</div>
										<Link to={`${config.baseUrl}nft-details/` + item.id}>
												<button className="tab-btn">Buy</button></Link>
									</div>
								</>
								:
								<>	
								<div className="price-section">						
									<div className="price">
											<p>Token ID</p>
											<h3>#{item.token_id}</h3>
										</div>
									<Link to={`${config.baseUrl}nft-details/` + item.id}><button className="tab-btn">Coming Soon</button></Link>
								</div>
								</>
								}
								
								
								<div className="View-History">
								<Link to={`${config.baseUrl}nft-details/` + item.id+`#history`}><i className="fa-solid fa-clock-rotate-left"></i>View History</Link>
								</div>
							</div>
						</div>
					
					
					
					))}
					{
                        mtovisible < MTONFTList.length &&
                                                      
					<div className="lorn-more col-12 text-center">
					<Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItemsMTO}><button className="tab-last-btn btn-bg-pink px-5">Load More</button></Link>					
					</div>
					  }
					  
					  </>
					  :''
				  }

					</div>
					</div>
					</div>
					</div>
					</div>
				   

            <Footer />
        </div >
    );
}

export default Safenfts;
