import { getRequest, postRequest, putRequest, deleteRequest, postRequestFormData } from "../coreFIles/helper";

export const LoginAction = (data) => {
   return postRequest('login', data).then(res => { return res.data })
}

export const LoginWithTokenAction = (data) => {
   return postRequest('LoginWithToken', data).then(res => { return res.data })
}

export const RegisterAction = (data) => {
   return postRequest('userRegister', data).then(res => { return res.data })
}

export const ForgotPasswordAction = (data) => {
   return postRequest('forgotPassword', data).then(res => { return res.data })
}

export const ResetPasswordAction = (data) => {
   return postRequest('resetPassword', data).then(res => { return res.data })
}

export const UpdateProfileAction = (data) => {
   return postRequestFormData('UpdateUserProfile', data).then(res => {return res.data})
}

export const getCategoryAction = (data) => {
   return getRequest('getCategory', data).then(res => { return res.data })
}

export const getSubCategoryAction = (data) => {
   return getRequest('getSubCategory', data).then(res => { return res.data })
}

export const getlandroadsAction = (data) => {
   return getRequest('getlandroads', data).then(res => { return res.data })
}

export const getglobalroyalityAction = (data) => {
   return getRequest('getglobalroyality', data).then(res => { return res.data })
}

export const createNftAction = (data) => {
   return postRequest('createUserNFT', data).then(res => { return res.data })
}

export const createMetadataAction = (data) => {
   return postRequest('createMetadata', data).then(res => {return res.data})
}

export const getNFTListByUserAction = (data) => {
   return postRequest('getUsersNFTList', data).then(res => { return res.data })
}

export const getLandListUserAction = (data) => {
   return postRequest('getUserLandList', data).then(res => { return res.data })
}
export const getfilterLandListUserAction = (data) => {
   return postRequest('getfilterLandListUser', data).then(res => { return res.data })
}
export const getNFTLicenseByUserAction = (data) => {
   return postRequest('getNFTLicenseByUser', data).then(res => { return res.data })
}
export const getfilterLicenseByUserAction = (data) => {
   return postRequest('getfilterLicenseByUser', data).then(res => { return res.data })
}


export const getSafeNFTByUserAction = (data) => {
   return postRequest('getSafeNFTByUser', data).then(res => { return res.data })
}
export const getfilterSafeNFTByUserAction = (data) => {
   return postRequest('getfilterSafeNFTByUser', data).then(res => { return res.data })
}
export const getCityAction = (data) => {
   return getRequest('getCities', data).then(res => { return res.data })
}

export const getLicenseListUserAction = (data) => {
   return postRequest('getLicenseListUser', data).then(res => { return res.data })
}



export const filterUserNFTsAction = (data) => {
   return postRequest('filterUserNFTs', data).then(res => { return res.data })
}

export const getUserNFTListAction = (data) => {
   return postRequest('getUserListingNFTList', data).then(res => { return res.data })
}
export const getUserDetailsAction = (data) => {
   return postRequest('getUserDetails', data).then(res => { return res.data })
}

export const getMarketplaceNFTsAction = (data) => {
   return postRequest('getMarketplaceNFTs', data).then(res => { return res.data })
}

export const getMarketplaceLandNFTsAction = (data) => {
   return postRequest('getMarketplaceLandNFTs', data).then(res => { return res.data })
}

export const getMarketplaceSafeNFTsAction = (data) => {
   return postRequest('getMarketplaceSafeNFTs', data).then(res => { return res.data })
}



export const putOnSaleAction = (data) => {
   return postRequest('putOnSaleAction', data).then(res => { return res.data })
}
export const putgroupItemOnSaleAction = (data) => {
   return postRequest('putgroupItemOnSaleAction', data).then(res => { return res.data })
}

export const cancelOrderAction = (data) => {
   return postRequest('cancelOrderAction', data).then(res => { return res.data })
}

export const getNftDetailsAction = (data) => {
   return postRequest('getFrontNftDetailsById', data).then(res => {return res.data})
}
export const getLicenseNftDetailsAction = (data) => {
   return postRequest('getFrontLicenseNftDetailsById', data).then(res => {return res.data})
}

export const getLandgroupDetailsAction = (data) => {
   return postRequest('getLandgroupDetails', data).then(res => {return res.data})
}

export const getLandgroupAllNFTsDetailsAction = (data) => {
   return postRequest('getLandgroupAllNFTsDetails', data).then(res => {return res.data})
}

export const updateLandgroupAction = (data) => {
   return postRequest('updateLandgroup', data).then(res => {return res.data})
}
export const getlandmetadataAction = (data) => {
   return postRequest('getlandmetadata', data).then(res => {return res.data})
}
export const getlicensemetadataAction = (data) => {
   return postRequest('getlicensemetadata', data).then(res => {return res.data})
}

export const updateNftAction = (data) => {
   return postRequest('updateNft', data).then(res => {return res.data})
}
export const updateNftDataAction = (data) => {
   return postRequest('updateNftData', data).then(res => {return res.data})
}


export const nftLikeDislikeAction = (data) => {
   return postRequest('nftLikeDislike', data).then(res => {return res.data})
}

export const getNftHistoryAction = (data) => {
   return postRequest('getNftHistory', data).then(res => {return res.data})
}

export const getHomePageNFTAction = (data) => {
   return postRequest('getHomePageNFT', data).then(res => {return res.data})
}

export const getHomePageSafeNFTAction = (data) => {
   return postRequest('getHomePageSafeNFT', data).then(res => {return res.data})
}

export const getHomePageLandAction = (data) => {
   return postRequest('getHomePageLand', data).then(res => {return res.data})
}

export const getHomePageContentListAction = (data) => {
   return postRequest('getHomePageContentList', data).then(res => {return res.data})
}

export const getHomePageSliderNFTAction = (data) => {
   return postRequest('getHomePageSliderNFTNEW', data).then(res => {return res.data})
}

export const verifyAccountAction = (data) => {
   return postRequest('verifyAccount', data).then(res => { return res.data })
}

export const getProfileAction = (data) => {
   return postRequest('getUserProfile', data).then(res => {return res.data})
}

export const buyItemAction = (data) => {
   return postRequest('nftPurchase', data).then(res => { return res.data })
}

export const bidPlaceAPIAction = (data) => {
   return postRequest('insertBid', data).then(res => { return res.data })
}

export const getUserPurchaseAPIAction = (data) => {
   return postRequest('getUserPurchase', data).then(res => { return res.data })
}


export const getUserPurchaselicensesAction = (data) => {
   return postRequest('getUserPurchaselicenses', data).then(res => { return res.data })
}


export const getcitieslistAction = (data) => {
  return postRequest("getfrontcitylist", data).then((res) => {
    return res.data;
  });
};

export const getcityidAction = (data) => {
  return postRequest("getcitydetails", data).then((res) => {
    return res.data;
  });
};
export const getCityLicenseCountAction = (data) => {
  return postRequest("getCityLicenseCount", data).then((res) => {
    return res.data;
  });
};


export const getUserSaleDataAPIAction = (data) => {
   return postRequest('getUserSaleListing', data).then(res => { return res.data })
}

export const getUserSoldDataAPIAction = (data) => {
   return postRequest('getUserSoldListing', data).then(res => { return res.data })
}

export const getUsertransactionsAPIAction = (data) => {
   return postRequest('getUserTransactions', data).then(res => { return res.data })
}

export const termsConditionAction = (data) => {
   return getRequest('getTermsConditionForUserpanel', data).then(res => { return res.data })
}

export const privacyPolicyAction = (data) => {
   return getRequest('getPrivacyPolicyForUserpanel', data).then(res => { return res.data })
}

export const ContactFormAction = (data) => {
   return postRequest('ContactFormRequest', data).then(res => { return res.data })
}

export const addNewsLetterAction = (data) => {
   return postRequest('addNewsLetter', data).then(res => { return res.data })
}

export const getSocialLinksAction = (data) => {
   return getRequest('getSocialLinksForUserpanel', data).then(res => { return res.data })
}

export const getSettingsAction = (data) => {
   return getRequest('getSettings', data).then(res => { return res.data })
}

export const faqsAction = (data) => {
   return getRequest('faqsList', data).then(res => { return res.data })
}

export const getBidPlacedHistoryAction = (data) => {
   return postRequest('getUserBids', data).then(res => { return res.data })
}

export const getNftBidReceivedHistoryAPIAction = (data) => {
   return postRequest('myBidItem', data).then(res => { return res.data })
}

export const viewNftBidDetailsAction = (data) => {
   return postRequest('getBidDetail', data).then(res => { return res.data })
}

export const bidAcceptAction = (data) => {
   return postRequest('bidAccept', data).then(res => { return res.data })
}

export const validatewhitelistedwalletAction = (data) => {
   return postRequest('validatewhitelistedwallet', data).then(res => {return res.data})
}

export const getWhiteListONOFFAction = (data) => {
   return postRequest('getWhiteListONOFF', data).then(res => { return res.data })
}

export const buyCoinsAction = (data) => {
   return postRequest('buyCoins', data).then(res => { return res.data })
}
export const getSinToCoinValueAction = (data) => {
   return postRequest('getSinToCoinValue', data).then(res => { return res.data })
}

export const getMysterycrateListAction = (data) => {
   return postRequest('getMysterycrateList', data).then(res => { return res.data })
}

export const getCityDetailsAction = (data) => {
   return postRequest('getCityDetails', data).then(res => { return res.data })
}

export const getLicenseAction = (data) => {
   return postRequest('getRandomLicense', data).then(res => { return res.data })
}

export const getCityCoffieeshopLicenseAction = (data) => {
   return postRequest('getCityCoffieeShoplicense', data).then(res => { return res.data })
}
export const getCityWeedFarmLicenseAction = (data) => {
   return postRequest('getCityWeedFarmLicense', data).then(res => { return res.data })
}


export const validatelicenseAction = (data) => {
   return postRequest('validatelicense', data).then(res => { return res.data })
}

export const trnasferTokenAPIAction = (data) => {
   return postRequest('trnasferToken', data).then(res => { return res.data })
}

export const MysteryCrateTrnasferAction = (data) => {
   return postRequest('MysteryCrateTrnasfer', data).then(res => { return res.data })
}

export const getUserTokenBuytransactionsAPIAction = (data) => {
   return postRequest('getUserTokenBuytransactions', data).then(res => { return res.data })
}

export const getUserCrateBuytransactionsAction = (data) => {
   return postRequest('getUserCrateBuytransactions', data).then(res => { return res.data })
}

export const buyBNBItemAction = (data) => {
   return postRequest('bnbnftPurchase', data).then(res => { return res.data })
}

export const getNFTMetaDataDetailsAction = (data) => {
   return postRequest('getNFTMetaDataDetails', data).then(res => {return res.data})
}


export const addToCartAction = (data) => {
   return postRequest('addToCart', data).then(res => {return res.data})
}

export const getCartAction = (data) => {
   return postRequest('getCartItems', data).then(res => {return res.data})
}

export const getCartNFTsAction = (data) => {
   return postRequest('getCartNFTs', data).then(res => {return res.data})
}

export const getCartGroupNFTsAction = (data) => {
   return postRequest('getCartGroupNFTs', data).then(res => {return res.data})
}

export const getcartcountAction = (data) => {
   return postRequest('getcartcount', data).then(res => {return res.data})
}

export const ValidateArmorItemAction = (data) => {
   return postRequest('ValidateArmorItem', data).then(res => {return res.data})
}

export const removefromcartAction = (data) => {
   return postRequest('removefromcart', data).then(res => {return res.data})
}
export const getoutofstockCartAction = (data) => {
   return postRequest('getoutofstockCart', data).then(res => {return res.data})
}

export const getContractNFTsAction = (data) => {
   return postRequest('getNFTfromblockchain', data).then(res => { return res.data })
}
export const getCollectedNFTsListAction = (data) => {
   return postRequest('getCollectedNFTsListfromblockchain', data).then(res => { return res.data })
}

export const getContractWalletNFTsAction = (data) => {
   return postRequest('getContractWalletNFTs', data).then(res => { return res.data })
}

export const collectedNFTsCreateAction = (data) => {
   return postRequest('collectedNFTsCreate', data).then(res => { return res.data })
}

export const ImportcollectedNFTsAction = (data) => {
   return postRequest('importcollectedNFT', data).then(res => { return res.data })
}
export const CreateGroupAction = (data) => {
   return postRequest('createGroup', data).then(res => { return res.data })
}

export const getContractNftDetailsAction = (data) => {
   return postRequest('getContractNftDetailsById', data).then(res => {return res.data})
}

export const getlandNFTsAction = (data) => {
   return postRequest('getlandNFTs', data).then(res => {return res.data})
}

export const getHomepageCitiesAction = (data) => {
   return postRequest('getHomepageCities', data).then(res => {return res.data})
}

export const updateNFTpriceAction = (data) => {
   return postRequest('updateNFTprice', data).then(res => {return res.data})
}

export const logMessageAction = (data) => {
   return postRequest('logs', { logData: data }).then(res => {return res.data})
}










// export const getArticlesAction = (data) => {
//    return getRequest('getArticles', data).then(res => { return res.data })
// }

// export const getCountryAction = (data) => {
//    return getRequest('getCountry', data).then(res => { return res.data })
// }

// export const removeFollowingAction = (data) => {
//    return postRequest('removeFollowing', data).then(res => {return res.data})
// }

// export const submitManuscriptAction = (data) => {
//    return postRequestFormData('submitManuscript', data).then(res => {return res.data})
// }

// export const updateManuscriptAction = (data) => {
//    return postRequestFormData('updateManuscript', data).then(res => {return res.data})
// }

// export const updateMetadataAction = (data) => {
//    return postRequest('updateMetadata', data).then(res => {return res.data})
// }

// export const getManuscriptAction = (data) => {
//    return postRequest('getManuscript', data).then(res => { return res.data })
// }

// export const getManuscriptDetailsAction = (data) => {
//    return postRequest('getManuscriptDetails', data).then(res => { return res.data })
// }

// export const getManuscriptByCategoryAction = (data) => {
//    return postRequest('getManuscriptByCategory', data).then(res => { return res.data })
// }

// export const getFollowUsersAction = (data) => {
//    return postRequest('getFollowUsers', data).then(res => { return res.data })
// }

// export const getNftHistoryAPIAction = (data) => {
//    return postRequest('getNftHistory', data).then(res => { return res.data })
// }

// export const getBidsHistoryAPIAction = (data) => {
//    return postRequest('getBidsHistory', data).then(res => { return res.data })
// }



// export const bidPlaceAPIAction = (data) => {
//    return postRequest('insertBid', data).then(res => { return res.data })
// }

// export const likeAPIAction = (data) => {
//    return postRequest('likeItem', data).then(res => { return res.data })
// }

// export const followAPIAction = (data) => {
//    return postRequest('followUser', data).then(res => { return res.data })
// }




// export const getCMSContentAction = (data) => {
//    return getRequest('getCMSContent', data).then(res => { return res.data })
// }

// export const getTermsAndPrivacyContentAction = (data) => {
//    return postRequest('getTermsAndPrivacyContent', data).then(res => { return res.data })
// }

// export const getSocialLinkAction = (data) => {
//    return getRequest('getCMSContent', data).then(res => { return res.data })
// }

// export const getAboutTheEditorsAction = (data) => {
//    return getRequest('getAboutTheEditors', data).then(res => { return res.data })
// }

// export const getCurrentIssueAction = (data) => {
//    return getRequest('getCurrentIssue', data).then(res => { return res.data })
// }

// export const getEditorialBoardAction = (data) => {
//    return getRequest('getEditorialBoard', data).then(res => { return res.data })
// }

// export const getFeaturedManuscriptAction = (data) => {
//    return getRequest('getFeaturedManuscript', data).then(res => { return res.data })
// }

// export const getHomeManuscriptAction = (data) => {
//    return getRequest('getHomeManuscript', data).then(res => { return res.data })
// }

// export const getAcceptRejectManuscriptFeedback = (data) => {
//    return postRequest('getAcceptRejectManuscriptFeedback', data).then(res => { return res.data })
// }

// export const getManuscriptReviewListAction = (data) => {
//    return postRequest('getManuscriptReviewList', data).then(res => { return res.data })
// }

// export const getAutherDataAction = (data) => {
//    return postRequest('getAutherData', data).then(res => { return res.data })
// }

// export const getStatisticsDataAction = (data) => {
//    return postRequest('getStatisticsData', data).then(res => { return res.data })
// }

// export const searchManuscriptAction = (data) => {
//    return postRequest('searchManuscript', data).then(res => { return res.data })
// }

// export const ContactFormAction = (data) => {
//    return postRequest('contactFormRequest', data).then(res => { return res.data })
// }

// export const checkVisitorAction = (data) => {
//    return postRequest('checkVisitor', data).then(res => { return res.data })
// }

// export const getTotalVisitorsAction = (data) => {
//    return getRequest('totalVisitors', data).then(res => { return res.data })
// }
