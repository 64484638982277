import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import config from '../../config';
import Cookies from 'js-cookie';
import { getSettingsAction } from '../../Action/user.action';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));    


const WelcomeSection = props => {
    const data = props.data;
	const [Settings, setSettings] = useState([]);
	
	useEffect(async () => {
        getSettingsAPI();        
    }, []);

    const getSettingsAPI = async () => {
        let res = await getSettingsAction();
        if (res.success) {
            setSettings(res.data);
        }
    }
    
    return (      
			
		<div className="section-sinverse">
		<div className="container">
		<div className="row  align-items-center">

		<div className="col-md-7">
		<div className="sinverse-images">
		<img src="images/Image.png" />
		</div>
		</div>
		<div className="col-md-5">
		<div className="sinverse-heading">
		<h2>{Settings.welcome_title}</h2>
		<p>{Settings.welcome_desc}</p>
		<Link to={`${config.baseUrl}marketplace`} className=""><button className="sinverse-btn">Explore</button></Link>
		</div>
		</div>

		</div>
		</div>
		</div>
		
    );
}

export default WelcomeSection;
