import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import DarkMode from './DarkMode';
import Web3 from 'web3';
import config from '../../config';
import Cookies from 'js-cookie';
import { getcartcountAction } from '../../Action/user.action';
import Dropdown from 'react-bootstrap/Dropdown';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
const AuthData = !Cookies.get('authlogin') ? { authenticated: false } : JSON.parse(Cookies.get('authlogin'));

const Header = (props) => {
	const data = props.data;
	const cartcount = data.cartcount;
    const { pathname } = useLocation();
    const [walletAddress, setwalletAddress] = useState('');  
    const [tokenBalance, settokenBalance] = useState(0);  
    const [isMetamaskConnected, setIsMetamaskConnected] = useState(false);  
    const headerRef = useRef(null)
    
    
    useEffect(() => {
		if(loginData?.email){
			// Check if there's an Ethereum provider (Metamask)
			if (typeof window.ethereum !== 'undefined') {
				connectMetasmask();
				
			  // Initialize Web3 with the current Ethereum provider
			 // const web3 = new Web3(window.ethereum);

			  // Request Metamask to enable its connection
			 // window.ethereum
			//	.request({ method: 'eth_requestAccounts' })
			//	.then(() => {
				  // Metamask is connected
			//	  setIsMetamaskConnected(true);
			//	})
			//	.catch((error) => {
				  // User denied the connection request or an error occurred
			//	  console.error(error);
			//	});
			}
		}
  }, []);
    
    useEffect(() => {
		const pathname = window.location.pathname;			 
		if (!(pathname.toLowerCase().includes('nft-details')) && (pathname != '/') ) {  // remove loader to default settings			
			  localStorage.removeItem('wvisible');
			  localStorage.removeItem('lvisible');
			  localStorage.removeItem('svisible');
			}
		if (!(pathname.toLowerCase().includes('details')) && !(pathname.toLowerCase().includes('portfolio'))) {	 // remove lastvisited page if not portfolio		
			  localStorage.removeItem('lastVisitedPage');			 
			}	
		const swallet = localStorage.getItem('swallet');	
		if (swallet != null && swallet != 'null' && swallet != 'undefined') {	
			//console.log(swallet)		
			if(loginData?.email){
				setwalletAddress(swallet);
				gettokenBalanceAPI(swallet)
				 if (window.ethereum) {
					window.ethereum.on('accountsChanged', function (accounts) {
						 if (accounts) {
							setwalletAddress(accounts[0]);
							localStorage.setItem('swallet', accounts[0]);
							gettokenBalanceAPI(accounts[0]);
							
						}
					})
				}
				console.log(swallet);
			}
		}
		else
		{
			if(loginData?.email){
				 if (window.ethereum) {
					window.ethereum.on('accountsChanged', function (accounts) {
						 if (accounts) {
							setwalletAddress(accounts[0]);
							localStorage.setItem('swallet', accounts[0]);
							gettokenBalanceAPI(accounts[0]);
							
						}
					})
				}			
			}
		}	
			
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };        
        window.scrollTo(0, 0);
    });
    
   
    
    useEffect(async () => {
		getMetamaskVersion(); 
    }, []);
    
    const isSticky = (e) => {
        const header = document.querySelector('.js-header');
        const scrollTop = window.scrollY;
      
        scrollTop >= 100 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
        scrollTop >= 200 ? header.classList.add('is-small') : header.classList.remove('is-small');
    };

    const menuLeft = useRef(null)
    const btnToggle = useRef(null)
    const btnSearch = useRef(null)
    
    async function getMetamaskVersion() {
		if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
		  window.ethereum.send({ method: 'web3_clientVersion' }, (error, response) => {
			if (error) {
			  console.error('Error getting MetaMask version:', error);
			} else {
			  console.log('MetaMask version:', response.result.split('/')[1]);
			}
		  });
		} 

      
    }

    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    }

    const searchBtn = () => {
        btnSearch.current.classList.toggle('active');
    }
    
    const gettokenBalanceAPI = async (wallet) => {
		let web3 = new Web3(window.ethereum);
		let tokenContractAddress = `${config.tokenContract}`;	
		const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
		let getTokenBalance = await tokenContract.methods.balanceOf(wallet.toString()).call();
		var currentBal = parseInt(getTokenBalance) / 10 ** 18;		
		settokenBalance(parseFloat(currentBal).toLocaleString('en-US'))
    }
    
    //~ const getcartcountAPI = async (wallet) => {
        //~ let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        //~ if (res.success) {
            //~ setcartcount(res.data);
        //~ }
    //~ }
    

    const [activeIndex, setActiveIndex] = useState(null);
    const handleOnClick = index => {
        setActiveIndex(index);
    };

    const connectMetasmask = async () => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setwalletAddress(accounts[0]);
            localStorage.setItem('swallet', accounts[0]);
            gettokenBalanceAPI(accounts[0]);
           setIsMetamaskConnected(true);
            
            
        }
    }

    function logout() {
        Cookies.remove('loginSuccessSinverseUserpanel');
        Cookies.remove('token');
        Cookies.remove('authlogin');
        localStorage.removeItem('swallet');
        setTimeout(() => {
            window.location.href = `${config.baseUrl}`
        });
    }
    return (
        
<div id="header_main" className="main-header js-header">
<nav class="navbar navbar-expand-xl">
<div className="container">
	<Link to={`${config.baseUrl}`} rel="home" className="main-logo">
		<img className='logo-dark' id="logo_header" src="images/Logo.png" alt="Sinverse Marketplace" />
	</Link>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav">
      <li className={ pathname === "/" ? "nav-item active": "nav-item"}>       
        <Link to={`${config.baseUrl}`} className="nav-link"><span>Home</span></Link>
      </li>      
       <li className={ pathname === "/marketplace" ? "nav-item active": "nav-item"}>        
        <Link to={`${config.baseUrl}marketplace`} className="nav-link"><span>Marketplace</span></Link>
      </li>
       <li className={ pathname === "/land-marketplace" ? "nav-item active": "nav-item"}>        
        <Link to={`${config.baseUrl}land-marketplace`} className="nav-link"><span>Land</span></Link>
      </li>
       <li className={ pathname === "/licenses-cities" ? "nav-item active": "nav-item"}>        
        <Link to={`${config.baseUrl}licenses-cities`} className="nav-link"><span>Licenses</span></Link>
      </li>
       <li className={ pathname === "/othernfts" ? "nav-item active": "nav-item"}>
        <Link to={`${config.baseUrl}othernfts`} className="nav-link"><span>Other nfts</span></Link>
      </li>
      <li className={ pathname === "/gold-coins" ? "nav-item active": "nav-item"}>
        <Link to={`${config.baseUrl}gold-coins`} className="nav-link"><span>Gold Coins</span></Link>
      </li>      
    </ul>
    
    {loginData?.email ?
		"" :
		<>
		 <div className="header-btn my-lg-0">  
		 <Link to={`${config.baseUrl}cart`} className="marketplace header-cart"> <i class="fa fa-shopping-cart"></i> <span>{cartcount}</span></Link>		
		 <Link to={`${config.baseUrl}sign-up`} className="btn-Register-my-sm-0"><span>Register</span></Link>
		 <Link to={`${config.baseUrl}login`} className="btn-Login-my-sm-0"><span>Login</span></Link> 
		
		 </div>
		< />
	}
   
    
     {loginData?.email ?
					<>
					 <div className="profile-header-btn my-lg-0">
					  <Link to={`${config.baseUrl}cart`} className="marketplace header-cart"> <i class="fa fa-shopping-cart"></i> <span>{cartcount}</span></Link>					  
					{loginData?.email ?
							walletAddress ?
								<a target="_blank" href={`${config.blockchainUrl + walletAddress}`} className="btn-Register-my-sm-0"><i class="fa fa-wallet"></i>&nbsp;<span> {walletAddress.toString().substring(0, 5) + '...' + walletAddress.toString().substring(walletAddress.length - 5)} ({tokenBalance} SIN)
									</span></a>								
								:
								<Link href="javascript:void(0)" className="btn-Register-my-sm-0"><i class="fa fa-wallet"></i>&nbsp; <span onClick={connectMetasmask}>My Wallet</span></Link>								
							: ""
						}	
						<Dropdown className='profiledropdown nav-link text-right'>
							<Dropdown.Toggle variant="" id="dropdown-basic" >
								{loginData?.profile_pic ?
									<>
									<img src={config.imageUrl + loginData?.profile_pic} alt="User Profile" className="header-user-img" />
									&nbsp; {loginData?.name.toString().substring(0, 15)}{loginData.name.length > 15 ? '...':''}
									</>
									:
									<>
									<img src="images/default-user-icon.jpg" alt="User Profile" className="header-user-img"/>
									&nbsp; {loginData?.name}
									</>
								}
										
										
							</Dropdown.Toggle>

							<Dropdown.Menu className=''>
								<Dropdown.Item href={`${config.baseUrl}profile`}> My Account</Dropdown.Item>					
							  	<Dropdown.Item href={`${config.baseUrl}portfolio-weapon`}>Portfolio</Dropdown.Item>
								<Dropdown.Item href={`${config.baseUrl}collectedNFTs`}>Collected NFTs</Dropdown.Item>																		
								<Dropdown.Item href={`${config.baseUrl}transactions-list`}>Transactions List</Dropdown.Item>
								<Dropdown.Item href={`${config.baseUrl}gold-coins`}>Gold Coins ({parseInt(loginData.credits).toLocaleString('en-US')} )</Dropdown.Item>
								<Dropdown.Item onClick={logout} >Logout</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						</div>
					</>
					:
					""
				}

  </div>
  </div>
</nav>
</div>

    );
}

export default Header;
