import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Cookies from 'js-cookie';
import { termsConditionAction, getcartcountAction } from '../Action/user.action';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
const TermsCondition = () => {
    const [termsCondition, settermsCondition] = useState({});
	const [cartcount, setcartcount] = useState(0);
    useEffect(() => {
        termsConditionAPI()
         if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            }
    }, []);

    const termsConditionAPI = async () => {
        let res = await termsConditionAction();
        if (res.success) {
            settermsCondition(res.data);
        }
    }
    const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }

    return (
        <div>           
            <Header data={{'cartcount':cartcount}} />
            <div class="about-section">
			<div class="container">
				<div class="about-us-heading">
					<h1>Terms And Condition</h1>   
			</div>
			</div>
			</div>
           
            <section className="tf-login tf-section">
                <div className="cart-container">
                    <div className="row">            
                        <div className="col-lg-12">
                        <div className="content-text"
                                    dangerouslySetInnerHTML={{ __html: termsCondition?.terms_conditions }}
                                />
                        
                        </div>
                       
                        
                        
                        
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default TermsCondition;
