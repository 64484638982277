import React, { useState } from 'react';
import Cookies from 'js-cookie';
import toast, { Toaster } from 'react-hot-toast';
import config from '../config';
const AuthLogin = () => {
  const [password, setPassword] = useState('');
   const [isLoading, setisLoading] = useState(false);

  const handleSubmit = (e) => {
	  setisLoading(true);
	if (password == "Sinverse#@!2023") {
		toast.success("You are verified.");
		Cookies.set('authlogin', JSON.stringify({'Authenticated': true}));
		
		  setTimeout(() => {
               //window.location.href = `https://marketplace.sinverse.com/`
               window.location.href = `http://localhost:3000/`
            }, 2000);
    }
    else
    { 
		console.log(1);
		Cookies.set('authlogin', JSON.stringify({'Authenticated': false}));	
		toast.error('Restricted Access !!!!!!!');
		 setisLoading(false);
		 return false;
    }
    
  };

  return (
	<div>
	<Toaster />
    <div className="auth-login">
      <h1>Login Authentication</h1>
      <form onSubmit={handleSubmit}>
		<label>Enter Password</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
         {isLoading ?
			<button disabled className="login-btn">Processing &nbsp; <i className="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></button>
			:
			<button className="login-btn">Login</button>
		}
        
      </form>
    </div>
    </div>
  );
};

export default AuthLogin;
