import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom'
import { cancelOrderAction, putOnSaleAction, getCityAction, getContractNFTsAction, getCategoryAction, collectedNFTsCreateAction, getglobalroyalityAction, CreateGroupAction, getCollectedNFTsListAction, getcartcountAction, getlandroadsAction,logMessageAction,ImportcollectedNFTsAction } from '../Action/user.action';
import config from '../config';
import toast, { Toaster } from 'react-hot-toast';
import Web3 from 'web3';
import Cookies from 'js-cookie';
import $ from 'jquery';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zindex:'99999999999999999999'
    },
};

const CollectedNFTs = () => {
    let subtitle;
    const [Category, setCategory] = useState([]);
    const [NFTList, setNFTList] = useState([]);
    const [FilterNFTList, setFilterNFTList] = useState([]);
    const [walletNFTList, setWalletNFTList] = useState([]);
    const [isPutonsale, setisPutonsale] = useState(0);
    const [isImportItem, setisImportItem] = useState(0);
    const [isCreatGroup, setCreatGroup] = useState(0);  
    const [isimportNFT, setisImportNFT] = useState(0);  
    const [walletAddress, setwalletAddress] = useState('');
    const [globalroyality, setglobalroyality] = useState('');
    const [spinLoader, setSpinLoader] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [blockchainUpdationType, setblockchainUpdationType] = useState(0);
    const [validatioError, setvalidatioError] = useState({});
    const [currentDate, setcurrentDate] = useState(new Date());
    const [isLoader, setisLoader] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [textboxes, setTextboxes] = useState([]);
    const [island, setisland] = useState(1);
    const [islicense, setislicense] = useState(0);
    const [landLoader, setlandLoader] = useState(0);
    const [licenseLoader, setlicenseLoader] = useState(0);
    const [SearchLFilter, setSearchLFilter] = useState('');
    const [SearchLicFilter, setSearchLicFilter] = useState('');
    const [cartcount, setcartcount] = useState(0);
    const [Cities, setCities] = useState([]);
    const [CityIdsFilters, setCitiesIdsFilters] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [modalopen, setModalopen] = useState(0);
    const [Landroads, setLandRoads] = useState([]);


    const [itemDetails, setItemDetails] = useState({
        title: '',
        token_id: '',
        image: '',
        description: '',
        category_id: '0',
        royalty_percentage: '0',
        price: '0',
        sell_type: "1",
        start_date: null,
        expiry_date: null,
        contractAddress: '',
        wallet_address: '',
        tokenUri: '',
        group_id: '0',
        contract_type:'',
    });
    
    const [groupDetails, setGroupNFT] = useState({
        title: '',
        description: '',
        price: '0',
        is_on_sale: '',
        created_by: '0',
        owner_id: '0',
        is_sold: '0',
        owner_address: '',       
        contractAddress: ''      
    });
    
    const addTextbox = () => {
		setTextboxes([...textboxes, '']);
	  };

	const removeTextbox = (index) => {
		const updatedTextboxes = [...textboxes];
		updatedTextboxes.splice(index, 1);
		setTextboxes(updatedTextboxes);
	};

	const handleTextboxChange = (index, value) => {
		const updatedTextboxes = [...textboxes];
		updatedTextboxes[index] = value;
		setTextboxes(updatedTextboxes);
	  };
  
    const handleCheckboxChange = (e, item, itemId, contractAddress) => {
		
		if (selectedContract != null)
		{ 
			if(selectedContract.toLowerCase() !== contractAddress.toLowerCase()) {
			  // Warn the user about selecting items from different groups
			  toast.error('Please select items from the same contract');
			   e.preventDefault()
			  return;
			}
		}

    let updatedSelection;

    if (selectedItems.some((item) => item.token_id === itemId && item.contractAddress === contractAddress)) {
      // If item is already selected, remove it from the selection
      updatedSelection = selectedItems.filter(
        (item) => item.token_id !== itemId || item.contractAddress !== contractAddress
      );
      removeTextbox(selectedItems.length-1)
    } else {
      // If item is not selected, add it to the selection
      updatedSelection = [...selectedItems, { title: item.name, image: item.tokenUri, description: item.metadata?.description ? item.metadata?.description : '',	token_id: item.tokenId,	contractAddress: item.contractAddress, category_id: '0', royalty_percentage: globalroyality, price: '0', sell_type: "1", start_date: null,	expiry_date: null, wallet_address: '',	group_id: '0',	contract_type: item.contract_type, }];
      
      addTextbox()
    }
    
    //console.log(updatedSelection)
    setSelectedItems(updatedSelection);
    setSelectedContract(contractAddress);
  }; 
	
    const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));

    useEffect(async () => {

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                setNFTList([]);
                setisLoader(1)
                if (accounts) {
                    getContractNFTsAPI(accounts[0]);
                    setwalletAddress(accounts[0]);
                     getcartcountAPI(accounts[0]);
                }
            })
        }

        setTimeout(() => {
            if (window.ethereum) {
                const { ethereum } = window;
                getContractNFTsAPI(ethereum.selectedAddress);
                 setwalletAddress(ethereum.selectedAddress);
                  getcartcountAPI(ethereum.selectedAddress);
            } else {
                getContractNFTsAPI('');
            }
        }, 100);

        // setTimeout(async () => {
        //     if (window.ethereum) {
        //         const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        //         if (accounts) {
        //             getContractNFTsAPI(accounts[0]);
        //         }
        //     }
        // }, 200);
		getCitiesAPI();
        getCategoryAPI();
          getLandRoadsAPI();
        getglobalroyalityAPI();
        if(selectedItems.length==0)
		{
			updateSelectedContract();
		}
    }, [selectedItems]);

    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setItemDetails({ ...itemDetails, [name]: value })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }
    
    const inputImportPriceHandler = (e) => {
        const { name, value, id } = e.target
        setItemDetails({ ...itemDetails, [name]: value })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }

    const groupinputHandler = (e) => {
        const { name, value, id } = e.target
        setGroupNFT({ ...groupDetails, [name]: value })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }
    
    const updateSelectedContract = () => {
		setSelectedContract(null);
	};

    function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const getCategoryAPI = async () => {
        let res = await getCategoryAction();
        if (res.success) {
            setCategory(res.data)
        }
    }
    
    const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
   
    const getglobalroyalityAPI = async () => {
        let res = await getglobalroyalityAction();
        if (res.success) {
            setglobalroyality(res.data)
        }
    }

    const handleChangeStartDate = e => {
        let startDate = formatDate(e);
        setItemDetails({ ...itemDetails, ['start_date']: startDate });
        setvalidatioError({ ...validatioError, ['startDateError']: '' });
    }

    const handleChangeExpiryDate = e => {
        let expiryDate = formatDate(e);
        setItemDetails({ ...itemDetails, ['expiry_date']: expiryDate });
        setvalidatioError({ ...validatioError, ['expiryDateError']: '' });
    }

    function afterOpenModal() {
        //subtitle.style.color = '#f00';
    }
	
	const getCitiesAPI = async () => {
        let res = await getCityAction();
        if (res.success) {
            setCities(res.data)
        }
    }
    
    const getContractNFTsAPI = async (walletAddress) => {
		if(walletAddress=='')
		{
				setNFTList([]);
				
		}
		else
		{
			//walletAddress ="0x58136EB0C230c70a5e03D708b3bF7787a9DD738D";
			let res = await getCollectedNFTsListAction({ 'wallet_address': walletAddress,'nft_type' :config.LAND  });
			setisLoader(0);
			if (res.success) {
				setNFTList(res.data);
				setFilterNFTList(res.data)
				
			}
        }
    }

    const putOnSaleModelAPI = async (item) => {
		setisPutonsale(1);
		console.log(item)		
			setItemDetails({
				title: item.name ? item.name : '',
				image: item.tokenUri,
				// title: item.metadata?.name,
				// image: item.metadata?.image,
				description: item.metadata?.description ? item.metadata?.description : '',
				token_id: item.tokenId,
				contractAddress: item.contractAddress,
				category_id: '0',
				royalty_percentage: globalroyality,
				price: '0',
				sell_type: "1",
				start_date: null,
				expiry_date: null,
				wallet_address: '',
				group_id: '0',
				contract_type: item.contract_type,
			});
		
    }
    
    const importtoPortfolioAPI = async (item) => {
		setCreatGroup(2);
		setisPutonsale(1);			
			setItemDetails({
				title: item.name ? item.name : '',
				image: item.tokenUri,				
				description: item.metadata?.description ? item.metadata?.description : '',
				token_id: item.tokenId,
				contractAddress: item.contractAddress,
				category_id: '0',
				royalty_percentage: globalroyality,
				price: '0',
				sell_type: "1",
				start_date: null,
				expiry_date: null,
				wallet_address: walletAddress,
				group_id: '0',
				contract_type: item.contract_type,
			});		
	}
 
    const ImportNFTToPortfolio = async (item) => {
		const isValid = validateImportNFT();
        if (!isValid) {

        }
        else
        {
			setisPutonsale(0);
			setDialogOpen(true);
			setblockchainUpdationType(3)
			setSpinLoader(1);
			let res = await ImportcollectedNFTsAction(itemDetails);
			if (res.success) {
				toast.success(res.msg);
				 if(itemDetails.contract_type == config.CONTRACT_TYPE_LICENSE)
				{
					 setTimeout(() => {
						window.location.href = `${config.baseUrl}portfolio-license`;
					}, 2000);
				}
				else
				{
					 setTimeout(() => {
						window.location.href = `${config.baseUrl}portfolio-land`;
					}, 2000);
				}
			}
			else
			{
				toast.error(res.msg);
				setSpinLoader(0);
				setDialogOpen(false);
				return false;
			}
		}
	}
    
    const approveGroupNFT = async (itemDetails,index,end) => {
		console.log(itemDetails)
				setSpinLoader(1);
                setisPutonsale(0);
			if (window.ethereum) {
            
                let web3 = '';
                web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();

                let walletAdd = accounts[0];
                if (!walletAdd) {
                    toast.error('Please connect your metamask wallet.');
                    return;
                }

                let from_address = accounts[0];
                console.log(from_address);
                var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);
                 console.log(getBalace);
                var currentBal = parseFloat(getBalace).toFixed(6)
                console.log(currentBal);
                if (currentBal == 0) {
                    toast.error(`Insufficient fund!!`);
                    return false;
                }

                let currentNetwork = await web3.currentProvider.chainId;
                console.log(currentNetwork);
                web3.eth.defaultAccount = accounts[0];
                let chainId = config.chainId;
                console.log(chainId);
                if (currentNetwork !== chainId) {
                    toast.error('Please select BNB smartchain!!');
                    return false;
                }

                //  Approve transaction
                const contractForApprove = await new web3.eth.Contract(config.nftContractABI, itemDetails?.contractAddress);
                let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();
				console.log(isApproved)
                if (isApproved == false) {
					try {
						let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);

						setSpinLoader(1);
						setDialogOpen(true);

						let encodedTxForToken = tx_builderForToken.encodeABI();
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});	
						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
					}
					catch (err) {
						console.log('error',err);
						if (err.message.toString().split('insufficient funds')[1]) {
							toast.error('Transaction reverted : ' + err.message)
						} else {
							if (err.toString().split('execution reverted:')[1]) {
								toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

							} else {
								if (err.toString().split('execution reverted:')[1]) {
									toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
								} else {
									toast.error(err.message);
								}
							}
						}

						setSpinLoader(0);
						setisPutonsale(0);
						setDialogOpen(false);
						setTimeout(() => {
							window.location.href = `${config.baseUrl}collectedNFTs`;
						}, 3000);
					}
                }

                setSpinLoader(1);
                setisPutonsale(0);
                setDialogOpen(true);
                try {
                    let mintFee = 0;
                    let SalePrice;
                    let start_date = 0;
                    let expiry_date = 0;
                    let tokenId = itemDetails.token_id; 
                    let Price = itemDetails.price;                  
                    let royalty_percent = parseInt(parseInt(itemDetails?.royalty_percentage) * 100);
					
                    if (itemDetails.sell_type == 1) {   
                         SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');                       
                    }

                    else if (itemDetails.sell_type == 2) {
                        SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');       
                        start_date = Math.round(new Date(itemDetails.start_date).getTime() / 1000);
                        expiry_date = Math.round(new Date(itemDetails.expiry_date).getTime() / 1000);
                    }

                    let contractAddress = `${config.mainMarketplaceContract}`
                    const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);
					console.log('SalePrice-',SalePrice)
                    setblockchainUpdationType(1)
                    await contract.methods.putonSale(
                        tokenId.toString(),
                        SalePrice.toString(),
                        itemDetails?.contractAddress.toString(),
                        itemDetails.sell_type.toString(),
                        start_date.toString(),
                        expiry_date.toString(),
                        royalty_percent.toString()
                    ).call();

                    var tx_builder = await contract.methods.putonSale(
                        tokenId.toString(),
                        SalePrice.toString(),
                        itemDetails?.contractAddress.toString(),
                        itemDetails.sell_type.toString(),
                        start_date.toString(),
                        expiry_date.toString(),
                        royalty_percent.toString()
                    );
                    let encoded_tx = tx_builder.encodeABI();
                    let gasPrice = await web3.eth.getGasPrice();
                    gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                    let gasLimit = await web3.eth.estimateGas({
                        gasPrice: web3.utils.toHex(gasPrice),
                        to: contractAddress,
                        from: from_address,
                        value: web3.utils.toHex(mintFee),
                        chainId: chainId,
                        data: encoded_tx
                    });

                    const txData = await web3.eth.sendTransaction({
                        gasPrice: web3.utils.toHex(gasPrice),
                        gas: web3.utils.toHex(gasLimit),
                        to: contractAddress,
                        from: from_address,
                        value: web3.utils.toHex(mintFee),
                        chainId: chainId,
                        data: encoded_tx
                    });
                    if (txData.transactionHash) {

                        itemDetails.wallet_address = from_address;
                        itemDetails.token_hash = txData.transactionHash;
                        let res = await collectedNFTsCreateAction(itemDetails);
                        if (res.success) {
							console.log(index,'-',end)
							if(index==end)
							{
								toast.success(res.msg);
								setTimeout(() => {
										window.location.href = `${config.baseUrl}portfolio-land`;
									}, 2000);
								
							}
                           
                        } else {
                            toast.error(res.msg);
                            setSpinLoader(0);
							setisPutonsale(0);							
							setCreatGroup(0);
							setDialogOpen(false);
							
                        }
                    } else {
                        toast.error('Something went wrong please try again.');
                        setSpinLoader(0);
                         setisPutonsale(0);
						setCreatGroup(0);
                        setDialogOpen(false);                       
                        return false;
                    }

                } catch (err) {
                    console.log('error',err);
                    if (err.message.toString().split('insufficient funds')[1]) {
                        toast.error('Transaction reverted : ' + err.message)
                    } else {
                        if (err.toString().split('execution reverted:')[1]) {
                            toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                        } else {
                            if (err.toString().split('execution reverted:')[1]) {
                                toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                            } else {
                                toast.error(err.message);
                            }
                        }
                    }

                    setSpinLoader(0);
                    setisPutonsale(0);
					setCreatGroup(0);
                    setDialogOpen(false);                   
                    setTimeout(() => {
							window.location.href = `${config.baseUrl}collectedNFTs`;
						}, 3000);
                }
            } else {
                toast.error('Please connect your metamask wallet.');
                setSpinLoader(0);
                setisPutonsale(0);
                setCreatGroup(0);
                setDialogOpen(false);                
                return false;
            }
        	
    }

    const CreateGroupModelAPI = async () => {
		groupDetails.contractAddress 	= selectedContract;
		groupDetails.owner_address 		= walletAddress;
		groupDetails.created_by 		= loginData.id;
        setCreatGroup(1);
        setisPutonsale(1);
        console.log('CreateGroupModelAPI called',groupDetails)
    }
  
    const closeModel = async () => {
        setisPutonsale(0);
        setCreatGroup(0);
        setDialogOpen(false);
		setblockchainUpdationType(0)
		setSpinLoader(0);
    }
 
    const handleOtherContractClick = () => {	
		 toast.error('Please select land from the same smart contract');
	  };

    function validate() {
        let titleError = "";
        let descriptionError = "";
        let categoryError = "";
        let priceError = "";
        let startDateError = "";
        let expiryDateError = "";
        if (itemDetails.title === '') {
            titleError = "Title field is required."
        }       
        if(itemDetails.contract_type == config.CONTRACT_TYPE_WEAPON)
        {
			if (itemDetails.category_id == 0) {
				categoryError = "Category field is required."
			}
        }
        
        const isValidValue = itemDetails.price === '' || (Number(itemDetails.price) > 0 && !isNaN(itemDetails.price));
        
		if (isValidValue === false || itemDetails.price=='' ) {
            priceError = "Price field is required."
        }       
        if (titleError ||  categoryError || priceError || startDateError || expiryDateError) {
            setvalidatioError({
                titleError, categoryError, priceError, startDateError, expiryDateError
            })
            return false
        } else {
            return true
        }
    }
	
    function validateImportNFT() {
       let priceError = "";       
       const isValidValue = itemDetails.price === '' || (Number(itemDetails.price) > 0 && !isNaN(itemDetails.price));
        
		if (isValidValue === false || itemDetails.price=='' ) {
            priceError = "Price field is required."
        }       
        if (priceError) {
            setvalidatioError({
               priceError
            })
            return false
        } else {
            return true
        }
    }
	
    function validateGroup() {	
        let grouptitleError 		= "";
        let groupdescriptionError 	= "";       
        let grouppriceError 		= ""; 
        let price					= 0; 
        let flag					= true;
        textboxes.forEach((textbox) => {
		  price = price + parseInt(textbox);
		  flag = textbox === '' || (Number(textbox) > 0 && !isNaN(textbox));
		});
        groupDetails.price = price;
             
        if (groupDetails.title === '') {
            grouptitleError = "Title field is required."
        }
        if (groupDetails.description === '') {
            groupdescriptionError = "Description field is required."
        }        
        if (groupDetails.price == 0) {
            grouppriceError = "Price field is required."
        } 
        if (flag === false ) {
            grouppriceError = "Please enter valid price."
        }      
        if (grouptitleError || groupdescriptionError || grouppriceError) {
            setvalidatioError({
                grouptitleError, groupdescriptionError, grouppriceError})
            return false
        } else {
            return true
        }
    }
	
    const approveNFT = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {
			logMessageAction('collected- nft-section')
            if (window.ethereum) {
            
                let web3 = '';
                web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();

                let walletAdd = accounts[0];
                if (!walletAdd) {
                    toast.error('Please connect your metamask wallet.');
                    return;
                }

                let from_address = accounts[0];
                console.log(from_address);
                logMessageAction('collected- wallet'+from_address)
                var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);
                 console.log(getBalace);
                
                var currentBal = parseFloat(getBalace).toFixed(6)
                console.log(currentBal);
                 logMessageAction('collected- currentBal '+currentBal) 
                if (currentBal == 0) {
                    toast.error(`Insufficient fund!!`);
                    return false;
                }

                let currentNetwork = await web3.currentProvider.chainId;
                console.log(currentNetwork);
                 logMessageAction('collected- currentNetwork'+currentNetwork) 
                web3.eth.defaultAccount = accounts[0];
                let chainId = config.chainId;
                console.log(chainId);
                if (currentNetwork !== chainId) {
                    toast.error('Please select BNB smartchain!!');
                    return false;
                }

                //  Approve transaction
                const contractForApprove = await new web3.eth.Contract(config.nftContractABI, itemDetails?.contractAddress);
                let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();
				console.log(isApproved)
				logMessageAction('isApproved-'+isApproved)
                if (isApproved == false) {
					try {
						let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);

						setSpinLoader(1);
						setDialogOpen(true);

						let encodedTxForToken = tx_builderForToken.encodeABI();
						logMessageAction('encodedTxForToken-'+encodedTxForToken);
						
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});	
						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
					}
					catch (err) {
						logMessageAction('error-'+err.message)
						console.log('error',err);
						if (err.message.toString().split('insufficient funds')[1]) {
							toast.error('Transaction reverted : ' + err.message)
						} else {
							if (err.toString().split('execution reverted:')[1]) {
								toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

							} else {
								if (err.toString().split('execution reverted:')[1]) {
									toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
								} else {
									toast.error(err.message);
								}
							}
						}

						setSpinLoader(0);
						setisPutonsale(0);
						setDialogOpen(false);
						setTimeout(() => {
							window.location.href = `${config.baseUrl}collectedNFTs`;
						}, 3000);
					}
                }

                setSpinLoader(1);
                setisPutonsale(0);
                setDialogOpen(true);
                try {
                    let mintFee = 0;
                    let SalePrice;
                    let start_date = 0;
                    let expiry_date = 0;
                    let tokenId = itemDetails.token_id; 
                    let Price = itemDetails.price;  
                    logMessageAction('toke_id-'+tokenId)                
                    logMessageAction('Price-'+Price)                
                    let royalty_percent = parseInt(parseInt(itemDetails?.royalty_percentage) * 100);
					
                    if (itemDetails.sell_type == 1) {   
                         SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');                       
                    }

                    else if (itemDetails.sell_type == 2) {
                        SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');       
                        start_date = Math.round(new Date(itemDetails.start_date).getTime() / 1000);
                        expiry_date = Math.round(new Date(itemDetails.expiry_date).getTime() / 1000);
                    }

                    let contractAddress = `${config.mainMarketplaceContract}`
                    const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);
					console.log('SalePrice-',SalePrice)
					logMessageAction('SalePrice-'+SalePrice)
                    setblockchainUpdationType(1)
                    await contract.methods.putonSale(
                        tokenId.toString(),
                        SalePrice.toString(),
                        itemDetails?.contractAddress.toString(),
                        itemDetails.sell_type.toString(),
                        start_date.toString(),
                        expiry_date.toString(),
                        royalty_percent.toString()
                    ).call();

                    var tx_builder = await contract.methods.putonSale(
                        tokenId.toString(),
                        SalePrice.toString(),
                        itemDetails?.contractAddress.toString(),
                        itemDetails.sell_type.toString(),
                        start_date.toString(),
                        expiry_date.toString(),
                        royalty_percent.toString()
                    );
                    let encoded_tx = tx_builder.encodeABI();
                    let gasPrice = await web3.eth.getGasPrice();
                    gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                    let gasLimit = await web3.eth.estimateGas({
                        gasPrice: web3.utils.toHex(gasPrice),
                        to: contractAddress,
                        from: from_address,
                        value: web3.utils.toHex(mintFee),
                        chainId: chainId,
                        data: encoded_tx
                    });

                    const txData = await web3.eth.sendTransaction({
                        gasPrice: web3.utils.toHex(gasPrice),
                        gas: web3.utils.toHex(gasLimit),
                        to: contractAddress,
                        from: from_address,
                        value: web3.utils.toHex(mintFee),
                        chainId: chainId,
                        data: encoded_tx
                    });
                    if (txData.transactionHash) {

                        itemDetails.wallet_address = from_address;
                        itemDetails.token_hash = txData.transactionHash;
                        let res = await collectedNFTsCreateAction(itemDetails);
                        logMessageAction('success itemDetails-'+itemDetails)
                        logMessageAction('res-'+res)
                        if (res.success) {
                            toast.success(res.msg);
                             if(itemDetails.contract_type == config.CONTRACT_TYPE_LICENSE)
							{
								 setTimeout(() => {
									window.location.href = `${config.baseUrl}portfolio-license`;
								}, 2000);
							}
							else
							{
								 setTimeout(() => {
									window.location.href = `${config.baseUrl}portfolio-land`;
								}, 2000);
							}
                           
                        } else {
                            toast.error(res.msg);
                        }
                    } else {
                        toast.error('Something went wrong please try again.');
                        setSpinLoader(0);
                        setisPutonsale(0);
                        setDialogOpen(false);
                        return false;
                    }

                } catch (err) {
					logMessageAction(err)
                    console.log('error',err);
                    if (err.message.toString().split('insufficient funds')[1]) {
                        toast.error('Transaction reverted : ' + err.message)
                    } else {
                        if (err.toString().split('execution reverted:')[1]) {
                            toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                        } else {
                            if (err.toString().split('execution reverted:')[1]) {
                                toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                            } else {
                                toast.error(err.message);
                            }
                        }
                    }

                    setSpinLoader(0);
                    setisPutonsale(0);
                    setDialogOpen(false);
                    return false;
                }
            } else {
                toast.error('Please connect your metamask wallet.');
                setSpinLoader(0);
                setisPutonsale(0);
                setDialogOpen(false);
                return false;
            }
        }
    console.log(e);
    logMessageAction(e);
    }

    const CreateGroupNFT = async (e) => {
        e.preventDefault()
        const isValid = validateGroup();
        if (!isValid) {

        }
        else if(selectedItems.length ==0)
        {
			 toast.error('Please select items');
		}
        else {
				 setisPutonsale(0); 
			 let res = await CreateGroupAction(groupDetails);
			 if (res.success) {								 				  
				 let grouplength    	= selectedItems.length-1;	
				 for(let i=0;i<selectedItems.length;i++)
					{						
						selectedItems[i].price = textboxes[i];
						selectedItems[i].group_id = res.data
						await approveGroupNFT(selectedItems[i],i,grouplength)										
					}
			
			}
		}
        
    }
	
	const showland = async (item) => {
		setSearchLFilter('')
		setCitiesIdsFilters([])
		getCollectedLandListAPI(walletAddress)
        setisland(1); 
        setislicense(0);
    }
 
    const showlicense = async (item) => {
		setSearchLicFilter('')
		getCollectedLicenseListAPI(walletAddress);              
        setislicense(1);
        setisland(0); 
    }
 
    const getCollectedLicenseListAPI = async (walletAddress) => {
		setisLoader(1);		
		//walletAddress ="0x58136EB0C230c70a5e03D708b3bF7787a9DD738D";		
		let res = await getCollectedNFTsListAction({'wallet_address' : walletAddress,'nft_type' :config.WEED });
        if (res.success) {
           setNFTList(res.data);
           setFilterNFTList(res.data)
           setisLoader(0);
            
        }
        
    }
 
    const getCollectedLandListAPI = async (walletAddress) => {		 
		 setisLoader(1);		
		let res = await getCollectedNFTsListAction({'wallet_address' : walletAddress,'nft_type' :config.LAND });
		if (res.success) {
			setNFTList(res.data);
			setFilterNFTList(res.data)
			setisLoader(0);			
		}
		
    }
  
    const SetLNameFilter = async (e) => {
	  setisLoader(1);
	  setSearchLFilter(e.target.value);   
	  const searchText = e.target.value.toLowerCase();
	  if(searchText != null){
		const result = NFTList.filter(data => data.name.toLowerCase().includes(searchText) || data.tokenId.includes(searchText)); 
		if(CityIdsFilters.length > 0){
			let newresult = result.filter(data => CityIdsFilters.includes(parseInt(data.city_id))); 
			setFilterNFTList(newresult); 
			setisLoader(0);
		}
		else{
			setFilterNFTList(result); 
			setisLoader(0);
		}		   
		
	  }else{
		 if(CityIdsFilters.length > 0){
			let newresult = NFTList.filter(data => CityIdsFilters.includes(parseInt(data.city_id))); 
			setFilterNFTList(newresult); 
			setisLoader(0);
		}
		else
		{
			setFilterNFTList(NFTList); 
		}
	  }
	 
	}
    
    const handleLKeyPress = async (event) => {
		if(event.key === "Enter"){  
			setisLoader(1);
			if(SearchLFilter != null){				  
				const result = NFTList.filter(data => data.name.toLowerCase().includes(SearchLFilter) || data.tokenId.includes(SearchLFilter));   
				 if(CityIdsFilters.length > 0){
					let newresult = result.filter(data => CityIdsFilters.includes(parseInt(data.city_id))); 
					setFilterNFTList(newresult); 
					setisLoader(0);
				 }
				 else{
					 
					 setFilterNFTList(result); 
					setisLoader(0);
				 }
				
			  }else{	
				  if(CityIdsFilters.length > 0){
					let newresult = NFTList.filter(data => CityIdsFilters.includes(parseInt(data.city_id))); 
					setFilterNFTList(newresult); 
					setisLoader(0);  
				  }	
				  else
				  {
					setFilterNFTList(NFTList);  
				  }		 
				
			}
		}
		
	}
	
	const CityFilter = async () => {	
		 let cityIds = []       
        $("input[name='city_id[]']:checked").each(function () {
            cityIds.push(parseInt($(this).val()));
        });

        const catidsArray = cityIds.filter(function (value) {
            return !Number.isNaN(value);
        });
		setCitiesIdsFilters(catidsArray); 
		
      if(catidsArray.length > 0){	
		  console.log(catidsArray)	 
		let result = NFTList.filter(data => catidsArray.includes(parseInt(data.city_id)));
		console.log(result)
		if(SearchLFilter != null){
			 let newresult = result.filter(data => data.name.toLowerCase().includes(SearchLFilter) || data.tokenId.includes(SearchLFilter)); 
			 setFilterNFTList(newresult); 
			setisLoader(0);
		}
		else
		{
		setFilterNFTList(result); 
		setisLoader(0);	
		}
	  }else{		
			if(SearchLFilter != null){
				const result = NFTList.filter(data => data.name.toLowerCase().includes(SearchLFilter) || data.tokenId.includes(SearchLFilter));   
					setFilterNFTList(result); 
					setisLoader(0);
			  }else{
				  setFilterNFTList(NFTList); 
			} 
	  }
        
    }   
	
	const SetLicNameFilter = async (e) => {
	  setisLoader(1);
	  setSearchLicFilter(e.target.value);   
	  const searchText = e.target.value.toLowerCase();
	  if(searchText != null){
		const result = NFTList.filter(data => data.city_name.toLowerCase().includes(searchText) || data.name.toLowerCase().includes(searchText) || data.tokenId.includes(searchText) ); 		
			setFilterNFTList(result); 
			setisLoader(0);
	}else{
		 setFilterNFTList(NFTList);		
	  }
	 
	}
    
    const handleLicKeyPress = async (event) => {
		if(event.key === "Enter"){  
			setisLoader(1);
			if(SearchLicFilter != null){				  
				const result = NFTList.filter(data => data.city_name.toLowerCase().includes(SearchLFilter) || data.name.toLowerCase().includes(SearchLFilter) || data.tokenId.includes(SearchLFilter));   
					setFilterNFTList(result); 
					setisLoader(0);
				}else{	
				  setFilterNFTList(NFTList);  
				 }
		}
		
	}
   
    const getLandRoadsAPI = async () => {
        let res = await getlandroadsAction();
        if (res.success) {
           setLandRoads(res.data);            
        }
        else
        {
			setLandRoads([])
		}
    }

    const closebutton = async () => {
        setModalopen(0);
    }

	const ShowPlotDetails = (cell_number) => {
		let cell			= cell_number-1; 
		console.log(cell_number)
		var Owner_Plots		= [];
		const size 			= 274;
		const hsize 		= 149;		
		const canvas 		= document.getElementById('myCanvas');
		canvas.width 		= 1249;
		canvas.height 		= 688;
		const ctx 			= canvas.getContext("2d");
		const tileWSize 	= canvas.width / size;
		const tileHSize 	= canvas.height / hsize;
		let lastTile 		= -1;
		for (let y = 0; y < canvas.height / tileHSize; y++) {
			for (let x = 0; x < canvas.width / tileWSize; x++) {
			const parity = (x + y) % 2;
			const tileNum = x + canvas.width / tileWSize * y;
			const xx = x * tileWSize;
			const yy = y * tileHSize;     
			if(parseInt(tileNum)	=== parseInt(cell))  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 1;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#dc3545";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);				
			}
			else if(Landroads.indexOf(tileNum) !== -1)  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#000000";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);
			} 		
			else
			{
				ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
			}
					
			  
			}
		  }

			ctx.lineWidth = .1;
			ctx.stroke(); 
			
				 
        setModalopen(1);
        
    }
    

    return (
        <div className='auctions'>
            <Toaster />

            <Modal
                isOpen={isDialogOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="text-center pl-3 pr-3">
                    < br />
                    {blockchainUpdationType == 1 ?
                        <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                            Put on sale in progress, once the process is completed, the NFT will be displayed on the marketplace page.
                        </h4>
                        :
                        blockchainUpdationType == 2 ?
                            <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                                Canceling your listing will unpublish this sale from Sinverse and requires a transaction.
                            </h4>
                            :
                        blockchainUpdationType == 3 ?
                            <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                                Importing you NFT into portfoilo.
                            </h4>
                            :    
                            <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                                Bid accepting in progress, Please wait for a while.
                            </h4>
                    }

                    <p style={{ color: '#091f3f' }}>
                        Please do not refresh the page or close the tab.
                    </p>
                    <div>
                        <img src="images/loader.gif" height={50} width={50} />
                    </div>
                </div>
            </Modal>

            <Header data={{'cartcount':cartcount}} />
            <div className="market-place-banner">
			  <h1>Collected NFTs</h1>
			  
			</div>
            <div className="market-place">
			<div className="container">
			<div className="row">
			<div className="col-12 col-md-3 col-lg-2 px-0 ">
				<div className="left-sidebar">
				  <ul>
					<li className={island === 1 ? "active" :''}><a  className={isLoader == 1 ? 'disabled-link':''} href="javascript:;" onClick={() => { showland() }}>Collected Lands</a></li>
					<li className={islicense === 1 ? "active" :''}><a className={isLoader == 1 ? 'disabled-link':''} href="javascript:;" onClick={() => { showlicense() }}>Collected Licenses</a></li>													
				  </ul>
				</div>
				
			  </div>
			 <div className="col-12 col-md-9 col-lg-10 pl-4">
			 <div className="row">				 
				  <div className="col-12">
				  {island === 1 ?
					  <>
					 <div className="col-12 d-flex pro-filter-outer">	
					 <div className="pro-filter">
					  <div className="dropdown city">
						  <button className="btn btn-secondary dropdown-toggle" type="button" id="city" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							District <i className="fa-solid fa-chevron-down"></i>
						  </button>
						  <div className="dropdown-menu" aria-labelledby="city">
						  <form action="#">
						   {Cities.map((cityList) => (
								<>
									<label className="filter-lebel">
									<input onClick={() => CityFilter()} type="checkbox" name='city_id[]' value={cityList.id} /><span className="filter-btn-checkbox"></span> 
										{cityList.title}							
									</label><br />
								</>
							))}
							</form>
						  </div>
						</div>
						</div>			 
					 				
						<div className="pro-search">
							<form className="search-form">
								<input type="text" id="search" className="form-control" placeholder="Search (Title or Token ID)" onChange={SetLNameFilter} name="s" title="Search for" value={SearchLFilter} onKeyDown={() => handleLKeyPress(this)} />
							   <i className="fa-solid fa-magnifying-glass"></i>           
							</form>							

						</div>						
				  </div>
				  </>
				  :
				  <>
				  <div className="col-12 d-flex pro-filter-outer">	
				   <div className="pro-filter"></div>
					 <div className="pro-search">
							<form className="search-form">
								<input type="text" id="search" className="form-control" placeholder="Search (Title or City or Token ID)" onChange={SetLicNameFilter} name="s" title="Search for" value={SearchLicFilter} onKeyDown={() => handleLicKeyPress(this)} />
							   <i className="fa-solid fa-magnifying-glass"></i>           
							</form>							

						</div>	
					</div> 
				  </>
				}
				  </div>
					  <div className="col-6">
						<h2 className="title">Explore Collection</h2>
					  </div>
					  <div className="col-6 text-right">
						{selectedItems.length >=2 ? <button onClick={() => { CreateGroupModelAPI() }} className='custom-btn' data-toggle="modal" data-target="#putOnSale">Create Group {selectedItems.length} NFTs</button> :'' }
					  </div>
					  <div className="col-12">
					  <p className="clr-wt">All the NFTs which have never interacted with the marketplace are shown here. &nbsp;(These are NFTs purchased from outside the marketplace).</p>
					  </div>
                        {isLoader == 0 ?
                            FilterNFTList.length > 0 ?
                                FilterNFTList.map((item) => (
									
                                    <div className="col-lg-4 col-md-6">                                    
										<div className="tab-section-main">
											<div className="tab section">
												<div className="profile-images">
												{item.contract_type==config.CONTRACT_TYPE_LAND ?
													(
													selectedContract !== null && selectedContract.toLowerCase() !== item.contractAddress.toLowerCase() ? (
													 <>
												<label className="check-container" onClick={handleOtherContractClick}>
												  <input disabled type="checkbox" />
												  <span className="checkmark"></span>
												</label>
											  </>
											) : (
												<>	
												<label className="check-container">
												  <input type="checkbox" onChange={(event) => { handleCheckboxChange(event,item,item.tokenId,item.contractAddress) }} />
												  <span className="checkmark"></span>
												</label>
												</> )
												 ) : null
												 }												
												</div>
												<div className="Andrew-text">
												<p>{walletAddress.toString().substring(0, 4) + '...' + walletAddress.toString().substring(walletAddress.length - 4)}</p>	
												</div>
												<div className="height-icon w-115">
													 {item.put_on_sale ?
														<button onClick={() => { putOnSaleModelAPI(item) }} className='custom-btn' data-toggle="modal" data-target="#putOnSale">Put On Sale</button>
														: ""}
												</div>
											</div>
											<div className="images-sec">
											{item.contract_type==config.CONTRACT_TYPE_LAND ?
												<>
											<Link to={`${config.baseUrl}collected-detail/` + item.contractAddress +`/`+ item.tokenId}><img src="images/lant-plot.png" /></Link>
											</>
											:
											item.metadata !=null ?
											item.metadata.type=='Exclusive' ?
											<>
											<Link to={`${config.baseUrl}collected-detail/` + item.contractAddress +`/`+ item.tokenId}>
											<img src={item.metadata.district.toLowerCase().includes('sin') ? 'licenses/sin-city-centre-exclusive.png' : item.metadata.district.toLowerCase().includes('china') ? 'licenses/china-town-exclusive.png' : item.metadata.district.toLowerCase().includes('strip') ? 'licenses/the-strip-exclusive.png' :  item.metadata.district.toLowerCase().includes('hills') ? 'licenses/the-hills-exclusive.png' : item.metadata.district.toLowerCase().includes('red') ? 'licenses/red-light-district-exclusive.png' : item.metadata.district.toLowerCase().includes('silicon') ? 'licenses/silicon-valley-exclusive.png' : item.metadata.district.toLowerCase().includes('favelas') ? 'licenses/favelas-exclusive.png' : item.metadata.district.toLowerCase().includes('fashion') ? 'licenses/fashion-district-exclusive.png' : item.metadata.district.toLowerCase().includes('cartel') ? 'licenses/cartel-exclusive.png' : item.metadata.district.toLowerCase().includes('harbour') || item.metadata.district.toLowerCase().includes('harbiur') ? 'licenses/harbour-exclusive.png' : 'images/no-image.png'} alt="NFT Image" data-src={item.metadata.district.toLowerCase().includes('strip') ? 'licenses/the-strip-exclusive.png':item.metadata.district} alt="NFT Image1" data-src={`${item.metadata.name.toLowerCase()}`} />											
											</Link>
											</>
											:
											<>
											<Link to={`${config.baseUrl}collected-detail/` + item.contractAddress +`/`+ item.tokenId}>
											<img src={item.metadata.district.toLowerCase().includes('sin') ? 'licenses/sin-city-centre-premium.png' : item.metadata.district.toLowerCase().includes('china') ? 'licenses/china-town-premium.png' : item.metadata.district.toLowerCase().includes('strip') ? 'licenses/the-strip-premium.png' :  item.metadata.district.toLowerCase().includes('hills') ? 'licenses/the-hills-premium.png' : item.metadata.district.toLowerCase().includes('red') ? 'licenses/red-light-district-premium.png' : item.metadata.district.toLowerCase().includes('silicon') ? 'licenses/silicon-valley-premium.png' : item.metadata.district.toLowerCase().includes('favelas') ? 'licenses/favelas-premium.png' : item.metadata.district.toLowerCase().includes('fashion') ? 'licenses/fashion-district-premium.png' : item.metadata.district.toLowerCase().includes('cartel') ? 'licenses/cartel-premium.png' : item.metadata.district.toLowerCase().includes('harbour') || item.metadata.district.toLowerCase().includes('harbiur') ? 'licenses/harbour-premium.png' : 'images/no-image.png'} alt="NFT Image2" data-src={item.metadata.district.toLowerCase().includes('strip') ? 'licenses/the-strip-premium.png':item.metadata.district} />
											
											
											</Link>
											</>
											: ''
											
											}
											
											</div>
											<div className="section-map">
												<h3>
												{
													  windowWidth < 1299 ?
													  	<>					
														<span className="fnt-16" >{ item.name.toString().substring(0, 15) } { item.name.length > 15 ? '...' : ''}</span>
														</>
														:
														<>					
														<span className="fnt-16" >{ item.name.toString().substring(0, 23) }
														{ item.name.length > 23 ? '...' : ''}
														</span>
														</>
												}
												{item.contract_type==config.CONTRACT_TYPE_LAND ?
													<>
												<span className="pull-right"><a href="javascript:;" onClick={() => { ShowPlotDetails(item.metadata.cell_number) }}><i class="fa-regular fa-eye" aria-hidden="true"></i></a></span>
												</>
												:''}
												
												{item.contract_type==config.CONTRACT_TYPE_LAND ?
													<>
												<br />
												<span className="">{item.city_name}</span>
												</>
												:''}
												</h3>
											</div>	
											<div className="price-section">
												<div className="price">
													<p>Token ID</p>
													<h3>#{item.tokenId}</h3>
												</div>	
												<div className="price">
													<p>Contract Address</p>
													<h3>{item.contractAddress.toString().substring(0, 4) + '...' + item.contractAddress.toString().substring(item.contractAddress.length - 4)}</h3>
												</div>											
											</div>	
											<div className="price-section">
												<div className="price import-portfolio">
													{item.put_on_sale ?
														<button onClick={() => { importtoPortfolioAPI(item) }} className='custom-btn' data-toggle="modal" data-target="#putOnSale">Import to portfolio</button>
														: ""}	
													</div>										
											</div>										
										</div>
									</div>
									
                                ))
                                :
                                <p className='noDataFound'>
                                    <h4> No Data Found!!! </h4>
                                </p>
                            :
                             (
								<div className="loaderDiv">
							  <img className='loaderImg' src="images/loader.gif" height={50} width={50} />
							  </div>
							)
                        }
                       
                    </div>
                </div>
                </div>
                </div>
                </div>
           

            {/* Put on sale model */}
            <div className={isPutonsale === 0 ? "modal fade" : "modal fade show"} id="putOnSale" style={{ display: isPutonsale === 0 ? 'none' : 'block', overflowY: 'auto' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                <div className={isCreatGroup != 2 ? "modal-dialog putonsale":"modal-dialog importnft"} role="document">
					{isCreatGroup === 0 ?
						<>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Put On Sale </h5>
                            <a type="button" className="close" data-dismiss="modal" style={{
                                fontSize: '26px'
                            }} aria-label="Close" onClick={closeModel} >
                                <span aria-hidden="true">&times;</span>
                            </a>
                        </div>

                        <div className="modal-body">
                            <div className="de_tab tab_methods">
                                <div className="de_tab_content">
                                    <form onSubmit={approveNFT}>
                                    <div className="row-form style-3">        
                                        <h4 className="title-create-item">Title</h4>
                                        <label>{itemDetails.title}</label>
                                        <span className="validationErr">{validatioError.titleError}</span>
                                        <input disabled="disabled" type="text" placeholder="Enter Title" id='titleError' className="dpnone" value={itemDetails.title} name='title' onChange={inputHandler} />
                                        </div>
									<div className="row-form style-3 mt-4">        
                                        <h4 className="title-create-item">Description</h4>
                                        <label>{itemDetails.description}</label>
                                        <span className="validationErr">{validatioError.descriptionError}</span>
                                        <textarea className="dpnone" disabled="disabled" id='descriptionError' name='description' onChange={inputHandler} placeholder="e.g. “This is very limited item”" value={itemDetails.description}></textarea>
                                     </div>   
									
										{itemDetails.contract_type == config.CONTRACT_TYPE_WEAPON  ?
										 <>
										 <div className="row-form style-3 mt-4">        	
                                        <h4 className="title-create-item">Category {itemDetails.contract_type}</h4>
                                        <span className="validationErr">{validatioError.categoryError}</span>
                                        <select name='category_id' id='categoryError' onChange={inputHandler}>
                                            <option value="0">Select Category</option>
                                            {Category.map(cat => (
                                                <option value={cat.id}>{cat.name}</option>
                                            ))}
                                        </select>
                                        </div>
										</>
                                          : ""}
                                       
                                        <div className="row-form style-3 mt-4">                                          

                                            <div className="inner-row-form">
                                                <h4 className="title-create-item">Price(SIN)</h4>
                                                <span className="validationErr">{validatioError.priceError}</span>
                                                <input name='price' id='priceError' onChange={inputHandler} type="text" placeholder="Enter Price (SIN)" onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} />
                                            </div>
                                            </div>
                                            <div className="row-form style-3 mt-4">        
                                             <div className="inner-row-form royalty">
                                                <h4 className="title-create-item">Royalty %</h4>
                                                <input name='royalty' id='royalty' type="text" readonly value={itemDetails.royalty_percentage} />
                                            </div>

                                            <div className="inner-row-form style-2">
                                                <div className="seclect-box">
                                                    {spinLoader == '0' ?
                                                        <input type="submit" value="Approve" id="submit" className="custom-btn" defaultValue="Create Item" />
                                                        :
                                                        <button disabled className="custom-btn" id="deposit-page" >Processing &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
                                                    }
                                                    <div className="spacer-single" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                    :
                    isCreatGroup=== 2 ? 
                    <>
                      <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Are you sure ? </h5>
                            <a type="button" className="close" data-dismiss="modal" style={{
                                fontSize: '26px'
                            }} aria-label="Close" onClick={closeModel} >
                                <span aria-hidden="true">&times;</span>
                            </a>
                        </div>  
                        <div className="modal-body">
                            <div className="de_tab tab_methods">
                                <div className="de_tab_content">                                  
									<div className="row-form style-3 mt-4"> 
									<div className="inner-row-form style-2">
										<div className="seclect-box">
											{spinLoader == '0' ?
                                            <>
                                             <div className="inner-row-form">
                                               <span className="validationErr">{validatioError.priceError}</span>
                                                <input name='price' id='priceError' onChange={inputImportPriceHandler} type="text" placeholder="Enter Price (SIN)" onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} />
                                            </div>                                            
                                             <div className="inner-row-form mt-4">
												<input type="button"  value="Import" id="submit" className="custom-btn" defaultValue="Import NFT" onClick={ImportNFTToPortfolio}/> 
                                                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<input type="button" value="Cancel" id="cancel" className="custom-btn pull-right" defaultValue="Cancel" data-dismiss="modal"  onClick={closeModel} />
                                                </div>
                                                </>	
												:
												<button disabled className="custom-btn" id="deposit-page" >Processing &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
												
											}
											<div className="spacer-single" />
										</div>
                                      
									</div>
								</div>						
						</div>
					</div>
				</div>
                    </div>
                    </>
                    :
                    <>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Put On Sale </h5>
                            <a type="button" className="close" data-dismiss="modal" style={{
                                fontSize: '26px'
                            }} aria-label="Close" onClick={closeModel} >
                                <span aria-hidden="true">&times;</span>
                            </a>
                        </div>  
                        <div className="modal-body">
                            <div className="de_tab tab_methods">
                                <div className="de_tab_content">
                                    <form onSubmit={CreateGroupNFT}>
                                    <div className="row-form style-3">        
                                        <h4 className="title-create-item">Title</h4>
                                        <span className="validationErr">{validatioError.grouptitleError}</span>
                                        <input type="text" placeholder="Enter Title" id='grouptitleError' value={groupDetails.title} name='title' onChange={groupinputHandler} />
                                        </div>
									<div className="row-form style-3 mt-4">        
                                        <h4 className="title-create-item">Description</h4>
                                        <span className="validationErr">{validatioError.groupdescriptionError}</span>
                                        <textarea id='groupdescriptionError' name='description' onChange={groupinputHandler} placeholder="e.g. “This is very limited item”" value={groupDetails.description}></textarea>
                                     </div>                                   
                                    {textboxes.map((textbox, index) => (
										<div className="row-form style-3 mt-4">
										<div className="inner-row-form">
										<h4 className="title-create-item">Land {index+1} Price(SIN)</h4>
										<div key={index}>
										  <input
											type="text"
											value={textbox}
											onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }}
											onChange={(e) => handleTextboxChange(index, e.target.value)}
										  />										 
										</div>
										</div>
                                    </div>
									  ))}
									<span className="validationErr">{validatioError.grouppriceError}</span>
									<div className="row-form style-3 mt-4">       
									
									<div className="inner-row-form style-2">
										<div className="seclect-box">
											{spinLoader == '0' ?
												<input type="submit" value="Create Group" id="submit" className="custom-btn" defaultValue="Create Group" />
												:
												<button disabled className="custom-btn" id="deposit-page" >Processing &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
											}
											<div className="spacer-single" />
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
                    </div>
                    </>
				}

                </div>
            </div>
			  <div id="myModal" className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered gridmodal">
                    <div className="modal-content">
						<button type="button" onClick={closebutton} className="close btnClose" data-dismiss="modal">&times;</button>                                      
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">                                        
                                        <div class="graph text-center">
											<canvas id="myCanvas"></canvas>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            

            <Footer />
        </div>
    );
}

export default CollectedNFTs;
