import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import ReactDatatable from '@ashvin27/react-datatable';
import {Container,Col} from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getUserPurchaseAPIAction, getUserSaleDataAPIAction, getUserSoldDataAPIAction, getUsertransactionsAPIAction, getUserTokenBuytransactionsAPIAction, getUserPurchaselicensesAction, getcartcountAction, getUserCrateBuytransactionsAction } from '../Action/user.action';
import config from '../config';

const TransactionList = () => {
    const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
    const [getUserPurchaseData, setgetUserPurchaseData] = useState([]);
    const [getUserSaleData, setgetUserSaleData] = useState([]);
    const [getUserSoldData, setgetUserSoldData] = useState([]);
    const [getUsertransactions, setgetUsertransactions] = useState([]);
    const [getUserTokenBuytransactions, setgetUserTokenBuytransactions] = useState([]);
    const [getUserCrateBuytransactions, setgetUserCrateBuytransactions] = useState([]);
    const [getUserPurchaselicenses, setUserPurchaselicenses] = useState([]);
    const [walletAddress, setwalletAddress] = useState('');
	const [cartcount, setcartcount] = useState(0);
	
    useEffect(() => {
    if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {                
                setwalletAddress(accounts[0]);
                if (accounts) {
                    getUserPurchaseAPI(accounts[0]);
					getUserSaleDataAPI(accounts[0]);
					getUserSoldDataAPI(accounts[0]);
					getUsertransactionsAPI(accounts[0]);
					getUserCrateBuytransactionsAPI(accounts[0]);
					getUserLicensesAPI(accounts[0]);
					getcartcountAPI(accounts[0]);
                }
            });
            
        }
    setTimeout(async () => {

            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                setwalletAddress(accounts[0]);
                if (accounts) {
                    getUserPurchaseAPI(accounts[0]);
					getUserSaleDataAPI(accounts[0]);
					getUserSoldDataAPI(accounts[0]);
					getUsertransactionsAPI(accounts[0]);
					getUserCrateBuytransactionsAPI(accounts[0]);
					getUserLicensesAPI(accounts[0]);
					 getcartcountAPI(accounts[0]);
                }
            }
        }, 200);    
        
    }, []);

    //  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>   User purchase history >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const columnsForPurchase = [
        {
            key: "sn",
            text: "#",
            cell: (row, index) => index + 1
        },
        {
            key: "image",
            text: "Image",
            cell: (item) => {
                return (
                item.nft_type == config.WEED ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					<div className="image-circle">
					<img src={`licenses/${item.city_image_title}-premium.png`} alt="NFT Image" width="70px" /></div>
					 </Link>
					:
					item.nft_type == config.COFFIEE ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					<div className="image-circle">				
					 <img src={`licenses/${item.city_image_title}-exclusive.png`} alt="NFT Image" width="70px" /></div>
					  </Link>
					:
					item.nft_type == config.LAND ? 
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        <div className="image-circle">
                            <img src="images/lant-plot.png" width="70px" />
                        </div>
                    </Link>
                    :
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        <div className="image-circle">
                            <img src={`${config.ipfsUrl}` + item.image} width="70px" />
                        </div>
                    </Link>
                );
            }
        },
        {
            key: "item_name",
            text: "Title",
            sortable: true,
            cell: (item) => {
				 return (
                item.nft_type == config.WEED ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					 {item.item_name}
					 </Link>
					:
					item.nft_type == config.COFFIEE ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					 {item.item_name}
					  </Link>
					:
					item.nft_type == config.LAND ? 
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        {item.item_name}
                    </Link>
                    :
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        {item.item_name}
                    </Link>
                );               
            }
        },
        {
            key: "price",
            text: "Amount",
            cell: (item) => {
                return (
                    parseInt(item.price).toLocaleString('en-US') + ' SIN'
                );
            }
        },
        {
            key: "purchase_datetime",
            text: "NFT Purchased On",
            cell: (item) => {
                return (
                    item.purchase_datetime
                );
            }
        },
       
        {
            key: "action",
            text: "Action",
            cell: (item) => {
                return (
                    item.transfer_hash ?
                        <a href={`${config.trxHash + item.transfer_hash}`} target="_blank">
                            <button className="custom-btn">Blockchain view</button>
                        </a>
                        : ""
                );
            }
        },
    ];

    const configForPurchase = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false
        }
    }

    //  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  For Sale history >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    const columnsForSale = [
        {
            key: "sn",
            text: "#",
            cell: (row, index) => index + 1
        },
        {
            key: "image",
            text: "Image",
            cell: (item) => {
                 return (
                item.nft_type == config.WEED ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					<div className="image-circle">
					<img src={`licenses/${item.city_image_title}-premium.png`} alt="NFT Image" width="70px" /></div>
					 </Link>
					:
					item.nft_type == config.COFFIEE ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					<div className="image-circle">				
					 <img src={`licenses/${item.city_image_title}-exclusive.png`} alt="NFT Image" width="70px" /></div>
					  </Link>
					:
					item.nft_type == config.LAND ? 
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        <div className="image-circle">
                            <img src="images/lant-plot.png" width="70px" />
                        </div>
                    </Link>
                    :
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        <div className="image-circle">
                            <img src={`${config.ipfsUrl}` + item.image} width="70px" />
                        </div>
                    </Link>
                );
            }
        },

        {
            key: "item_name",
            text: "Title",
            sortable: true,
            cell: (item) => {
				 return (
					item.nft_type == config.WEED ?
						<Link to={`${config.baseUrl}license-details/` + item.item_id}>
						 {item.item_name}
						 </Link>
					:
					item.nft_type == config.COFFIEE ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					 {item.item_name}
					  </Link>
					:
					item.nft_type == config.LAND ? 
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        {item.item_name}
                    </Link>
                    :
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        {item.item_name}
                    </Link>
                );                  
            }
        },
        {
            key: "price",
            text: "Amount",
            cell: (item) => {
                return (
                    parseInt(item.price).toLocaleString('en-US') + ' SIN'
                );
            }
        },

        {
            key: "nft_datetime",
            text: "Created Date",
            cell: (item) => {
                return (
                    item.nft_datetime
                );
            }
        },

    ];

    const configForSale = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false

        }
    }
	
	 //  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  For Sold history >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    const columnsForSold = [
        {
            key: "sn",
            text: "#",
            cell: (row, index) => index + 1
        },
        {
            key: "image",
            text: "Image",
            cell: (item) => {
                 return (
                item.nft_type == config.WEED ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					<div className="image-circle">
					<img src={`licenses/${item.city_image_title}-premium.png`} alt="NFT Image" width="70px" /></div>
					 </Link>
					:
					item.nft_type == config.COFFIEE ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					<div className="image-circle">				
					 <img src={`licenses/${item.city_image_title}-exclusive.png`} alt="NFT Image" width="70px" /></div>
					  </Link>
					:
					item.nft_type == config.LAND ? 
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        <div className="image-circle">
                            <img src="images/lant-plot.png" width="70px" />
                        </div>
                    </Link>
                    :
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        <div className="image-circle">
                            <img src={`${config.ipfsUrl}` + item.image} width="70px" />
                        </div>
                    </Link>
                );
            }
        },

        {
            key: "item_name",
            text: "Title",
            sortable: true,
            cell: (item) => {
				 return (
					item.nft_type == config.WEED ?
						<Link to={`${config.baseUrl}license-details/` + item.item_id}>
						 {item.item_name}
						 </Link>
					:
					item.nft_type == config.COFFIEE ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					 {item.item_name}
					  </Link>
					:
					item.nft_type == config.LAND ? 
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        {item.item_name}
                    </Link>
                    :
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        {item.item_name}
                    </Link>
                );                  
            }
        },
        {
            key: "price",
            text: "Amount",
            cell: (item) => {
                return (
                    parseInt(item.price).toLocaleString('en-US') + ' SIN'
                );
            }
        },

        {
            key: "purchase_datetime",
            text: "Sold Date",
            cell: (item) => {
                return (
                    item.purchase_datetime
                );
            }
        },

    ];

    const configForSold = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false

        }
    }

    
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>    All Transactions History >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const columnsForAllTransactions = [
        {
            key: "sn",
            text: "#",
            cell: (row, index) => index + 1
        },
        {
            key: "image",
            text: "Image",
            cell: (item) => {
                return (
                item.nft_type == config.WEED ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					<div className="image-circle">
					<img src={`licenses/${item.city_image_title}-premium.png`} alt="NFT Image" width="70px" /></div>
					 </Link>
					:
					item.nft_type == config.COFFIEE ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					<div className="image-circle">				
					 <img src={`licenses/${item.city_image_title}-exclusive.png`} alt="NFT Image" width="70px" /></div>
					  </Link>
					:
					item.nft_type == config.LAND ? 
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        <div className="image-circle">
                            <img src="images/lant-plot.png" width="70px" />
                        </div>
                    </Link>
                    :
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        <div className="image-circle">
                            <img src={`${config.ipfsUrl}` + item.image} width="70px" />
                        </div>
                    </Link>
                );
            }
        },

        {
            key: "item_name",
            text: "Title",
            sortable: true,
            cell: (item) => {
                return (
					item.nft_type == config.WEED ?
						<Link to={`${config.baseUrl}license-details/` + item.item_id}>
						 {item.item_name}
						 </Link>
					:
					item.nft_type == config.COFFIEE ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					 {item.item_name}
					  </Link>
					:
					item.nft_type == config.LAND ? 
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        {item.item_name}
                    </Link>
                    :
                    <Link to={`${config.baseUrl}nft-details/` + item.item_id}>
                        {item.item_name}
                    </Link>
                );  
            }
        },

        {
            key: "trxType",
            text: "Type",
            sortable: true
        },

        {
            key: "price",
            text: "Amount",
            cell: (item) => {
                return (
                    parseInt(item.price).toLocaleString('en-US') + ` SIN`
                );
            }
        },
         {
            key: "purchase_datetime",
            text: "NFT Purchased On",
            cell: (item) => {
                return (
                    item.purchase_datetime
                );
            }
        },
   
    ];
   
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>    Token Buy Transactions History >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const columnsForTokenBuyTransactions = [
        {
            key: "sn",
            text: "#",
            cell: (row, index) => index + 1
        },       
        {
            key: "item_id",
            text: "Buy Coins",
            sortable: true,
            cell: (item) => {
                return (
                    item.item_id
                )
            }
        },
        {
            key: "amount",
            text: "Spend Tokens",
            sortable: true,
            cell: (item) => {
                return (
                    item.amount + ` SIN`
                )
            }
        },
        
        {
            key: "txHash",
            text: "Hash",
            cell: (item) => {
                return (
                   item.txHash.toString().substring(0, 7) + '...' + item.txHash.toString().substr(item.txHash.length - 7)
                );
            }
        },

        {
            key: "created_on",
            text: "Created Date",
            cell: (item) => {
                return (
                    item.created_on
                );
            }
        }


    ];
      
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>    Token Buy Transactions History >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const columnsForCrateBuyTransactions = [
        {
            key: "sn",
            text: "#",
            cell: (row, index) => index + 1
        },       
        {
            key: "item_id",
            text: "Crate Purchased",
            sortable: true,
            cell: (item) => {
                return (
                    item.crate_title
                )
            }
        },
        {
            key: "amount",
            text: "Spend Tokens",
            sortable: true,
            cell: (item) => {
                return (
                    item.crate_price + ` SIN`
                )
            }
        },
        {
            key: "rewards",
            text: "Rewards",
            sortable: true,
            cell: (item) => {
                return (
                    item.rewards + ` (` + item.item_amount + `)`
                )
            }
        },
        
        {
            key: "txHash",
            text: "Hash",
            cell: (item) => {
                return (
                  item.txHash ? item.txHash.toString().substring(0, 7) + '...' + item.txHash.toString().substr(item.txHash.length - 7) :''
                );
            }
        },

        {
            key: "created_on",
            text: "Created Date",
            cell: (item) => {
                return (
                    item.created_on
                );
            }
        }


    ];
      
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>    License Buy History >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const columnsForLicenseBuy = [
        {
            key: "sn",
            text: "#",
            cell: (row, index) => index + 1
        },       
        {
            key: "nft_type",
            text: "License",
            sortable: true,
            cell: (item) => {
                 return (
                 item.nft_type == config.WEED ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					<div className="image-circle">
					<img src={`licenses/${item.city_image_title}-premium.png`} alt="NFT Image" width="70px" /></div>
					 </Link>
					:
					item.nft_type == config.COFFIEE ?
					<Link to={`${config.baseUrl}license-details/` + item.item_id}>
					<div className="image-circle">				
					 <img src={`licenses/${item.city_image_title}-exclusive.png`} alt="NFT Image" width="70px" /></div>
					  </Link>
					:
					''
					
                 );
            }
        },
        {
            key: "license_key",
            text: "License Key",
            sortable: true,
            cell: (item) => {
                 return item.license_key
            }
        },
        {
            key: "price",
            text: "Spend Tokens",
            sortable: true,
            cell: (item) => {
                return (
                    parseInt(item.price).toLocaleString('en-US') + ` SIN`
                )
            }
        },       
        
        {
            key: "created_on",
            text: "Purchased Date",
            cell: (item) => {
                return (
                    item.purchaseddate
                );
            }
        }


    ];
 
    const configForAllTransactions = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false

        }
    }
    
     const configForTokenTransactions = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false

        }
    }
     const configForCrateTransactions = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false

        }
    }
    
     const configForLicenses = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false

        }
    }
    
    const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }

    const getUserPurchaseAPI = async (wallet) => {
        let res = await getUserPurchaseAPIAction({ 'user_id': loginData?.id, 'email': loginData?.email, 'walletAddress': wallet });
        if (res.success) {
            setgetUserPurchaseData(res.data);
        }
    }
    
    const getUserLicensesAPI = async (wallet) => {
        let res = await getUserPurchaselicensesAction({ 'user_id': loginData?.id, 'email': loginData?.email, 'walletAddress': wallet  });
        if (res.success) {
            setUserPurchaselicenses(res.data);
        }
    }
    
    

    const getUserSaleDataAPI = async (wallet) => {
        let res = await getUserSaleDataAPIAction({ 'user_id': loginData?.id, 'email': loginData?.email, 'walletAddress': wallet });
        if (res.success) {
            setgetUserSaleData(res.data);
        }
    }
    
    const getUserSoldDataAPI = async (wallet) => {
        let res = await getUserSoldDataAPIAction({ 'user_id': loginData?.id, 'email': loginData?.email, 'walletAddress': wallet });
        if (res.success) {
            setgetUserSoldData(res.data);
        }
    }

    const getUsertransactionsAPI = async (wallet) => {
        let res = await getUsertransactionsAPIAction({ 'user_id': loginData?.id, 'email': loginData?.email,'walletAddress': wallet});
        if (res.success) {
            setgetUsertransactions(res.data);
        }
    }
    
     const getUserTokenBuytransactionsAPI = async (wallet) => {
        //let res = await getUserTokenBuytransactionsAPIAction({ 'user_id': loginData?.id, 'email': loginData?.email, 'walletAddress': wallet });
       
       // if (res.success) {
        //    setgetUserTokenBuytransactions(res.data);
       // }
    }
     const getUserCrateBuytransactionsAPI = async (wallet) => {       
        let res = await getUserCrateBuytransactionsAction({ 'user_id': loginData?.id, 'email': loginData?.email, 'walletAddress': wallet });
        if (res.success) {
            setgetUserCrateBuytransactions(res.data);
        }
    }

    return (
        <div className='auctions'>
            {/* <Toaster /> */}

            <Header data={{'cartcount':cartcount}} />
            <div class="about-section">
			<div class="container">
				<div class="about-us-heading">
					<h1>Transactions List</h1>   
			</div>
			</div>
			</div>
            <section className="tf-section live-auctions transactionlist">
                <div className="container">
                    <div className="col-md-12">
                        <Tabs>
                            <TabList>
                                <Tab>NFTs Purchase History</Tab>
                                <Tab>NFTs Currently On Sale</Tab>                                 
                                <Tab>NFTs Sale History</Tab>                                 
                                <Tab>All NFTs Transaction History</Tab>                                                             
                                <Tab>Crate Buy History</Tab>                                
                            </TabList>

                            <TabPanel>
                                <Container>
                                    <Col lg={12}>
                                <ReactDatatable
                                    config={configForPurchase}
                                    records={getUserPurchaseData}
                                    columns={columnsForPurchase}
                                />
                                </Col>
                                </Container>
                            </TabPanel>

                            <TabPanel>
                            <Container>
                            <Col lg={12}>
                                <ReactDatatable
                                    config={configForSale}
                                    records={getUserSaleData}
                                    columns={columnsForSale}
                                />
                                </Col>
                                </Container>
                            </TabPanel>
                            
                             <TabPanel>
                            <Container>
                            <Col lg={12}>
                                <ReactDatatable
                                    config={configForSold}
                                    records={getUserSoldData}
                                    columns={columnsForSold}
                                />
                                </Col>
                                </Container>
                            </TabPanel>

                            <TabPanel>
                            <Container>
                            <Col lg={12}>
                                <ReactDatatable
                                    config={configForAllTransactions}
                                    records={getUsertransactions}
                                    columns={columnsForAllTransactions}
                                />
                                </Col>
                                </Container>
                            </TabPanel>                                       
                            <TabPanel>
                            <Container>
                            <Col lg={12}>
                                <ReactDatatable
                                    config={configForCrateTransactions}
                                    records={getUserCrateBuytransactions}
                                    columns={columnsForCrateBuyTransactions}
                                />
                                </Col>
                                </Container>
                            </TabPanel>                     
                            
                            
                        </Tabs>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default TransactionList;
