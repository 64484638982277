import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import 'react-tabs/style/react-tabs.css';
import ReactTooltip from 'react-tooltip';
import { getCityWeedFarmLicenseAction, getcityidAction, getcartcountAction, getSettingsAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import Swal from "sweetalert2";


const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));



const WeedFarmList = () => {
	const { id } = useParams();
	const [cartcount, setcartcount] = useState(0);
	const [settings, setSettings] = useState([]);
	const [connectWalletAddress, setConnectWalletAddress] = useState('');	   
    const [visible, setVisible] = useState(6);   
    const [NFTList, setNFTList] = useState([]);
    const [CityDetails, setCityDetails] = useState([]);
    const [isHistory, setHistory] = useState(0);
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(async () => {
		getCityDetailsAPI()
		 getSettingsAPI();
     setTimeout(() => {
            if (window.ethereum) {
                const { ethereum } = window;
                setConnectWalletAddress(ethereum.selectedAddress);
                getNFTListAPI(ethereum.selectedAddress);               
                    
            }
            else
            {
            getNFTListAPI('');
            }
            
         if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            }    
        }, 200);
    const hash = window.location.hash.substring(1);
	if(hash =='history')
	{
		setHistory(1);
	}    
    }, []);    
    
     const navigate = useNavigate();

	  const handleGoBack = () => {
		navigate(-1); // Navigate back by one step in the history stack
	  };

	const getNFTListAPI = async (walletAddress) => {
        let res = await getCityWeedFarmLicenseAction({ 'city_id': id, 'walletAddress': walletAddress  });
        if (res.success) {
            setNFTList(res.data);          
        }
    }
    
     const getSettingsAPI = async () => {
        let res = await getSettingsAction();
        if (res.success) {
            setSettings(res.data);
        }
    }
     
    
    const ShowHistory = () => {
        setHistory(1);
    }
    const HideHistory = () => {
        setHistory(0);
    }
    
    const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
    
	const getCityDetailsAPI = async () => {
        let res = await getcityidAction({ 'id': id});
        if (res.success) {
            setCityDetails(res.data);          
        }
    }
    
    const connectMetasmask = async () => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setConnectWalletAddress(accounts);
             getNFTListAPI(accounts);
        }
        else {
            toast.error(`Please use dApp browser to connect wallet!`);
        }
    }
   
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 6);
    }
   
    
    return (
      <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}}  />
			<div className="market-place-banner">
			  <h1>{CityDetails?.title}  - Premium Business License</h1>
			</div>
			<div className="market-place">
			<div className="container">
			<div className="row">
			  <div className="col-12 col-md-3 col-lg-2 px-0 ">
				<div className="left-sidebar">
				 <ul>
					<li><Link to={`${config.baseUrl}marketplace`}>Weapon & Armor Collections</Link></li>
					<li><Link to={`${config.baseUrl}land-marketplace`}>Land</Link></li>
					<li className="active"><Link to={`${config.baseUrl}licenses-cities`}>License</Link></li>	
					<li><Link to={`${config.baseUrl}othernfts`}>Other NFTs</Link></li>	
					<li><Link to={`${config.baseUrl}gold-coins`} className="">Gold Coins</Link></li>				
				  </ul>
				</div>
			  </div>
			  <div className="col-12 col-md-9 col-lg-10 pl-4">
				<div className="row">
				  <div className="col-12 back-btn">
                    <button className="custom-btn btn-bg-blue" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i>Back</button>
                    </div>
				  <div className="col-12">
					<h2 className="title">Explore Licenses Collection  ({NFTList.length})</h2>
				  </div>
				  {NFTList.length == 0 ?
					<>
						<h4 className="not-found">No data found!!!</h4>
					</>
					:
					NFTList.slice(0, visible).map((item, index) => (
					<div className="col-lg-4 col-md-6">
							<div className="tab-section-main">
								<div className="tab section">
									<div className="profile-images">									
									</div>
									<div className="Andrew-text">
									<p>{item.owner_address.toString().substring(0, 4) + '...' + item.owner_address.toString().substring(item.owner_address.length - 4)}</p>	
									</div>									
								</div>
								<div className="images-sec">
								
								{item.nft_type==config.WEED ? 
									<Link to={`${config.baseUrl}weed-farm-license/` + item.id}>
									<img src={`licenses/${CityDetails.city_image_title}-premium.png`} alt="NFT Image" />
									</Link>
									:
									<Link to={`${config.baseUrl}coffee-shop-license/` + item.id}>
									<img src={`licenses/${CityDetails.city_image_title}-exclusive.png`} alt="NFT Image" />
									</Link>
								}
								
								</div>
								<div className="section-map">
									<h3>
									{
									  windowWidth < 1299 ?						
										item.name.toString().substring(0, 15) + '...'
										:
										item.name.toString().substring(0, 23) +
										(item.name.length > 23 ? '...' : '')
									}
									</h3> 
									<h6 className="license-key">{item.license_key}</h6>
								</div>	
								
								{ settings.is_license_on_sale ?
								<>	
								<div className="price-section">
									<div className="price">
										<p>Price</p>
										<h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
									</div>
									<div className="price">
										<p>Token ID</p>
										<h3>#{item.token_id}</h3>
									</div>
									{item.nft_type==config.WEED ? 
									<Link to={`${config.baseUrl}weed-farm-license/` + item.id}>
									<button className="tab-btn">Buy</button></Link>
									:
									<Link to={`${config.baseUrl}coffee-shop-license/` + item.id}>
									<button className="tab-btn">Buy</button></Link>
								}								
								</div>
								</>
								:
								<>	
								<div className="price-section">									
									<div className="price">
										<p>Token ID</p>
										<h3>#{item.token_id}</h3>
									</div>
									{item.nft_type==config.WEED ? 
									<Link to={`${config.baseUrl}weed-farm-license/` + item.id}>
									<button className="tab-btn">Coming Soon</button></Link>
									:
									<Link to={`${config.baseUrl}coffee-shop-license/` + item.id}>
									<button className="tab-btn">Coming Soon</button></Link>
								}
								</div>
								</>
							}
								
								
								<div className="View-History">
								{item.nft_type==config.WEED ? 
									<Link to={`${config.baseUrl}weed-farm-license/` + item.id+`#history`}>
									<i className="fa-solid fa-clock-rotate-left"></i>View History</Link>
									:
									<Link to={`${config.baseUrl}coffee-shop-license/` + item.id+`#history`}>
									<i className="fa-solid fa-clock-rotate-left"></i>View History</Link>
								}								
								</div>
							</div>
						</div>
					
					
					
					))}
					{
                        visible < NFTList.length &&
                                                      
					<div className="lorn-more col-12 text-center">
					<Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><button className="tab-last-btn btn-bg-pink px-5">Load More</button></Link>					
					</div>
					  }
					 
					</div>
					</div>
					</div>
					</div>
					</div>
					<Footer />
        </div >
    );
}

export default WeedFarmList;
